import md from 'markdown-it'
import taskLists from 'markdown-it-task-lists'
import highlightJs from 'markdown-it-highlightjs'
import definitionLists from 'markdown-it-deflist'
import textMark from 'markdown-it-mark'
import textIns from 'markdown-it-ins'
import textSub from 'markdown-it-sub'
import textSup from 'markdown-it-sup'
import textKbd from 'markdown-it-kbd'
import textBr from 'markdown-it-br'
// import footNotes from 'markdown-it-footnote'
import emoji from 'markdown-it-emoji'
import inlineComments from 'markdown-it-inline-comments'
// markdown-it-emoji/light

// Other plugins can be found here: https://www.npmjs.com/browse/keyword/markdown-it-plugin

import hljs from 'highlight.js'
import fountainLang from './highlightjs_languages/fountain'

import removeMarkdown from 'remove-markdown'

hljs.registerLanguage('fountain', fountainLang)

const converter = md({
  typographer: true
})
  .use(taskLists, {
    enabled: true,
    label: false
  })
  .use(highlightJs, {

  })
  .use(definitionLists)
  .use(textMark)
  .use(textIns)
  .use(textSub)
  .use(textSup)
  .use(textKbd)
  .use(textBr)
  // .use(footNotes)
  .use(emoji)
  .use(inlineComments)

export interface MarkdownOptions { }

export function convertToHTML(source: string, options: Partial<MarkdownOptions> = {}): string {
  return converter.render(source)
}

export function removeFormatting(source: string): string {
  return removeMarkdown(source, {
    stripListLeaders: true,
    gfm: true
  }).replace(/==/g, '')
}

export const markdownToHTML = convertToHTML
export const markdownToText = removeFormatting

export default { convertToHTML, removeFormatting, markdownToHTML, markdownToText }