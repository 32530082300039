import Snapshot, { INoteSnapshot } from '../../utils/Snapshot'
import NoteMgr, { Note } from '../../services/NoteManager'
import App from '../../services/Application'

export function updateNotePosition(note: Note, position: number): boolean {
  if (
    note.position === position ||
    position < 0 ||
    !note.hasParent ||
    position > note.parent.children.length
  ) {
    return false
  }

  const getNoteById = App.instance.noteMgr.getByIdOrThrow

  return App.instance.undoMgr.executeCommand({
    context: {
      noteId: note.id,
      position: position,
      prevPosition: note.position,
      snapshot: {} as INoteSnapshot
    },

    execute(ctx) {
      // console.info("> updateNote id:", ctx.noteId, 'content:', content, 'context:', ctx)
      let note = getNoteById(ctx.noteId)
      const prevSnapshot = ctx.snapshot = Snapshot.serializeNote(note.parent)
      note.repositionTo(ctx.position)

      App.instance.events.onNoteUpdate.dispatch({ note: note.parent, prevSnapshot })
      // App.instance.events.onNoteUpdate.dispatch({ note, prevSnapshot })
      return note
    },

    revert(ctx) {
      let note = getNoteById(ctx.noteId)
      const prevSnapshot = Snapshot.serializeNote(note)
      note.repositionTo(ctx.prevPosition)

      App.instance.events.onNoteUpdate.dispatch({ note: note.parent, prevSnapshot })
    },

    shouldCoalesce(ctx, prevCtx) {
      return prevCtx.isNewNote && prevCtx.noteId === ctx.noteId && !prevCtx._isCoalesced
    }
  })
}