import { IconClasses } from '@blueprintjs/core';
import { action, decorate, observable } from 'mobx';
import Selection from './SelectionManager';

interface IConfirmationOptions {
  iconName?: string
  title?: string
  body?: string | JSX.Element
  yesLabel?: string
  noLabel?: string
}

const defaultOptions = {
  iconName: IconClasses.CONFIRM,
  title: "Please Confirm",
  body: "Are you sure?",
  yesLabel: "Yes",
  noLabel: "No"
}

export class ConfirmationManager {

  whenReady = Promise.resolve(this)

  isVisible = false
  options: IConfirmationOptions = {}

  private _resolvePromise: any

  show(options?: Partial<IConfirmationOptions>): Promise<boolean> {
    if (!!this._resolvePromise) {
      return Promise.reject(new Error("Confirmation already in progress!"))
    }

    Selection.service.setEnabled(false)

    this.options = Object.assign({}, defaultOptions, options)
    this.isVisible = true

    return new Promise(resolve => {
      this._resolvePromise = resolve
    })
  }

  hide(returnValue: boolean) {
    Selection.service.setEnabled(true)
    this.isVisible = false
    this._resolvePromise(returnValue)
    setTimeout(() => {
      this._resolvePromise = null
    })
  }

  // Singleton

  static get service() { return this._instance || (this._instance = new this()) }
  private static _instance: ConfirmationManager
}

decorate(ConfirmationManager, {
  isVisible: observable,
  options: observable,

  show: action,
  hide: action,
})

export default ConfirmationManager