import CM from 'codemirror';
import 'codemirror-markdown-list-autoindent/autoindent';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/dialog/dialog.css';
import 'codemirror/addon/display/panel';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/continuelist';
import 'codemirror/addon/fold/comment-fold';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/fold/markdown-fold';
import 'codemirror/addon/scroll/annotatescrollbar';
import 'codemirror/addon/search/jump-to-line';
import 'codemirror/addon/search/match-highlighter';
import 'codemirror/addon/search/matchesonscrollbar';
import 'codemirror/addon/search/matchesonscrollbar.css';
import 'codemirror/addon/search/search';
import 'codemirror/addon/search/searchcursor';
import 'codemirror/addon/selection/active-line';
import 'codemirror/keymap/sublime';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/gfm/gfm';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/yaml-frontmatter/yaml-frontmatter';
import 'codemirror/mode/yaml/yaml';
import * as React from 'react';
import { Application } from '../services/Application';
import { NEW_NOTE_CONTENT } from '../services/NoteManager/index';
import { assignableRef } from '../utils/AssignableRef';
import { autoDisposer } from '../utils/AutoDisposer';
import { classNames, style } from '../utils/Stylesheet';
import './CodemirrorEditor.css';

export type EditorDoc = CodeMirror.Doc
export type EditorCommands = 'split' | 'add-peer' | 'add-child'

export function createEditorDoc(markdownContent: string): CodeMirror.Doc {
  const doc = CM.Doc(markdownContent, MarkdownEditor._codeMirrorConfig.mode)
  // if (markdownContent == NEW_NOTE_CONTENT) {
  //   doc.setSelection({ line: 0, ch: 0 }, { line: doc.lineCount(), ch: doc.lastLine.length })
  // }
  return doc
}

interface MarkdownEditorProps {
  disabled?: boolean
  autoFocus?: boolean
  doc: CM.Doc | string,
  onCommand?: (cmd: EditorCommands, ctx?: any) => void
  onChange?: (value: string) => void | any
  onConfirm?: (value: string) => void
  onCancel?: () => void
  onDocChange?: (doc: EditorDoc, prevDoc: EditorDoc) => void | any
}


export class MarkdownEditor extends React.Component<MarkdownEditorProps> {
  private editorDomRoot!: HTMLDivElement
  private codeMirror!: CM.Editor
  private charWidth: number = -1
  private charHeight: number = -1
  autoDispose = autoDisposer(this)

  // @DI.inject(AppEvents) protected eventBus!: AppEvents
  // @DI.injectDynamic(() => UI) protected ui!: UI

  activeDoc!: EditorDoc

  static _codeMirrorConfig: CodeMirror.EditorConfiguration = {
    mode: {
      name: 'yaml-frontmatter', // 'gfm', //'markdown',
      base: {
        name: 'gfm',
        highlightFormatting: true
      },
      highlightFormatting: true
    },
    dragDrop: false,
    lineWrapping: true,
    lineNumbers: false,
    // lineNumbers: true,
    autofocus: true,
    theme: 'notetree',
    foldGutter: false,
    gutters: [], //"CodeMirror-linenumbers", "CodeMirror-foldgutter"
    keyMap: 'sublime',
    cursorHeight: 1, //0.85,
    tabSize: 4,
    indentUnit: 4,
    indentWithTabs: true,
  }
  static _codeMirrorExtraConfig = { // Untyped, unfortunately
    autoCloseBrackets: {
      pairs: `()[]{}''""**__~~\`\``, //==
      override: true
    },
    cursorScrollMargin: 50,
    highlightSelectionMatches: {
      minChars: 3,
      // showToken: /\w/,
      annotateScrollbar: true,
      delay: 150,
      wordsOnly: true
    },
    // styleActiveLine: true,
    pollInterval: 250,

    showInvisibles: true,
    // maxInvisibles: 16 // optional
  }


  codemirrorRoot = assignableRef<HTMLDivElement>(this, (elem) => {
    // console.log("HERE WE GO!")
    if (!!elem) this.editorDomRoot = elem
    // if (!elem) {
    //   console.warn("NO NODE!")
    // }
    // else {
    //   console.warn("NODE!")
    //   this.editorDomRoot = elem
    // }
  })


  render() {
    const fontStyles = {
      fontFamily: Application.service.UI.editorFont,
      fontSize: Application.service.UI.editorFontSize,
    }
    // console.log("Font Styles:", fontStyles)
    return (
      <div className={classNames('MarkdownEditor', CSS.ContainerClass)} style={fontStyles}>
        <div className={classNames('CodeMirrorContainer', CSS.CodeMirrorContainerClass)} ref={this.codemirrorRoot.assign} />
      </div>
    )
  }

  setReadOnly(readonly: boolean) {
    const readOnlyState = readonly
      ? 'nocursor'
      : false

    this.codeMirror.setOption("readOnly", readOnlyState)

    if (readonly) {
      this.editorDomRoot.classList.add('isReadOnly')
    }
    else {
      this.editorDomRoot.classList.remove('isReadOnly')
    }
  }

  shouldComponentUpdate(nextProps: MarkdownEditorProps) {
    return false

    // console.log("SHOULD update?", this.props.doc != nextProps.doc)

    // if (nextProps.doc != this.activeDoc) {
    //   this.setActiveDoc(nextProps.doc)
    // }

    // if (nextProps.disabled != this.props.disabled) {
    //   this.setReadOnly(nextProps.disabled == true)
    // }

    // return false
  }

  private setActiveDoc = (doc: any) => {
    if (typeof doc == 'string') {
      doc = createEditorDoc(doc)
    }
    this.activeDoc = doc ///this.codeMirror.getDoc()
    if (!this.codeMirror) return

    this.codeMirror.swapDoc(doc)
    const oldDoc = this.activeDoc
    this.props.onDocChange && this.props.onDocChange(this.activeDoc, oldDoc)

    if (this.activeDoc.getValue() == NEW_NOTE_CONTENT) {
      console.log("SELECT ALL!")
      this.codeMirror.execCommand('selectAll')
    }
  }

  componentDidMount() {
    // console.warn("componentDidMount() => Creating CodeMirror!")
    const cm: CM.Editor = this.codeMirror = CM(this.editorDomRoot as any, {
      value: this.props.doc || "",
      ...MarkdownEditor._codeMirrorConfig,
      ...MarkdownEditor._codeMirrorExtraConfig,
      autoFocus: this.props.autoFocus,
      theme: Application.service.UI.useDarkMode ? 'notetree-dark' : 'notetree'
    } as any)

    // console.log("Creating CodeMirror!", this.props.autoFocus)

    const dim = this.findEditorCharDimensions()
    this.charWidth = dim.width
    this.charHeight = dim.height

    // // TODO: HACK: Need to find a better way to do this.
    // const sizer = document.querySelector<HTMLElement>('.CodeMirror-sizer')
    // if (sizer != null) {
    //   // sizer.style.maxWidth = `${this.charWidth * 65}px`
    //   sizer.style.maxWidth = '65ch'
    // }



    this.setActiveDoc(cm.getDoc()) //this.props.doc)

    const noop = () => false

    this.codeMirror.setOption('extraKeys', {
      "Enter": "newlineAndIndentContinueMarkdownList",
      "defaultTab": function (cm: any) {
        var spaces = Array(cm.getOption("indentUnit") + 1).join(" ");
        cm.replaceSelection(spaces);
      },
      "Tab": "autoIndentMarkdownList",
      "Shift-Tab": "autoUnindentMarkdownList",
      "Cmd-D": "selectNextOccurrence",
      "Ctrl-D": "selectNextOccurrence",
      "Ctrl-Q": toggleFold,
      'Cmd-B': toggleBold,
      'Ctrl-B': toggleBold,
      'Cmd-I': toggleItalics,
      'Ctrl-I': toggleItalics,
      'Cmd-H': toggleHighlight,
      'Ctrl-H': toggleHighlight,
      "Cmd-/": toggleComment,
      "Ctrl-/": toggleComment,

      "Cmd-K": this.handleCommand('split'),
      "Ctrl-K": this.handleCommand('split'),

      "Cmd-1": toggleHeader('#'),
      "Ctrl-1": toggleHeader('#'),
      "Cmd-2": toggleHeader('##'),
      "Ctrl-2": toggleHeader('##'),
      "Cmd-3": toggleHeader('###'),
      "Ctrl-3": toggleHeader('###'),
      "Cmd-4": toggleHeader('####'),
      "Ctrl-4": toggleHeader('####'),
      "Cmd-5": toggleHeader('#####'),
      "Ctrl-5": toggleHeader('#####'),

      "Cmd-S": this.handleCommit,
      "Ctrl-S": this.handleCommit,
      "Cmd-Enter": this.handleCommit,
      "Alt-Enter": this.handleCommit,
      "Ctrl-Enter": this.handleCommit,

      "Shift-Ctrl-Enter": this.handleCommand('add-peer'),
      "Shift-Cmd-Enter": this.handleCommand('add-peer'),

      "Ctrl-Alt-Enter": this.handleCommand('add-child'),
      "Cmd-Alt-Enter": this.handleCommand('add-child'),


      "Esc": this.handleCancel,
    })

    if (this.props.onChange != null) {
      cm.on('changes', this.codemirror_onChange)
      this.autoDispose(() => cm.off('changes', this.codemirror_onChange))
    }


    cm.on("renderLine", lineRenderer);
    cm.on('blur', this.handleCommit)

    // this.ui.registerCodeMirror(cm)
    this.autoDispose(
      // this.ui.registerCodeMirror(cm),
      () => cm.off('renderLine', lineRenderer),
      () => cm.off('blur', this.handleCommit),
      // () => this.ui.unregisterCodeMirror(cm),
      // this.eventBus.FocusEditor.subscribe((e) => {
      //   cm.focus()
      // }),
      // this.eventBus.ScrollEditorTo.subscribe((e) => {
      //   cm.scrollIntoView(e.data)
      // }),
      // this.eventBus.ScrollToHeading.subscribe(e => {
      //   const dim = RectTools.getBoundingRect(this)
      //   const doc = this.activeDoc
      //   const pos = doc.posFromIndex(e.data.charOffset)
      //   const margin = Math.floor(dim.height / 2) - (this.charHeight * 2)
      //   const line = doc.getLine(pos.line)

      //   cm.focus()
      //   doc.setCursor(pos)
      //   cm.scrollIntoView(pos, margin)
      //   doc.setSelection(pos, { line: pos.line, ch: line.length })
      // })
    )

    this.setReadOnly(this.props.disabled == true)

    // if (this.props.autoFocus) {
    //   setTimeout(() => {
    //     this.eventBus.FocusEditor.dispatch({})
    //   }, 100)
    // }
  }

  handleCommand = (cmd: EditorCommands) => () => {
    const ctx: any = {}

    if (cmd == 'split') {
      const doc = this.activeDoc
      const pos = doc.getCursor('start')
      const endPos = doc.getCursor('end')
      const lastLine = doc.lastLine()

      ctx.contentBefore = doc.getRange({ line: 0, ch: 0 }, pos)
      ctx.contentAfter = doc.getRange(endPos, { line: lastLine, ch: doc.getLine(lastLine).length })
      ctx.contentSelected = doc.getSelection()

      // let line = 0
      // const before: string[] = []
      // const after: string[] = []

      // while (line < pos.line) {
      //   before.push(doc.getLine(line))
      //   line++
      // }

      // const currLine = doc.getLine(pos.line)
      // before.push(currLine.substring(0, pos.ch))
      // after.push(currLine.substring(pos.ch))

      // line++

      // while (line <= lastLine) {
      //   after.push(doc.getLine(line))
      //   line++
      // }

      // ctx.contentBefore = before.join('\n') //|| NEW_NOTE_CONTENT
      // ctx.contentAfter = after.join('\n') //|| NEW_NOTE_CONTENT
    }

    this.props.onCommand && this.props.onCommand(cmd, ctx)
  }

  handleCommit = () => {
    this.props.onConfirm && this.props.onConfirm(this.codeMirror.getValue())
  }
  handleCancel = () => {
    this.props.onCancel && this.props.onCancel()
  }

  codemirror_onChange = (cm: CodeMirror.Editor, changes: any[]) => {
    this.props.onChange && this.props.onChange(cm.getValue())
  }

  componentWillUnmount() {
    // console.warn("componentWillUnmount() => Destroying CodeMirror!")
    // console.log("THIS CODEMIRROR?", this.codeMirror)
    this.autoDispose.disposeAll()
    this.codeMirror.swapDoc(createEditorDoc(""))
  }

  // updateHeaderAdjust(lineEl: HTMLElement)
  // {
  //   if (this.charWidth == null || lineEl.textContent == null)
  //   {
  //     return
  //   }

  //   const m = lineEl.textContent.match(/^#+\s*/);
  //   if (m != null) { return lineEl.style.marginLeft = (m[0].length * -this.charWidth) + 'px'; }
  // }

  findEditorCharDimensions() {
    const code = document.querySelector('.CodeMirror-code')
    if (code == null) return { width: 0, height: 0 };

    const textNode = document.createTextNode(new Array(61).join('#'));
    const preEl = document.createElement('pre');
    preEl.style.whiteSpace = 'nowrap'

    preEl.appendChild(textNode);
    code.appendChild(preEl);
    const range = document.createRange();

    range.selectNodeContents(textNode);

    const bb = range.getBoundingClientRect();
    // preEl.parentNode.removeChild(preEl);
    code.removeChild(preEl);

    return {
      width: bb.width / 60,
      height: bb.height
    }
  }

}


export default MarkdownEditor

const CSS = {
  ContainerClass: style({
    display: 'flex',
    flex: 1,
    // outline: '2px solid dodgerblue'
  }),
  CodeMirrorContainerClass: style({
    flex: 1,
    // paddingLeft: 12,
    // outline: '2px solid dodgerblue',
    // paddingTop: 12,
    // paddingBottom: 12,

    $nest: {
      "&.isReadOnly": {
        backgroundColor: 'transparent',
      },

      "&.isReadOnly *": {
        backgroundColor: 'transparent !important',
        borderColor: 'transparent !important',
      },

      "&>.CodeMirror": {
        // paddingTop: 24,
        // paddingBottom: 24,
      },
    }
  })
}

function wrapSelection(cm: CodeMirror.Doc, startTag: string, endTag: string = startTag) {
  var selection = cm.getSelection();
  cm.replaceSelection(startTag + selection + endTag);

  if (!selection) {
    var ed = cm.getEditor()
    var cursorPos = cm.getCursor();
    cm.setCursor({ line: cursorPos.line, ch: cursorPos.ch - startTag.length });
  }
}

const toggleBold = inlineTagSupport('**', new RegExp("\\*+\\*", "gm"))
const toggleItalics = inlineTagSupport('_', new RegExp("\\_", "gm"))
// const toggleItalics = inlineTagSupport('*', new RegExp("\\*", "gm"))
const toggleHighlight = inlineTagSupport('==', new RegExp("\\=+\\=", "gm"))

function inlineTagSupport(mark: string, re: RegExp) {
  return (editor: CodeMirror.Editor) => {
    const doc = editor.getDoc()
    let str = doc.getSelection().trim();

    // No selection
    if (str === "") {
      editor.operation(() => {
        doc.replaceSelection(`${mark}${mark}`);
        const pos = doc.getCursor()
        doc.setCursor({ line: pos.line, ch: pos.ch - 2 })
      })
      return;
    }

    if (str.indexOf(mark) != -1) {
      if (str.indexOf(mark) != str.lastIndexOf(mark)) {
        if (str.indexOf(mark) == 0 && str.lastIndexOf(mark) == str.length - mark.length) {
          str = str.replace(re, "");
        } else {
          str = mark + str.replace(re, "") + mark;
        }
      } else {
        str = mark + str.replace(mark, "");
      }
    } else {
      str = mark + str + mark;
    }

    editor.operation(() => {
      doc.replaceSelection(str);
      var cursorPos = doc.getCursor();
      doc.setCursor({ line: cursorPos.line, ch: cursorPos.ch - str.length });
      doc.extendSelection(
        { line: cursorPos.line, ch: cursorPos.ch - str.length },
        { line: cursorPos.line, ch: cursorPos.ch }
      )
    })
  }
}



const headerRE = /^[#]*[\s]*/i

function toggleHeader(header: string) {
  // if (!header.endsWith(' ')) header += " "
  const headerLen = header.length

  return (editor: CodeMirror.Editor) => {
    const doc = editor.getDoc()
    const pos = doc.getCursor()
    const line = doc.getLine(pos.line)
    // const adjustCursor = line == ""//editor.getDoc().getSelection() == ""  //!editor.getDoc().somethingSelected
    const match = line.match(headerRE)

    if (match) {
      const [frag] = match

      doc.setSelection({ ch: 0, line: pos.line }, { ch: frag.length, line: pos.line })

      if (frag.trim() == header) {
        // Remove header
        doc.replaceSelection('')
        doc.setCursor({ line: pos.line, ch: (pos.ch - frag.length) })
      }
      else {
        // Replace header
        doc.replaceSelection(`${header} `)
        doc.setCursor({ line: pos.line, ch: (pos.ch - frag.length + header.length + 1) })
      }
    }
    else {
      // Add header
      doc.setSelection({ ch: 0, line: pos.line }, { ch: 0, line: pos.line })
    }
  }
}

function toggleComment(editor: CodeMirror.Editor) {
  var doc = editor.getDoc()
  const pos = doc.getCursor()
  const line = editor.getDoc().getLine(pos.line)
  const adjustCursor = line == ""//editor.getDoc().getSelection() == ""  //!editor.getDoc().somethingSelected

  if (doc.somethingSelected()) {
    wrapSelection(doc, "<!--", "-->")
  }
  else {
    // Stupid dance for TypeScript
    var toggle: any = (editor as any)['toggleComment'].bind(editor)
    // console.log("Toggle Comment!", { adjustCursor, line })
    toggle({
      blockCommentStart: '<!--',
      blockCommentEnd: '-->'
    });
  }

  if (adjustCursor) {
    doc.setCursor({ line: pos.line, ch: pos.ch + 5 })
  }
}

function toggleFold(cm: any) {
  cm.foldCode(cm.getCursor());
}

function innerModeForLine(cm: any, line: any) {
  return cm.getModeAt({ line: line.lineNo() }).name
}


function lineRenderer(cm: CodeMirror.Editor, line: CodeMirror.LineHandle, elt: HTMLElement) {
  const firstChar = line.text[0]
  if (firstChar == ">") { //line.text.indexOf(">") == 0) {
    if (!elt.classList.contains('quote-line')) {
      elt.classList.add('quote-line')
    }
  }
  else if (firstChar == "#") { //line.text.indexOf("#") == 0) {
    if (!elt.classList.contains('header-line')) {
      elt.classList.add('header-line')
    }
  }
  else if (innerModeForLine(cm, line) == 'yaml') {
    if (!elt.classList.contains('yaml')) {
      elt.classList.add('yaml')
    }
  }
}







// function toggleBoldOld(editor: CodeMirror.Editor) {
//   const doc = editor.getDoc()
//   let str = doc.getSelection().trim();

//   // No selection
//   if (str === "") {
//     editor.operation(() => {
//       doc.replaceSelection("****");
//       const pos = doc.getCursor()
//       doc.setCursor({ line: pos.line, ch: pos.ch - 2 })
//     })
//     return;
//   }

//   if (str.indexOf("**") != -1) {
//     if (str.indexOf("**") != str.lastIndexOf("**")) {
//       if (str.indexOf("**") == 0 && str.lastIndexOf("**") == str.length - 2) {
//         str = str.replace(new RegExp("\\*+\\*", "gm"), "");
//       } else {
//         str = "**" + str.replace(new RegExp("\\*+\\*", "gm"), "") + "**";
//       }
//     } else {
//       str = "**" + str.replace("**", "");
//     }
//   } else {
//     str = "**" + str + "**";
//   }

//   editor.operation(() => {
//     doc.replaceSelection(str);
//     var cursorPos = doc.getCursor();
//     doc.setCursor({ line: cursorPos.line, ch: cursorPos.ch - str.length });
//     doc.extendSelection(
//       { line: cursorPos.line, ch: cursorPos.ch - str.length },
//       { line: cursorPos.line, ch: cursorPos.ch }
//     )
//   })
// }

// function toggleItalicsOLD(editor: CodeMirror.Editor) {
//   var doc = editor.getDoc()
//   var str = doc.getSelection().trim();
//   if (str === "") {
//     editor.operation(() => {
//       doc.replaceSelection("**");
//       const pos = doc.getCursor()
//       doc.setCursor({ line: pos.line, ch: pos.ch - 1 })
//     })
//     return;
//   }
//   if (str.indexOf("*") != -1) {
//     if (str.indexOf("*") != str.lastIndexOf("*")) {
//       if (str.indexOf("*") == 0 && str.lastIndexOf("*") == str.length - 1) {
//         str = str.replace(new RegExp("\\*", "gm"), "");
//       } else {
//         str = "*" + str.replace(new RegExp("\\*", "gm"), "") + "*";
//       }
//     } else {
//       str = "*" + str.replace("*", "") + "*";
//     }
//   }
//   else {
//     str = "*" + str + "*";
//   }

//   editor.operation(() => {
//     doc.replaceSelection(str);
//     var cursorPos = doc.getCursor();
//     // doc.setCursor({ line: cursorPos.line, ch: cursorPos.ch - str.length });
//     doc.extendSelection(
//       { line: cursorPos.line, ch: cursorPos.ch - str.length },
//       { line: cursorPos.line, ch: cursorPos.ch }
//     )
//   })
//   // doc.replaceSelection(str);
//   //editor.doc.getSelection() = "**"+editor.doc.getSelection()+"**";
// }
