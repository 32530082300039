import Application from "../services/Application";
import { Note } from "../services/NoteManager/Note";
import { cancelEvent } from "../utils/FunctionTools";
import * as commands from '../commands'


export function select(e?: any, note?: Note) {
  if (!note) {
    console.warn("Cannot select null -- Must be instance of Note!"); // Application.service.notifications.show({
    //   intent: Intent.DANGER,
    //   message: 'Cannot select .',
    //   timeout: 1500
    // })
  } else {
    Application.service.selectionMgr.select(note);
  }
}

export function selectTop(e?: KeyboardEvent) {
  cancelEvent(e);
  if (Application.service.selectionMgr.isDisabled) return;
  const currNote = Application.service.selectionMgr.selectedNote;
  if (!currNote) return
  Application.service.selectionMgr.select(currNote.parent.children[0]);
}

export function selectNext(e?: KeyboardEvent) {
  cancelEvent(e);
  Application.service.selectionMgr;
  if (Application.service.selectionMgr.isDisabled) return;

  if (e && e.altKey) {
    const currNote = Application.service.selectionMgr.selectedNote;
    if (!currNote) return
    const currIdx = currNote.parent.indexOf(currNote); // const newNote = currNote.parent.addChildNote(NEW_NOTE_CONTENT, currIdx + 1)

    const note = commands.createNote(currNote.parent, undefined, currIdx + 1);
    Application.service.selectionMgr.select(note);
    Application.service.selectionMgr.enterEditMode();
  } else {
    Application.service.selectionMgr.selectNextSibling();
  }
}

export function selectPrev(e?: KeyboardEvent) {
  cancelEvent(e);
  if (Application.service.selectionMgr.isDisabled) return;

  if (e && e.altKey) {
    const currNote = Application.service.selectionMgr.selectedNote;
    if (!currNote) return
    const currIdx = currNote.parent.indexOf(currNote); // const newNote = currNote.parent.addChildNote(NEW_NOTE_CONTENT, currIdx)

    const note = commands.createNote(currNote.parent, undefined, currIdx);
    Application.service.selectionMgr.select(note);
    Application.service.selectionMgr.enterEditMode();
  } else {
    Application.service.selectionMgr.selectPreviousSibling();
  }
}



export function selectLast(e?: KeyboardEvent) {
  cancelEvent(e)
  if (Application.service.selectionMgr.isDisabled) return
  const currNote = Application.service.selectionMgr.selectedNote
  if (!currNote) return
  Application.service.selectionMgr.select(currNote.parent.children[currNote.parent.children.length - 1])
}

export function selectChild(e?: KeyboardEvent) {
  cancelEvent(e)
  if (Application.service.selectionMgr.isDisabled) return
  if (e && e.altKey) {
    const currNote = Application.service.selectionMgr.selectedNote
    if (!currNote) return
    // const newNote = currNote.addChildNote(NEW_NOTE_CONTENT)
    const note = commands.createNote(currNote)
    Application.service.selectionMgr.select(note)
    Application.service.selectionMgr.enterEditMode()
  }
  else {
    Application.service.selectionMgr.selectChild()
  }
}

export function selectParent(e?: KeyboardEvent) {
  cancelEvent(e)
  if (Application.service.selectionMgr.isDisabled) return
  if (!Application.service.selectionMgr.selectParent() && Application.service.selectionMgr.isHoisted) {
    console.warn("Attempted to navigation above hoisted note.")
    // Notifications.show({
    //   iconName: IconClasses.WARNING_SIGN,
    //   intent: Intent.DANGER,
    //   message: "Could not select parent."
    // })
  }
}

export function selectTopmost(e?: KeyboardEvent) {
  cancelEvent(e)
  if (Application.service.selectionMgr.isDisabled) return
  const rootNote = Application.service.selectionMgr.isHoisted
    ? Application.service.selectionMgr.rootNote
    : Application.service.noteMgr.rootNote
  const topNote = rootNote.children.find(n => Application.service.selectionMgr.isNoteParentOfSelected(n))

  if (topNote)
    Application.service.selectionMgr.select(topNote)
  else
    console.warn('Topmost note not found.')
}

export function selectBottomLeaf(e?: KeyboardEvent) {
  cancelEvent(e)
  if (Application.service.selectionMgr.isDisabled) return

  let childNote = Application.service.selectionMgr.selectedNote

  while (childNote && childNote.hasChildren) {
    childNote = childNote.children[0]
  }
  if (childNote)
    Application.service.selectionMgr.select(childNote)
  else
    console.warn('Leaf note not found.')
}