import { cancelEvent } from '../utils/FunctionTools';

export * from './crud'
export * from './selection'
export * from './tools'
export * from './ui'


export function doNothing(e?: any) {
  cancelEvent(e)
}


