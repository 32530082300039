/**
 * For use with React Components. Example usage:
 *   
 *   usernameInput = assignableRef<HTMLInputElement>(this)
 * 
 *  // In render...
 * 
 *  <input type="text" ref={this.usernameInput.assign} />
 * 
 *  // In a handler somewhere...
 * 
 *  doX() {
 *   this.usernameInput.ref.value // (.ref to get the referenced item)
 *  }
 */
export function assignableRef<T = any>(autoDisposableContainer?: any, onAssignment?: (elem: T | undefined) => void) {
  let __ref: any = null

  const api = {

    assign(object: any) {
      __ref = object
      if (onAssignment) onAssignment(object)
    },

    dispose() {
      __ref = null
      if (onAssignment) onAssignment(undefined)
    },

    get ref(): T {
      return __ref
    },

    get isAssigned() {
      return !!__ref
    }
  }

  if (autoDisposableContainer && autoDisposableContainer.autoDispose) {
    autoDisposableContainer.autoDispose(api)
  }

  return api
}

export default assignableRef