import { computed, decorate } from 'mobx'
import Session from '../../utils/Session'
import Events from '../EventManager'
import initialContent from '../../initialContent'
import * as commands from '../../commands'
import Snapshot, { INoteSnapshot } from '../../utils/Snapshot'
import { Note } from './Note'

export class NoteManager {
  whenReady: Promise<NoteManager>
  rootNote!: Note
  trashBin!: Note

  commands = commands
  session = new Session('app')

  constructor() {
    this.whenReady = this.initialize()
  }


  // @computed 
  get latestUpdate() {
    let lastUpdate = this.rootNote.updated

    const checkUpdated = (note: Note) => {
      if (note.updated > lastUpdate) lastUpdate = note.updated
      if (note.created > lastUpdate) lastUpdate = note.created
      note.children.forEach(checkUpdated)
    }

    this.rootNote.children.forEach(checkUpdated)

    return lastUpdate
  }

  toJson() {
    return Snapshot.serializeNote(this.rootNote)
  }

  fromJson(json: INoteSnapshot) {
    this.rootNote = Snapshot.deserializeNote(json)
  }

  getById = (id: string | number, includeTrash = false): Note => {
    if (includeTrash) {
      return (
        this.rootNote.findById(id) ||
        this.trashBin.findById(id)
      ) as any
    }
    else {
      return this.rootNote.findById(id) as any
    }
  }

  getByIdOrThrow = (id: string | number, includeTrash = false): Note => {
    let note = this.getById(id, includeTrash)
    if (note == null) throw new Error(`No note with an id of '${id}' found.`)
    return note
  }

  private async initialize() {
    const localDb = this.session.get('db')

    if (localDb) {
      this.fromJson(localDb)
    }
    else {
      this.rootNote = Note.fromContent('# My NoteTree')
      initialContent(this.rootNote)
    }

    const trashDb = this.session.get('trashbin')
    if (trashDb) {
      this.trashBin = Snapshot.deserializeNote(trashDb)
    }
    else {
      this.trashBin = Note.fromContent("---\n_$TrashRoot: true\n---\n# Trash Bin")
    }

    Events.service.onNotesLoaded.dispatch({
      root: this.rootNote,
      trash: this.trashBin
    })

    // const snap = Snapshot.serializeNote(this.rootNote)

    // Object.keys(snap.notes).forEach(noteId => {
    //   const note = snap.notes[noteId]
    //   notesDb.setItem(String(note.id), note)
    // })

    return this
  }

  // Singleton

  static getById(id: string | number): Note {
    return this.service.getById(id)
  }

  static get service() { return this._instance || (this._instance = new this()) }
  private static _instance: NoteManager
}

decorate(NoteManager, {
  latestUpdate: computed
})

export default NoteManager



// export interface INoteJson {
//   id: string | number
//   parentId: string | number | undefined
//   position: number
//   content: string
//   created: number
//   updated: number
//   children: INoteJson[]
// }

// export function noteToJson(note: Note): INoteJson {
//   return {
//     id: note.id,
//     parentId: note.parent && note.parent.id,
//     position: (note.parent && note.position) || -1,
//     content: note.content,
//     created: note.created,
//     updated: note.updated,
//     children: note.children.map(noteToJson)
//   }
// }

// export function jsonToNote(json: INoteJson, parent?: Note): Note {
//   const parentNote = parent !== undefined && parent.id == json.parentId
//     ? parent
//     : undefined

//   const newNote = Note.fromContent(json.content, parentNote, json.id)

//   newNote.created = json.created
//   newNote.updated = json.updated

//   if (!newNote.parent) {
//     console.debug(`Note: "${newNote.title}" has an empty parent!`)
//   }

//   newNote.children = json.children.map(jsChild => jsonToNote(jsChild, newNote))

//   return newNote
// }

// window['noteToJson'] = noteToJson
// window['jsonToNote'] = jsonToNote
