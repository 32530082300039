import Application from "../services/Application";
import { Note } from "../services/NoteManager/Note";
import { cancelEvent } from "../utils/FunctionTools";
import * as commands from '../commands'
import { SplitType, SplitSelectionContext } from "../commands";
import { Intent, IconClasses } from "@blueprintjs/core";

export function dissolveNote(e?: any, note?: Note) {
  cancelEvent(e)
  if (Application.service.selectionMgr.isDisabled) return
  const currNote = note || Application.service.selectionMgr.selectedNote

  if (!currNote) return

  const doDissolve = () => {
    Application.service.selectionMgr.selectNearest(currNote)
    const firstNewNote = commands.dissolveNote(currNote)
    if (firstNewNote)
      Application.service.selectionMgr.select(firstNewNote)
  }

  if (Application.service.UI.shouldConfirmDeletes) {
    Application.service.confirmation.show({}).then((confirmation) => {
      if (confirmation) {
        doDissolve()
      }
    }).catch((error) => {
      Application.service.notifications.show({
        intent: Intent.DANGER,
        iconName: IconClasses.WARNING_SIGN,
        message: error.message
      })
    })
  }
  else {
    doDissolve()
  }
}

export function mergeUp(e?: any, currNote?: Note) {
  cancelEvent(e)
  // if (Application.service.selectionMgr.isDisabled) return
  currNote = currNote || Application.service.selectionMgr.selectedNote
  if (!currNote) return
  commands.mergeNote(currNote, 'up')
}

export function mergeDown(e?: any, currNote?: Note) {
  cancelEvent(e)
  // if (Application.service.selectionMgr.isDisabled) return
  currNote = currNote || Application.service.selectionMgr.selectedNote
  if (!currNote) return
  commands.mergeNote(currNote, 'down')
}

export function splitNote(e?: any, note?: Note, splitType?: SplitType, context?: SplitSelectionContext) {
  if (Application.service.selectionMgr.isDisabled) return
  cancelEvent(e)
  note = note || Application.service.selectionMgr.selectedNote

  if (!note) return

  const noteToSelect = Application.service.noteMgr.commands.splitNote(note, splitType, context)

  if (splitType == 'selection') {
    Application.service.selectionMgr.select(noteToSelect)
    Application.service.selectionMgr.enterEditMode()
  }
}


export function restoreNote(e: any, note: Note) {
  cancelEvent(e)
  const { events, noteMgr, selectionMgr } = Application.service

  // TODO: How should this play with Undo?
  noteMgr.trashBin.removeChildNote(note)

  const parent = noteMgr.getById(note.metadata.trash.parentId) || noteMgr.rootNote
  const position = note.metadata.trash.position || -1
  note.removeMetadata('trash')

  parent.addChildNote(note, position)
  events.onNoteRestored.dispatch({ note })

  selectionMgr.onceEnabled(() => {
    console.log("Selecting note:", note.title)
    selectionMgr.select(note)
  })
}