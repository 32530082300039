import { action, autorun, computed, decorate, observable } from 'mobx';
import { createTransformer } from 'mobx-utils';
import Events from './EventManager';
import NoteManager, { Note } from './NoteManager';

type EnabledCallback = () => void

/**
 * The SelectionManager maintains:
 *  - rootNote / firstNote (app root note, or hoisted note)
 *  - selectedNote / lastNote (currently selected note)
 *  - mode (view/edit) (?)
 *      OR
 *  - isMutable (whether the selection can change)
 * 
 * It will dynamically create:
 *  - A list of visible cards (for CardListView)
 * 
 * Notes:
 *  - firstNote must always be a parent of lastNote
 */
export class SelectionManager {
  whenReady: Promise<SelectionManager>

  disabledCount = 0
  inEditMode = false
  rootNote!: Note
  selectedNote!: Note
  prevouslySelected!: Note

  private onceEnabledCallbacks: EnabledCallback[] = []

  get isEnabled() {
    return this.disabledCount === 0
  }

  get isDisabled() {
    return !this.isEnabled || this.inEditMode
  }

  get selectedParentIds(): (string | number)[] {
    let note: Note = this.selectedNote
    let ids: (string | number)[] = []

    while (note = !!note && note.parent) {
      ids.push(note.id)

      if (note === this.rootNote) {
        break;
      }
    }

    return ids
  }

  get selectionChain(): Note[] {
    if (!this.selectedNote) {
      return [this.rootNote]
    }

    let note: Note = this.selectedNote
    let notes: Note[] = [note]

    while (note = note.parent) {
      notes.unshift(note)

      if (note === this.rootNote) {
        break;
      }
    }

    return notes
  }

  get selectionChainLength(): number {
    return this.selectionChain.length
  }

  get hasSelection() {
    return !!this.selectedNote
  }

  get isHoisted() {
    return this.rootNote !== NoteManager.service.rootNote
  }

  get canHoist() {
    return this.hasSelection && this.selectedNote.children.length > 0
  }

  constructor(rootNote?: Note, isReadyOnly: boolean = false) {
    this.whenReady = this.initialize()
    autorun(() => {
      if (this.isEnabled == true) {
        // console.log("selectionManager.isEnabled: Calling onceEnabledCallbacks")
        let callback = this.onceEnabledCallbacks.shift()
        while (callback) {
          callback()
          callback = this.onceEnabledCallbacks.shift()
        }
      }
      // else {
      //   console.log("selectionManager.isDisabled")
      // }
    })
  }

  onceEnabled(callback: EnabledCallback) {
    if (this.isEnabled) callback()
    else this.onceEnabledCallbacks.push(callback)
  }

  setEnabled(enabled: boolean) {
    // console.trace("Setting selectionMgr.enabled =", enabled)
    if (enabled) {
      this.disabledCount -= 1
    }
    else {
      this.disabledCount += 1
    }
  }

  select(note: Note) {
    if (this.isDisabled) {
      console.warn("SelectionManager is currently disabled!")
      return false
    }
    if (this.selectedNote === note) {
      // console.warn("Trying to re-select same note!")
      return false
    }


    if (note == this.rootNote || !this.rootNote.findById(note.id)) {
      // throw new Error("Selection must be a note in view") // Must be a child of the rootNote
      console.warn(
        "Tried to select the rootNote or specified note isn't a child of the rootNote!"
      )
      this.prevouslySelected = this.selectedNote
      this.selectedNote = void 0 as any
      Events.service.onSelectionChanged.dispatch({
        selectedNote: this.selectedNote,
        previousNote: this.prevouslySelected
      })
      return false
    }

    this.prevouslySelected = this.selectedNote
    this.selectedNote = note

    Events.service.onSelectionChanged.dispatch({
      selectedNote: this.selectedNote,
      previousNote: this.prevouslySelected
    })

    return true
  }

  selectNextSibling() {
    if (this.selectedNote !== undefined) {
      const parent = this.selectedNote.parent
      const idx = parent.indexOf(this.selectedNote)

      if (idx < parent.children.length - 1) {
        return this.select(parent.children[idx + 1])
      }
    }
    return false
  }

  selectPreviousSibling() {

    if (this.selectedNote !== undefined) {
      const parent = this.selectedNote.parent
      const idx = parent.indexOf(this.selectedNote)

      if (idx > 0) {
        return this.select(parent.children[idx - 1])
      }
    }
    return false
  }

  selectChild() {
    if (this.selectedNote !== undefined && this.selectedNote.hasChildren) {
      if (this.prevouslySelected && this.prevouslySelected.parent === this.selectedNote) {
        return this.select(this.prevouslySelected)
      }
      else {
        return this.select(this.selectedNote.children[0])
      }

    }
    return false
  }

  selectParent() {
    if (this.selectedNote !== undefined) {
      const parent = this.selectedNote.parent

      if (parent && parent !== this.rootNote) {
        return this.select(parent)
      }
    }
    return false
  }

  selectNearest(note: Note = this.selectedNote) {
    const index = note.parent.indexOf(note)
    // First try previous sibling
    if (index > 0) {
      return this.select(note.parent.children[index - 1])
    }
    // Second try next sibling
    else if (index < note.parent.children.length && note.parent.children.length > 1) {
      return this.select(note.parent.children[index + 1])
    }
    // Finally select the parent
    else {
      return this.select(note.parent)
    }
  }

  forceSelectionChain(rootNote: Note, selectedNote: Note) {
    this.rootNote = rootNote
    this.prevouslySelected = this.selectedNote
    this.selectedNote = selectedNote

    Events.service.onSelectionChanged.dispatch({
      selectedNote: this.selectedNote,
      previousNote: this.prevouslySelected
    })
  }

  hoistSelected() {
    if (this.selectedNote.hasChildren) {
      this.rootNote = this.selectedNote
      return this.select(this.rootNote.children[0])
    }
    return false
  }

  unhoist() {
    if (this.rootNote !== NoteManager.service.rootNote) {
      const prevHoisted = this.rootNote
      this.rootNote = NoteManager.service.rootNote
      return this.select(prevHoisted)
    }
    return false
  }

  enterEditMode() {
    this.inEditMode = true
  }

  exitEditMode() {
    this.inEditMode = false
  }

  isNoteSelected = createTransformer((note: Note): boolean => {
    return this.selectedNote === note
  })

  isNoteParentOfSelected = createTransformer((note: Note) => {
    return !this.isNoteSelected(note) && this.selectionChain.indexOf(note) >= 0
  })

  private async initialize() {
    Events.service.onNotesLoaded.on(e => {
      this.rootNote = e.data.root
      this.selectedNote = e.data.root.children[0]
    })
    return this
  }

  // Static methods...

  static get service() { return this._instance || (this._instance = new this()) }
  private static _instance: SelectionManager
}

decorate(SelectionManager, {
  disabledCount: observable,
  inEditMode: observable,
  rootNote: observable,
  selectedNote: observable,
  prevouslySelected: observable,

  isEnabled: computed,
  isDisabled: computed,
  selectedParentIds: computed,
  selectionChain: computed,
  selectionChainLength: computed,
  hasSelection: computed,
  isHoisted: computed,
  canHoist: computed,

  setEnabled: action,
  select: action,
  forceSelectionChain: action,
  hoistSelected: action,
  unhoist: action,
  enterEditMode: action,
  exitEditMode: action,
})

export default SelectionManager