import { action, computed, decorate, observable } from 'mobx';
import debounce from '../utils/debounce';
import Session from '../utils/Session';
import Snapshot, { INoteSnapshot } from '../utils/Snapshot';
import Events from './EventManager';
import NoteManager from './NoteManager';

export interface IBackupDocument {
  rootNote: INoteSnapshot
}

interface Change {
  id: string,
  change: 'add' | 'update' | 'delete'
}

export class SyncManager {
  whenReady: Promise<SyncManager>

  // noteChanges = new Set<string>()
  noteChanges = new Map<string, Change>()

  // syncToken!: string
  // lastSync!: number
  // isSyncing = false
  // changesPending = false

  private session = new Session('app')

  // get hasValidSyncToken() {
  //   return false
  // }

  constructor() {
    this.whenReady = this.initialize()
  }

  // markPending() {
  //   if (!this.changesPending) {
  //     this.changesPending = true
  //   }
  // }

  private async initialize() {
    Events.instance.onNoteCreate.on(e => {
      const { id } = e.data.note
      console.info("Note: Add", id)
      this.noteChanges.set(id, { id, change: 'add' })
      this.saveChanges()
    })
    Events.instance.onNoteUpdate.on(e => {
      const { id } = e.data.note
      console.info("Note: Update", id)
      this.noteChanges.set(id, { id, change: 'update' })
      this.saveChanges()
    })
    Events.instance.onNoteDelete.on(e => {
      const { id } = e.data.note
      console.info("Note: Delete", id)
      this.noteChanges.set(id, { id, change: 'delete' })
      this.saveChanges()
    })
    Events.instance.onNoteRestored.on(e => {
      const { id } = e.data.note
      console.info("Note: Restored", id)
      this.noteChanges.set(id, { id, change: 'add' })
      this.saveChanges()
    })
    Events.instance.onTrashEmptied.on(e => {
      console.info("Trash: Emptied")
      this.saveChanges()
    })

    return this
  }

  private saveChanges = debounce(() => {
    const notesToSave = Array.from(this.noteChanges.values())
    this.noteChanges.clear()

    console.info("• SAVING!", notesToSave)
    notesToSave
      .map(({ id, change }) => ({ change, note: NoteManager.service.getById(id, true) }))
      .forEach(({ note, change }) => {
        console.log(" -", note ? note.title : '???', " / ", change)
        if (note) console.log("   ->", note.toValue())
      })

    const json = NoteManager.service.rootNote.toJson()
    this.session.set('db', json)

    const trashJson = NoteManager.service.trashBin.toJson()
    this.session.set('trashbin', trashJson)
    // this.markPending()
  }, { defer: 250 })

  //

  static get service() { return this._instance || (this._instance = new this()) }
  private static _instance: SyncManager
}

decorate(SyncManager, {
  // syncToken: observable,
  // lastSync: observable,
  // isSyncing: observable,
  // changesPending: observable,

  // hasValidSyncToken: computed,

  // markPending: action,
})

export default SyncManager




// const KEY_SYNC_KEY = 'syncToken'

// export const notesDb = localForage.createInstance({
//   driver: localForage.INDEXEDDB,
//   description: "Notes database",
//   name: "notetree.db",
//   storeName: 'notes'
// })

// export const draftsDb = localForage.createInstance({
//   driver: localForage.INDEXEDDB,
//   description: "Notes database",
//   name: "notetree.db",
//   storeName: 'drafts'
// })

//   ; (window as any)['notesDb'] = notesDb
//   ; (window as any)['draftsDb'] = draftsDb

/* To get the root node, look for the note with no parentId: */
// const rootNote = notesDb.keys()
//   .then(keys => Promise.all(keys.map(k => notesDb.getItem<INoteData>(k))))
//   .then(notes => notes.find(n => !n.parentId))



    // const rootNoteId = await notesDb.getItem<string>("rootNoteId")
    // if (rootNoteId) {
    //   console.log("> Load notes from root:", rootNoteId)
    // }
    // else {
    // await notesDb.setItem("rootNoteId", NoteManager.service.rootNote.id)
    // const saveNote = async (note: Note) => {
    //   console.log("Saving to localForage:", note.id)
    //   await notesDb.setItem(note.id, note.toJson(false))
    //   return Promise.all(note.children.map(saveNote))
    // }
    // saveNote(NoteManager.service.rootNote)
    // }
