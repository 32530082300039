import Snapshot, { INoteSnapshot } from '../../utils/Snapshot'
import { Note, NEW_NOTE_CONTENT } from '../../services/NoteManager'
import App from '../../services/Application'
import { generateUuid } from '../../utils/Uuid'
import { Command } from '../../services/UndoManager';

// export function createNote2(parent: Note, content = NEW_NOTE_CONTENT, index = -1): Note {
//   let note: Note
//   App.service.undoMgr.autoTrackChanges(() => {
//     note = parent.addChildNote(content, index)
//   })
//   return note!
// }

export function createNote(parent: Note, content: string = NEW_NOTE_CONTENT, index: number = -1): Note {
  let newNoteId = generateUuid()

  return App.instance.undoMgr.executeCommand({
    label: 'Create Note',

    context: {
      parentNodeId: parent.id,
      newNoteId: newNoteId as any,
      snapshot: {} as INoteSnapshot,
      noteId: newNoteId as any,
      // prevSelection: App.instance.selectionMgr.selectedNote, // Selection.service.selectedNote,
      isNewNote: true
    },

    execute(ctx) {
      // console.info("> createNote id:", newNoteId, 'content:', content, 'context:', ctx)
      const note = App.instance.noteMgr.getByIdOrThrow(ctx.parentNodeId).addChildNote(content, index, newNoteId)
      // ctx.note = note
      ctx.newNoteId = note.id
      ctx.noteId = note.id
      ctx.snapshot = Snapshot.serializeNote(note)

      App.instance.events.onNoteCreate.dispatch({ note })

      // App.instance.selectionMgr.select(ctx.note)

      return note
    },

    revert(ctx) {
      let note = App.instance.noteMgr.getByIdOrThrow(ctx.noteId)
      App.instance.selectionMgr.selectNearest(note)

      note.parent.removeChildNote(note)
      App.instance.events.onNoteDelete.dispatch({ note, snapshot: ctx.snapshot })
    }
  })
}




type CreateNoteCommandContext = {
  parentNodeId: string | number
  newNoteId: string | number
  snapshot: INoteSnapshot
  noteId: string | number
  isNewNote: boolean
  index: number
  content: string
}
class CreateNoteCommand implements Command<CreateNoteCommandContext> {
  context: CreateNoteCommandContext

  constructor(context: CreateNoteCommandContext) {
    this.context = context
  }

  execute(ctx: CreateNoteCommandContext) {
    // console.info("> createNote id:", newNoteId, 'content:', content, 'context:', ctx)
    const note = App.instance.noteMgr.getByIdOrThrow(ctx.parentNodeId).addChildNote(ctx.content, ctx.index, ctx.newNoteId as string)
    // ctx.note = note
    ctx.newNoteId = note.id
    ctx.noteId = note.id
    ctx.snapshot = Snapshot.serializeNote(note)

    App.instance.events.onNoteCreate.dispatch({ note })

    // App.instance.selectionMgr.select(ctx.note)

    return note
  }

  revert(ctx: CreateNoteCommandContext) {
    let note = App.instance.noteMgr.getByIdOrThrow(ctx.noteId)
    App.instance.selectionMgr.selectNearest(note)

    note.parent.removeChildNote(note)
    App.instance.events.onNoteDelete.dispatch({ note, snapshot: ctx.snapshot })
  }
}



// function createNote3(parent: Note, content: string = NEW_NOTE_CONTENT, index: number = -1): Note {
//   let newNoteId = generateUuid()

//   return App.instance.undoMgr.executeCommand(new CreateNoteCommand({
//     parentNodeId: parent.id,
//     newNoteId: newNoteId as any,
//     snapshot: {} as INoteSnapshot,
//     noteId: newNoteId as any,
//     // prevSelection: App.instance.selectionMgr.selectedNote, // Selection.service.selectedNote,
//     isNewNote: true
//   }))
// }