import { Button, Dialog, IconClasses, Intent } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import Application from '../services/Application';
import { style } from '../utils/Stylesheet';
import { Row, Spacer } from './Grid';

class ConfirmationDialog extends React.Component<{}, {}>{

  private get manager() {
    return Application.service.confirmation
  }

  render() {
    return (
      <Dialog enforceFocus autoFocus lazy
        className={Styles.ConfirmationDialog}
        isOpen={this.manager.isVisible}
        iconName={this.manager.options.iconName as any}
        title={this.manager.options.title}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
      >
        {this.manager.isVisible && <ConfirmationPanel />}
      </Dialog>
    )
  }
}

const ConfirmationPanel = observer(class ConfirmationPanelUI extends React.Component<{}, {}> {
  private get manager() {
    return Application.service.confirmation
  }
  _noBtn: any
  assign = {
    _noBtn: (c: any) => this._noBtn = c
  }

  render() {
    return (
      <div className={Styles.ConfirmationPanel}>

        {this.manager.options.body}

        <Row className={Styles.Actions}>
          <Button intent={Intent.NONE} text={this.manager.options.noLabel} onClick={this.handleNoClick} elementRef={this.assign._noBtn} />
          <Spacer />
          <Button intent={Intent.PRIMARY} iconName={IconClasses.TICK} text={this.manager.options.yesLabel} onClick={this.handleYesClick} />
        </Row>
      </div>
    )
  }

  componentDidMount() {
    setTimeout(() => {
      this._noBtn.focus()
    }, 300)
  }

  handleYesClick = () => {
    this.manager.hide(true)
  }

  handleNoClick = () => {
    this.manager.hide(false)
  }

})



export default observer(ConfirmationDialog)


const Styles = {
  ConfirmationDialog: style({
    width: '30em',
    padding: 0,

    '.pt-dialog-header': {
      marginBottom: 1
    }
  }),

  ConfirmationPanel: style({
    padding: '1em'
  }),

  Actions: style({
    paddingTop: '1em'
  }),
}