import { IToastOptions, IToastProps, Position, Toaster } from "@blueprintjs/core";

export class NotificationManager {
  toaster = Toaster.create({
    className: "my-toaster",
    position: Position.TOP,
    canEscapeKeyClear: true
  })

  clear(): void {
    this.toaster.clear()
  }

  dismiss(key: string) {
    this.toaster.dismiss(key);
  }

  getToasts(): IToastOptions[] {
    return this.toaster.getToasts()
  }

  show(props: IToastProps): string {
    return this.toaster.show(props)
  }

  update(key: string, props: IToastProps): void {
    this.toaster.update(key, props)
  }

  static show(props: IToastProps): string {
    return this.service.show(props)
  }

  // Singleton

  static get service() { return this._instance || (this._instance = new this()) }
  private static _instance: NotificationManager
}

export default NotificationManager
