import { decorate, observable, runInAction } from 'mobx';
import * as actions from '../actions';
import Clipboard from '../utils/Clipboard';
import { Session } from '../utils/Session';
import BackupManager from './BackupManager';
import Confirmation from './ConfirmationManager';
import DraftManager from './DraftManager';
import EventManager from './EventManager';
import ExportManager from './ExportManager';
import Hotkeys from './Hotkeys';
import NoteManager from './NoteManager';
import Notifications from './Notifications';
import PreviewManager from './PreviewManager';
import SelectionManager from './SelectionManager';
import SettingsManager from './SettingsManager';
import SyncManager from './SyncManager';
import TrashBinManager from './TrashBinManager';
import TreeViewManager from './TreeViewManager';
import UI from './UIManager';
import Undo from './UndoManager';

//@ts-ignore
var meta: { version: string } = require('../meta.json')


export class Application {

  isReady = false
  meta = meta
  actions = actions

  backup = BackupManager.service
  clipboard = Clipboard
  drafts = DraftManager.service
  notifications = Notifications.service
  session = new Session('app')
  events = EventManager.service
  noteMgr = NoteManager.service
  selectionMgr = SelectionManager.service
  syncMgr = SyncManager.service
  previewMgr = PreviewManager.service
  UI = UI.service
  undoMgr = Undo.service
  hotkeys = Hotkeys.service
  settings = SettingsManager.service
  trashbin = TrashBinManager.service
  exportMgr = ExportManager.service
  treeView = TreeViewManager.service
  confirmation = Confirmation.service

  commands = NoteManager.service.commands

  private whenReady!: Promise<Application>

  start() {
    if (this.whenReady) {
      return this.whenReady
    }
    const startTime = Date.now()

    return this.whenReady = Promise
      .resolve(this)
      .then(() => {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.addEventListener('message', (e) => {
            console.log("?>>>> WORKER SAYS!", e.data, 'event:', e)
          })
          navigator.serviceWorker.onmessage = e => {
            console.log(">>>> WORKER SAYS!", e.data, 'event:', e)
          }
        }
      })
      .then(() => Promise.all<any>([
        this.UI.whenReady, this.events.whenReady, this.noteMgr.whenReady,
        this.selectionMgr.whenReady, this.syncMgr.whenReady, this.undoMgr.whenReady,
        this.previewMgr.whenReady, this.hotkeys.whenReady, this.selectionMgr.whenReady,
        this.exportMgr.whenReady, this.treeView.whenReady, this.confirmation.whenReady,
        this.trashbin.whenReady, this.backup.whenReady, this.drafts.whenReady
      ]))
      .then(services => {
        let Services: any = (window as any)['Services'] = {
          Application: this
        }
        services.forEach((srv) => {
          Services[srv.constructor.name] = srv
        })
        return services
      })
      .then(() => {
        runInAction(() => {
          this.isReady = true
          this.events.onStart.dispatch(this)
          const endTime = Date.now()
          console.log('Start up completed in:', (endTime - startTime), 'milliseconds.')
        })
        return this
      })
  }

  static start() { return this.instance.start() }

  // SINGLETON: 

  static get instance(): Application { return this.service }
  static get service() { return this._instance || (this._instance = new this()) }
  private static _instance: Application

  static get actions() {
    return this.service.actions
  }
  static get commands() {
    return this.service.commands
  }
  static get events() {
    return this.service.events
  }
}

decorate(Application, {
  isReady: observable
})

export default Application