import { toJS } from 'mobx';
import App from '../../services/Application';
import { createMarkdownWithFM, Note } from '../../services/NoteManager';
import * as command from './index';

export function mergeNote(note: Note, direction: 'up' | 'down'): Note {
  return App.service.undoMgr.inTransaction(() => {
    if (direction == 'up')
      return mergeUp(note)
    else
      return mergeDown(note)
  })
}


function mergeUp(note: Note) {
  if (!note.hasParent || note.position <= 0) {
    console.warn("Cannot merge top note UP.")
    return note
  }

  const peer = note.parent.children[note.position - 1]

  if (!peer) {
    console.warn("Peer not not found!")
    return note
  }

  const mergedMetadata = Object.assign({}, toJS(peer.metadata), toJS(note.metadata))
  const newContent = `${peer.contentMarkdown.trim()}\n\n---\n\n${note.contentMarkdown.trim()}`

  if (peer.hasChildren) {
    peer.children.forEach(child => {
      command.updateNoteParent(child, note, "top")
    })
  }

  command.updateNoteContent(note, createMarkdownWithFM(newContent, mergedMetadata))

  command.deleteNotePermanently(peer, { updateSelection: false })

  return note
}

function mergeDown(note: Note) {
  if (!note.hasParent || note.position >= note.parent.children.length - 1) {
    console.warn("Cannot merge bottom note DOWN.")
    return note
  }

  const peer = note.parent.children[note.position + 1]

  if (!peer) {
    console.warn("Peer not not found!")
    return note
  }

  const mergedMetadata = Object.assign({}, toJS(note.metadata), toJS(peer.metadata))
  const newContent = `${note.contentMarkdown.trim()}\n\n---\n\n${peer.contentMarkdown.trim()}`

  if (peer.hasChildren) {
    peer.children.forEach(child => {
      command.updateNoteParent(child, note, "bottom")
    })
  }

  command.updateNoteContent(note, createMarkdownWithFM(newContent, mergedMetadata))

  command.deleteNotePermanently(peer)

  return note
}