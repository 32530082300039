interface Options {
  defer: number
  immediate: boolean
}

type Proc<A> = (args: A) => void

export function debounce<T = () => void>(fn: T, options: Partial<Options> = { defer: 100, immediate: false }): T {
  let timeout: number | null;

  return function wrappedFunction() {
    //@ts-ignore
    const context: any = this;
    const args = arguments;

    const later = () => {
      timeout = null;
      if (options.immediate !== true) (fn as any).apply(context, args);
    };

    var callNow = options.immediate == true && !timeout;

    if (timeout != null) clearTimeout(timeout);

    timeout = setTimeout(later, options.defer);

    if (callNow) (fn as any).apply(context, args);
  } as any;
};

export default debounce