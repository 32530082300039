import { Classes, IMenuItemProps, Text } from '@blueprintjs/core'; //CollapsibleList, CollapseFrom, IconClasses, MenuItem
import { action, computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import Application from '../services/Application';
import { Note } from '../services/NoteManager';
import { classNames, style } from '../utils/Stylesheet';
import { Row, Spacer } from './Grid';

interface BreadcrumbsState {
}

interface BreadcrumbsProps { }

class Breadcrumbs extends React.Component<BreadcrumbsProps, BreadcrumbsState> {
  state = {}

  get selection() { return Application.service.selectionMgr }
  get notes() { return Application.service.noteMgr }

  fullWidthFor = -1

  setFullWidthFor(i: number) {
    this.fullWidthFor = i
  }

  get breadCrumbItems() {
    const first = this.selection.isHoisted ? -1 : 0
    const last = this.selection.selectionChain.length - 1
    return this.selection.selectionChain.filter((note, i) => i != first && i != last)
    // return this.selection.selectionChain
  }

  get lastItemIndex() {
    return this.breadCrumbItems.length - 1
  }

  get breadCrumbItemsOLD() {
    const chain: Note[] = []
    let note = this.selection.selectedNote.parent
    while (note) {
      chain.unshift(note)
      note = note.parent
    }
    return chain
  }

  get visibleItemsCount() {
    return this.selection.selectionChainLength - 1
  }

  render() {
    return (
      <div className={Styles.Breadcrumbs}>
        <Row>
          {this.breadCrumbItems.map(this.renderBreadCrumb)}
          <Spacer />
          <Text className={Styles.Crumb}>
            <span className={Styles.CrumbContent}>
              <span
                title="Send Feedback..."
                className={classNames(Styles.Feedback, "pt-icon-standard pt-icon-chat")}
                onClick={this.handleSendFeedback}
              />
            </span>
          </Text>
        </Row>
        {/*<CollapsibleList
          className={Classes.BREADCRUMBS}
          collapseFrom={CollapseFrom.START}
          visibleItemCount={this.visibleItemsCount}
          dropdownTarget={<span className={Classes.BREADCRUMBS_COLLAPSED} />}
          renderVisibleItem={this.renderBreadCrumbs}
        >
          {this.breadCrumbItems.map((note, i) => (
            <MenuItem
              key={i}
              href={note.id}
              iconName={IconClasses.DOCUMENT}
              text={note.title} disabled
            />
          ))}
        </CollapsibleList>*/}
      </div>
    )
  }

  renderBreadCrumb = (note: Note, i: number) => {
    return (
      <Text key={i} className={classNames('Crumb', Styles.Crumb)} ellipsize={this.fullWidthFor !== i && this.lastItemIndex !== i} >
        <span
          className={classNames(Styles.CrumbContent, { isActive: i === this.lastItemIndex })}
          onMouseEnter={() => this.setFullWidthFor(i)}
          onMouseLeave={() => this.setFullWidthFor(-1)}
          onClick={this.handleClick(note)}
        >
          {i !== 0 && <span className={classNames(Styles.CrumbDivider, "pt-icon-standard pt-icon-chevron-right")} />}
          {note.title}
        </span>
      </Text>
    )
  }

  // renderBreadCrumbs = (props: IMenuItemProps) => {
  //   if (props.href != null) {
  //     return <a onClick={this.handleClick(props.href)} className={classNames(Styles.Crumb, Classes.BREADCRUMB)}>{props.text}</a>;
  //   } else {
  //     return <span className={classNames(Styles.Crumb, Classes.BREADCRUMB)}>{props.text}</span>;
  //   }
  // }

  handleClick = (note: Note) => () => {
    if (note !== this.selection.rootNote) {
      this.selection.select(note)
    }
    else {
      this.selection.unhoist()
    }
  }

  handleSendFeedback = (e: any) => {
    e.preventDefault()
    const at = '@'
    const url = 'mail' + 'to:' + 'matt' + at + 'elucidata.net?subject=NoteTree Feedback'

    location.href = url

    // const win = window.open(url, 'email-window')
    // setTimeout(() => {
    //   if (win && win.open && !win.closed)
    //     win.close();
    // }, 250)
  }

}

decorate(Breadcrumbs, {
  fullWidthFor: observable,

  breadCrumbItems: computed,
  lastItemIndex: computed,
  breadCrumbItemsOLD: computed,
  visibleItemsCount: computed,

  setFullWidthFor: action,
})


export default observer(Breadcrumbs)
//

const Styles = {

  Breadcrumbs: style({
    // background: 'rgba(0,0,0, 0.05)',
    // borderBottom: '1px dotted var(--border-color)',
    // borderTop: '1px dotted var(--border-color)',
    color: 'var(--footer-fg-color)',
    // paddingLeft: '1em',
    fontSize: '90%',

    // '.Crumb + .Crumb': {
    //   borderLeft: '1px dotted silver',
    // }

    // background: 'linear-gradient(to bottom, var(--border-color), var(--bg-color) 50% )',
    backgroundColor: 'var(--footer-color)',
  }),

  Crumb: style({
    // maxWidth: '20vw',
    // overflow: 'ellipsis',
    marginLeft: 6,
  }),

  CrumbContent: style({
    display: 'inline-block',
    textOverflow: 'ellipsis',
    padding: '6px 6px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    opacity: 0.7,
    // color: 'var(--card-fg-color)',

    '&.isActive': {
      // color: 'black'
      // color: 'var(--card-fg-color)',
      opacity: 1,
    },

    '&:hover': {
      // backgroundColor: 'gainsboro',
      // textDecoration: 'underline',
      color: 'var(--highlight-color)', //'dodgerblue',
      zIndex: 5
    },
  }),

  CrumbDivider: style({
    display: 'inline-block',
    marginRight: '6px',
    marginLeft: '-12px',
    // color: 'silver !important',
    // color: 'var(--card-fg-color)',
    opacity: 0.3,
  }),

  Feedback: style({
    opacity: 0.5,
    cursor: 'pointer',

    '&:hover': {
      opacity: 1,
    }
  }),

}



// function List(props: { ItemComponent?: (props: ItemProps) => JSX.Element }) {
//   const {
//     ItemComponent = DefaultItem
//   } = props
//   const items = [
//     ["0", "Zero"],
//     ["1", "One"],
//     ["2", "Two"],
//     ["3", "Three"],
//   ]
//   return (
//     <div>
//       {items.map((pair, i) => (
//         <ItemComponent key={i} label={pair[1]} value={pair[0]} isSelected={false} />
//       ))}
//     </div>
//   )
// }

// interface ItemProps {
//   label: string
//   value: any
//   isSelected: boolean
// }

// function DefaultItem(props: ItemProps) {
//   return (
//     <div className={'Item ' + props.isSelected ? 'is-selected' : ''}>
//       {props.label} ({props.value})
//     </div>
//   )
// }



// const CustomItem = (props: ItemProps) => (
//   <div className={'Item ' + props.isSelected ? 'is-selected' : ''}>
//     Custom Item = {props.label} value = {props.value}.
//   </div>
// )

// const x = (
//   <List ItemComponent={CustomItem} />
// )

// const y = (
//   <List />
// )
