import { FocusStyleManager, Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Application from '../services/Application';
import HotkeysMgr from '../services/Hotkeys';
import { Note } from '../services/NoteManager';
import Selection from '../services/SelectionManager';
import UI from '../services/UIManager';
import debounce from '../utils/debounce';
import { style } from '../utils/Stylesheet';
import AppHeader from './AppHeader';
import AuthDialog from './AuthDialog';
import Breadcrumbs from './Breadcrumbs';
import CardList, { COLUMN_WIDTH } from './CardList';
import ConfirmationDialog from './ConfirmationDialog';
import ExportDialog from './ExportDialog';
import NoteTree from './NoteTree';
import PreviewDialog from './PreviewDialog';
import SettingsDialog from './SettingsDialog/SettingsDialog';
import TrashBinDialog from './TrashBinDialog';

interface AppState {
  viewport: ClientRect | undefined
}

interface AppProps {
  // app: Application
}


class App extends React.Component<AppProps, AppState> {
  state = {
    viewport: undefined
  }
  assign = {
    _mainContent: (c: HTMLDivElement) => this._mainContent = c,
    _UI_ScrollTarget: (c: HTMLDivElement) => UI.service.assignScrollTarget(c)
  }
  _mainContent!: HTMLDivElement

  get isHoisted() {
    return Selection.service.isHoisted
  }

  render() {
    const spacerColumnWidth = UI.service.spacerColumnWidth
    return (
      <div className={Styles.App}>
        <AppHeader layout="vertical" />
        <PreviewDialog />
        <ExportDialog />
        <SettingsDialog />
        <ConfirmationDialog />
        <AuthDialog />
        <TrashBinDialog />
        {Application.service.treeView.isVisible &&
          <NoteTree />
        }
        <div className={Styles.MainContent}>
          {/* <Breadcrumbs /> */}
          <div className={Styles.ColumnList} ref={this.assign._mainContent}>
            {this.renderColumns()}
            <div style={{
              display: 'block',
              width: spacerColumnWidth,
              maxWidth: spacerColumnWidth,
              minWidth: spacerColumnWidth
            }} />
            <div ref={this.assign._UI_ScrollTarget} />
          </div>
          <Breadcrumbs />
        </div>
      </div>
    )//className="Column"
  }

  renderColumns() {
    if (this.state.viewport !== undefined) {
      return Selection.service.selectionChain.map((note: Note, idx: number) => (
        <CardList
          key={idx}
          note={note}
          viewport={this.state.viewport as any}
        />
      ))
    }
    else {
      return null
    }
  }

  renderHotkeys() {
    return (
      <Hotkeys>
        {HotkeysMgr.service.globalHotKeyProps.map((props, i) => (
          <Hotkey key={i} global={true} {...props} />
        ))}
      </Hotkeys>
    )
  }

  _disposables: any[] = []
  componentDidMount() {
    FocusStyleManager.onlyShowFocusOnTabs();

    this._disposables.push(autorun(() => {
      this.calculateViewPort(UI.service.viewportWidth)
    }))
  }
  componentWillUnmount() {
    this._disposables.forEach(dispose => dispose())
  }

  private calculateViewPort = debounce((uiWidth: number) => {
    console.log("Calculating column width", uiWidth)
    const dom = ReactDOM.findDOMNode(this._mainContent) as HTMLDivElement
    if (dom != null) this.setState(state => ({
      viewport: dom.getBoundingClientRect()
    }))
  })
}

HotkeysTarget(App)

export default observer(App)

//

const Styles = {
  App: style({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    // flexDirection: 'column',
  }, 'App'),

  MainContent: style({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
  }, 'MainContent'),

  HorizontalSpacer: style({
    position: 'absolute',
    display: 'block',
    top: 10,
    left: 0,
    height: 5,
    // backgroundColor: 'transparent'
    backgroundColor: 'orange',
    transition: 'width 1s ease-out',
    opacity: 0.2,
    borderLeft: '10px solid purple'
  }, 'HorizontalSpacer'),

  ColumnMeasure: style({
    width: COLUMN_WIDTH,
    position: 'absolute',
    height: 25,
    backgroundColor: 'red',
    top: 50,
    left: 0,
    opacity: 0.2,
  }, 'ColumnMeasure'),

  ColumnList: style({
    position: 'relative',
    display: 'flex',
    flex: 1,
    overflowX: 'auto',
  }, 'ColumnList'),

  NothingToSeeHere: style({
    padding: '2em',
    opacity: '0.5',
  }, 'NothingToSeeHere'),

}
