let _lastId = 0

export function generateUuid(radix: number = 36) {
  let nextId = Date.now()

  while (nextId <= _lastId) {
    nextId++
  }

  _lastId = nextId

  return nextId.toString(radix)
}

export default {
  generate: generateUuid
}
