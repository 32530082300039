import { Button, IconClasses, Intent, Menu, MenuDivider, MenuItem, Popover, Position } from '@blueprintjs/core';
import { action, computed, decorate } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import * as actions from '../actions';
import * as commands from '../commands';
import Application from '../services/Application';
import { NEW_NOTE_CONTENT, Note } from '../services/NoteManager';
import SelectionManager from '../services/SelectionManager';
import { autoDisposer, setDisposableTimeout } from '../utils/AutoDisposer';
import debounce from '../utils/debounce';
import { cancelEvent } from '../utils/FunctionTools';
import { classNames, style } from '../utils/Stylesheet';
import { insertHeaderText, toggleTextMarks } from '../utils/Textarea';
import CodemirrorEditor, { EditorCommands } from './CodemirrorEditor';
import EditableText from './EditableText';

interface CardProps extends React.Props<{}> {
  viewport?: ClientRect
  note: Note
  requestScrollIntoView: (node: HTMLElement, alignTop: boolean, alignLeft: boolean) => void
}

interface CardState {
  renderContent: boolean,
  toolsVisible: boolean
}

// @ContextMenuTarget 
class Card extends React.Component<CardProps, CardState> {
  _root: HTMLElement | null = null
  autoDispose = autoDisposer(this)

  state = {
    renderContent: false,
    toolsVisible: false
  }
  tetherOptions = {
    constraints: [{ attachment: "together", to: "scrollParent" }]
  }

  render() {
    const { note } = this.props
    const isVisible = this.noteIsInteractive || this.state.renderContent
    const isSelected = SelectionManager.service.isNoteSelected(note) //.selectedNote === note
    const isSelectedParent = SelectionManager.service.isNoteParentOfSelected(note) //.selectedParentIds.indexOf(note.id) > -1
    const containerClassName = classNames('Card', {
      isSelected,
      isSelectedParent,
      isVisible,
      hasDraft: this.hasDraft
    }, Styles.Card)
    const cardClassName = classNames('pt-card', {
      'pt-elevation-1': !isSelected,
      'pt-elevation-2': !isSelected && isSelectedParent,
      'pt-elevation-3': isSelected
    })

    const icon = isSelected && SelectionManager.service.inEditMode
      ? IconClasses.EDIT
      : this.hasDraft
        ? IconClasses.DOCUMENT_OPEN
        : note.isDataset
          ? IconClasses.TH
          : IconClasses.DOCUMENT

    // const icon = IconClasses.DOCUMENT

    // const icon = note.hasChildren
    //   ? IconClasses.DUPLICATE
    //   : IconClasses.DOCUMENT

    return (
      <div data-note-id={'n' + note.id} className={containerClassName} onClick={this.handleSelect} ref={c => this._root = c} onDoubleClick={this.handleEdit}>
        {note.hasChildren &&
          <div className={Styles.HasChildNotes}>
            <span className="ChildIndicator pt-icon-standard pt-icon-chevron-rightX pt-icon-caret-rightX pt-icon-arrow-right pt-icon-circle-arrow-rightX" />
          </div>
        }
        <div className={cardClassName}>
          <div className={Styles.CardInner}>
            <div className={Styles.CardTools}>
              {!this.noteIsInteractive &&
                <div data-tooltip="Tools" data-tooltip-pos="down">
                  <Popover useSmartArrowPositioning autoFocus isModal lazy
                    popoverClassName="pt-minimal"
                    isOpen={this.state.toolsVisible} onClose={this.handleHideTools}
                    content={this.renderContextMenu()}
                    position={Position.TOP_LEFT} tetherOptions={this.tetherOptions}
                    popoverWillClose={this.handlePopoverWillClose}
                    popoverDidOpen={this.handlePopoverDidOpen}
                  >
                    <Button className="pt-minimal" iconName={icon} onClick={this.handleShowTools} intent={this.hasDraft ? Intent.DANGER : Intent.NONE} />
                  </Popover>
                </div>
              }
              {this.noteIsInteractive &&
                <div data-tooltip="Save" data-tooltip-pos="down">
                  <Button className="pt-minimal" iconName="floppy-disk" intent={this.hasDraft ? Intent.DANGER : Intent.NONE} />
                </div>
              }
              {/* <small className="pt-text-muted">{note.wordCount}</small> */}
              {/*<span className="pt-icon-large pt-icon-document" />*/}
              {/* {this.hasDraft &&
                <Icon iconName="asterisk" intent={Intent.WARNING} />
              } */}
            </div>
            <div className={Styles.CardContent}>
              {this.renderContent(this.props.note)}
            </div>
          </div>
          {/*<div className="thin-text">({note.children.length} / {note.fullCount})</div>*/}
        </div>
        <div className={classNames(Styles.AddSiblingAction, 'NoteBtn')}>
          <span className="pt-icon-standard pt-icon-small-plus" onClick={this.handleAddSiblingNote} title="Insert new note here" />
        </div>
        {!note.hasChildren &&
          <div className={classNames(Styles.AddChildAction, 'NoteBtn')}>
            <span className="pt-icon-standard pt-icon-small-plus" onClick={this.handleAddChildNote} title="Insert new nested note" />
          </div>
        }
      </div>
    )
    /*{
    note.hasChildren && !note.hasDescendants &&
      <div className={Styles.HasChildNotes}>
        <span className="ChildIndicator pt-icon-large pt-icon-chevron-right" />
      </div>
    }
    {
    note.hasDescendants &&
      <div className={Styles.HasChildNotes}>
        <span className="ChildIndicator pt-icon-standard pt-icon-double-chevron-right" onClick={this.handleShowTools} />
      </div>
    }*/
  }

  get noteIsInteractive() {
    return SelectionManager.service.isNoteSelected(this.props.note) && SelectionManager.service.inEditMode
  }

  renderContent(note: Note) {
    if (this.noteIsInteractive) {
      const defaultValue = this.hasDraft
        ? this.draft || note.content
        : note.content

      if (Application.service.UI.useCodemirror) {
        return (
          <CodemirrorEditor
            autoFocus
            key={note.id}
            doc={defaultValue}
            onChange={this.handleTextChanges}
            onConfirm={this.handleCommitChanges}
            onCancel={this.handleCancelChanges}
            onCommand={this.handleCommand}
          />
        )
      }
      else {
        return (
          <EditableText
            multiline
            selectAllOnFocus={note.content === NEW_NOTE_CONTENT}
            minLines={2}
            defaultValue={defaultValue}
            isEditing={true}
            onConfirm={this.handleCommitChanges}
            onCancel={this.handleCancelChanges}
            onChange={this.handleTextChanges}
            controlProps={this.controlProps}
          />
        )
      }
    }
    else {
      if (!this.state.renderContent) {
        return (
          <form><div className="CardContent"></div></form>
        )
      }
      if (note.isDataset) {
        return (
          <form>
            {this.renderMetadata(note)}
          </form>
        )
      }
      else {
        return (
          <form>
            {note.metadata.$showMetadata == true &&
              this.renderMetadata(note)
            }
            <div
              onClick={this.handleContentClicks}
              className={classNames("CardContent isVisible", `type-${note.contentType}`)}
              dangerouslySetInnerHTML={{ __html: note.contentHTML }}
            />
          </form>
        )
      }
    }
  }

  renderMetadata(note: Note) {
    return (
      <div className={classNames("CardDataset", `type-dataset`)}>
        <table className={Styles.MetadataGrid}>
          <tbody>
            {Object.keys(note.metadata).map(key => {
              if (key.startsWith("$")) return null // == "$showMetadata"
              return (
                <tr key={key}>
                  <th>{key}</th>
                  <td>{JSON.stringify(note.metadata[key])}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )

    // return (
    //   <pre>{JSON.stringify(note.metadata, null, 2)}</pre>
    // )
  }

  renderContextMenu() {
    return (
      <Menu>
        {this.hasDraft &&
          <MenuItem iconName={IconClasses.CROSS} text="Clear Draft" onClick={this.handleClearDraft} intent={Intent.DANGER} />
        }
        <MenuItem iconName={IconClasses.INFO_SIGN} text={`Word Count: ${this.props.note.wordCount}`} disabled />
        <MenuDivider />
        <MenuItem iconName={IconClasses.EDIT} text="Edit" onClick={this.handleEdit} label="⌘⏎" />
        <MenuItem iconName={IconClasses.EYE_OPEN} text="Preview..." onClick={this.handlePreviewNoteAndChildren}>
          <MenuItem text="Note Only" onClick={this.handlePreviewNote} />
          <MenuItem text="Note & Children" onClick={this.handlePreviewNoteAndChildren} label="⌘⇧P" />
          <MenuItem text="Only Children" onClick={this.handlePreviewChildren} />
          <MenuItem text="Only Leaf Notes" onClick={this.handlePreviewLeaves} />
        </MenuItem>
        <MenuItem disabled={!this.props.note.hasChildren} iconName={IconClasses.DOCUMENT_SHARE} text="Hoist" onClick={this.handleHoist} />
        <MenuDivider />
        <MenuItem text="Reorder" iconName={IconClasses.BLANK}>
          <MenuItem text="Move to First" disabled iconName={IconClasses.CHEVRON_UP} onClick={this.handleMoveUp} label="⇧⌘↑" />
          <MenuItem text="Move Up" iconName={IconClasses.ARROW_UP} onClick={this.handleMoveUp} label="⇧↑" />
          <MenuItem text="Move Down" iconName={IconClasses.ARROW_DOWN} onClick={this.handleMoveDown} label="⇧↓" />
          <MenuItem text="Move to Last" disabled iconName={IconClasses.CHEVRON_DOWN} onClick={this.handleMoveDown} label="⇧⌘↓" />
        </MenuItem>
        {/* <MenuItem text="Icon" iconName={IconClasses.BLANK}>
          <div style={{ width: '30em', maxHeight: '15em', overflowY: 'auto', zoom: 0.9 }}>
            {Object.keys(IconClasses).map((name) => IconClasses[name]).map((icon, i) => (
              <button key={i} className={"pt-button pt-minimal pt-icon-standar " + icon}></button>
            ))}
          </div>
        </MenuItem>*/}
        <MenuItem text="Clipboard" iconName={IconClasses.BLANK}>
          <MenuItem disabled text="Cut" iconName={IconClasses.CUT} label="⌘X" />
          <MenuItem disabled text="Copy" iconName={IconClasses.DUPLICATE} label="⌘C" />
          <MenuItem disabled text="Paste" iconName={IconClasses.CLIPBOARD} label="⌘V" />
          <MenuItem disabled text="Paste as Child" iconName={IconClasses.CLIPBOARD} label="⌘⇧V" className={Styles.ClipboardMenu} />
          <MenuDivider />
          <MenuItem disabled text="Copy as Markdown" iconName={IconClasses.DUPLICATE} />
          <MenuItem disabled text="Copy as HTML" iconName={IconClasses.DUPLICATE} />
        </MenuItem>
        <MenuItem text="Tools" iconName={IconClasses.BLANK}>
          <MenuItem text="Split each bullet..." iconName={IconClasses.CUT} onClick={this.handleBulletSplit} />
          <MenuItem text="Split each hrule..." iconName={IconClasses.CUT} onClick={this.handleHRuleSplit} />
          <MenuDivider />
          <MenuItem iconName={IconClasses.DOUBLE_CHEVRON_UP} text="Merge Up" onClick={this.handleMergeUp} label="⇧⌥↑" />
          <MenuItem iconName={IconClasses.DOUBLE_CHEVRON_DOWN} text="Merge Down" onClick={this.handleMergeDown} label="⇧⌥↓" />
        </MenuItem>
        <MenuDivider />
        <MenuItem iconName={IconClasses.SEARCH_AROUND} text="Dissolve" onClick={this.handleDissolve} label="⇧⌘⌫" />
        <MenuItem iconName={IconClasses.DELETE} text="Delete" onClick={this.handleDelete} label="⌘⌫" />
      </Menu>
    )
  }

  _textarea!: HTMLTextAreaElement

  controlProps = {
    ref: (textarea: any) => this._textarea = textarea,
    onKeyDown: (e: KeyboardEvent) => {
      // console.info("> KeyPress:", keyCode, '/', e.key)
      const { keyCode, shiftKey, altKey, key } = e
      const modKey = e.ctrlKey || e.metaKey

      if (keyCode === 9) { // TAB
        e.preventDefault()
        e.stopPropagation()
        this._textarea.blur()
        return
      }


      if (keyCode === 13 && shiftKey && modKey) { // SHIFT+MOD+ENTER
        const currNote = this.props.note
        const currIdx = currNote.parent.indexOf(currNote)
        const note = commands.createNote(currNote.parent, undefined, currIdx + 1)

        SelectionManager.service.select(note)
        SelectionManager.service.enterEditMode()
      }
      else if (modKey && keyCode === 66) { // B
        toggleTextMarks(this._textarea, "**")
      }
      else if (modKey && keyCode === 73) { // I
        toggleTextMarks(this._textarea, "*")
      }
      else if (modKey && keyCode === 85) { // U
        toggleTextMarks(this._textarea, "_")
      }
      else if (modKey && key == '1') {
        e.preventDefault()
        insertHeaderText(this._textarea, "# ")
      }
      else if (modKey && key == '2') {
        e.preventDefault()
        insertHeaderText(this._textarea, "## ")
      }
      else if (modKey && key == '3') {
        e.preventDefault()
        insertHeaderText(this._textarea, "### ")
      }
      else if (modKey && key == '4') {
        e.preventDefault()
        insertHeaderText(this._textarea, "#### ")
      }
      else if (modKey && key == 'h') {
        e.preventDefault()
        toggleTextMarks(this._textarea, "==")
      }
      else if (modKey && key == 'k') {
        e.preventDefault()
        this.handleSplitAtCursor(this._textarea)
      }
    }
  }

  handleSplitAtCursor(textarea: HTMLTextAreaElement) {
    Application.service.undoMgr.inTransaction(() => {
      const { selectionStart, value } = textarea
      const contentBefore = value.substring(0, selectionStart)
      const contentAfter = value.substring(selectionStart)

      this.handleCommitChanges(contentBefore)
      const newNote = commands.createNote(this.props.note.parent, contentAfter, this.props.note.position + 1)

      SelectionManager.service.select(newNote)
      SelectionManager.service.enterEditMode()
    })
  }

  handleBulletSplit = () => {
    this.afterPopoverCloses(() => {
      actions.splitNote(null, this.props.note, 'bullet')
    })
    // splitNote(this.props.note, 'bullet')
  }

  handleHRuleSplit = () => {
    this.afterPopoverCloses(() => {
      actions.splitNote(null, this.props.note, 'hrule')
    })
    // splitNote(this.props.note, 'hrule')
  }

  handleMergeUp = () => {
    actions.mergeUp(null, this.props.note)
    // mergeNote(this.props.note, 'up')
  }

  handleMergeDown = () => {
    actions.mergeDown(null, this.props.note)
    // mergeNote(this.props.note, 'down')
  }

  handleTextChanges = (value: string) => {
    this._saveDraft(value)
  }

  private _saveDraft = debounce((value: string) => {
    this._setDraft(value)
  })

  get hasDraft() {
    return Application.service.drafts.has(this.props.note.id)
    // return Card.drafts.has(this.props.note.id)
  }

  get draft() {
    return this.hasDraft
      ? Application.service.drafts.get(this.props.note.id)!
      : null
    // return this.hasDraft
    //   ? Card.drafts.get(this.props.note.id)!
    //   : null
  }

  _setDraft(text: string) {
    if (text == this.props.note.content) {
      this._clearDraft()
    }
    else {
      Application.service.drafts.set(this.props.note.id, text)
      // Card.drafts.set(this.props.note.id, text)
    }
  }

  _clearDraft() {
    Application.service.drafts.remove(this.props.note.id)
    // Card.drafts.delete(this.props.note.id)
  }

  handleCommitChanges = (value: string, mergeFrontMatter = false) => {
    commands.updateNoteContent(this.props.note, value, { mergeFrontMatter })
    // actions.updateNoteContent(null, this.props.note, value, { mergeFrontMatter })
    // SelectionManager.service.exitEditMode()
    actions.exitEditMode()
    this._clearDraft()
  }


  handleCancelChanges = () => {
    actions.exitEditMode()
    if (this.hasDraft) {
      if (this.draft == this.props.note.content) {
        this._clearDraft()
      }
    }
    // SelectionManager.service.exitEditMode()
  }

  handleClearDraft = () => {
    this._clearDraft()
  }

  handleCommand = (cmd: EditorCommands, context: any = {}) => {
    const { note } = this.props

    if (cmd == 'add-peer') {
      const value = this.hasDraft
        ? this.draft || note.content
        : note.content

      this.handleCommitChanges(value)
      actions.createSibling(null, note)
    }

    if (cmd == 'add-child') {
      const value = this.hasDraft
        ? this.draft || note.content
        : note.content

      this.handleCommitChanges(value)
      actions.createChild(null, note)
    }

    if (cmd == 'split') {
      SelectionManager.service.exitEditMode()
      this._clearDraft()

      actions.splitNote(null, this.props.note, 'selection', context)
    }
  }

  handleSelect = (e?: any) => {
    actions.select(e, this.props.note)
  }

  handleEdit = (e?: any) => {
    this.afterPopoverCloses(() => {
      actions.enterEditMode(e, this.props.note)
    })
  }

  handleDelete = () => {
    this.afterPopoverCloses(() => {
      actions.deleteNote()
    })
  }

  handleDissolve = () => {
    this.afterPopoverCloses(() => {
      actions.dissolveNote(null, this.props.note)
    })
  }

  handleMoveUp = () => {
    actions.moveUp(null, this.props.note)
  }

  handleMoveDown = () => {
    actions.moveDown(null, this.props.note)
  }

  handleAddSiblingNote = (e: any) => {
    actions.createSibling(e, this.props.note)
  }

  handleAddChildNote = (e: any) => {
    actions.createChild(e, this.props.note)
  }

  handlePopoverWillClose = () => {
    SelectionManager.service.setEnabled(true)
    if (!!this._popoverCloseAction) {
      this._popoverCloseAction()
      this._popoverCloseAction = null
    }
  }

  handlePopoverDidOpen = () => {
    SelectionManager.service.select(this.props.note)
    SelectionManager.service.setEnabled(false)
  }

  handleShowTools = (e: any) => {
    cancelEvent(e)
    this.setState(state => ({
      toolsVisible: true
    }))
  }

  handleHideTools = () => {
    this.setState(state => ({
      toolsVisible: false
    }))
  }

  handleHoist = (e?: any) => {
    this.afterPopoverCloses(() => {
      actions.hoist(e, this.props.note)
    })
  }

  handlePreviewNote = (e?: any) => {
    actions.showPreview(e, this.props.note, 'note')
  }
  handlePreviewNoteAndChildren = (e?: any) => {
    actions.showPreview(e, this.props.note, 'note-children')
    this.handleHideTools()
  }
  handlePreviewChildren = (e?: any) => {
    actions.showPreview(e, this.props.note, 'children')
  }
  handlePreviewLeaves = (e?: any) => {
    actions.showPreview(e, this.props.note, 'leaves')
  }

  handleContentClicks = (e: any) => {
    if (e.target && e.target.nodeName === 'INPUT') {
      const input = e.target
      const checkedState = input.checked
      const inputPosition = findIndexOf(e.target)
      const newContent = toggleTaskMarkdown(this.props.note.content, inputPosition, checkedState)
      // console.log(newContent)
      if (newContent !== this.props.note.content) {
        commands.updateNoteContent(this.props.note, newContent)
      }
      else {
        console.warn("No changes to content!")
      }
    }
  }

  componentDidMount() {
    this.autoDispose(
      setDisposableTimeout(() => {
        this.setState(state => ({ renderContent: true }))
      })
    )
  }

  componentWillUnmount() {
    this.autoDispose.disposeAll()
  }

  private afterPopoverCloses(callback: () => void) {
    this._popoverCloseAction = callback
  }
  private _popoverCloseAction: any = null

  // static drafts = new Map();
  // static drafts = new ObservableMap<string>()
}


decorate(Card, {
  noteIsInteractive: computed,
  hasDraft: computed,
  draft: computed,

  _setDraft: action,
  _clearDraft: action,
})


export default observer(Card)

const highlightColor = "var(--highlight-color)"
const fadedHighlightColor = "grey"

// const highlightColor = window.location.hostname.startsWith('localhost')
//   ? "dodgerblue" //"crimson"
//   : "dodgerblue"
// const fadedHighlightColor = window.location.hostname.startsWith('localhost')
//   ? "grey"
//   : "grey"

const Styles = {
  Card: style({
    padding: '0.5em 1em',
    paddingBottom: '1em',
    // paddingBottom: '0',
    position: 'relative',
    borderColor: 'transparent !important',

    '.pt-card': {
      backgroundColor: 'var(--card-bg-color)!important',
      color: 'var(--card-fg-color)', //!important
      border: '1px solid transparent',
      animationDuration: '0s !important',
      transitionDuration: '0s !important',
      borderLeftWidth: 4,
      // padding: '1em',
      padding: '0',
      opacity: 0,
      // boxShadow: 'none !important',
      // filter: 'drop-shadow(0px 2px 2px var(--shadow-color))',
      boxShadow: '0px 2px 2px var(--shadow-color) !important',
      borderRadius: '0px !important',
    },

    '.pt-editable-editing.pt-editable-text:before': {
      borderColor: 'transparent !important',
      // boxShadow: '0 0 0 2px rgba(19, 124, 189, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.2)',
      boxShadow: 'none !important',
      // fontFamily: "Menlo, Monaco, monospace",
    },

    // 'textarea': {
    //   fontFamily: "Menlo, Monaco, monospace",
    // },

    "& .NoteBtn": {
      opacity: 0,
    },
    "&:hover .NoteBtn": {
      opacity: 0.15,
    },
    "&.isSelected .NoteBtn": {
      opacity: 0.15,
    },

    '&.isSelected .Btn': {
      opacity: 1,
    },
    '&.isSelected .pt-card': {
      borderColor: `${highlightColor} !important`,
    },
    '.ChildIndicator': {
      color: "darkgrey"
    },
    '&.isSelected .ChildIndicator': {
      color: `${highlightColor} !important`
    },

    '&.isVisible .pt-card': {
      opacity: 1
    },

    '&.isSelectedParent .pt-card': {
      borderColor: fadedHighlightColor,
    },
    '&.isSelectedParent .ChildIndicator': {
      color: `${fadedHighlightColor} !important`
    },

    '&.hasDraft::after': {
      position: 'absolute',
      content: ' ',
      background: 'var(--highlight-color)',
      width: 10,
      height: 10,
      borderRadius: '100%',
    },

    '&:hover .Card_Footer': {
      opacity: 1,
    },

    // '&:hover .Card_Footer': {
    //   opacity: 1,
    // },

    '.Btn': {
      opacity: 0,
      transition: 'opacity 200ms ease-in-out',
      // transitionDelay: '200ms',
      '&:active': {
        outline: '0',
      }
    },
    '&:hover .Btn': {
      opacity: 1,
    },

    "& .CardDataset + .CardContent": {
      // marginTop: 0,
      // fontSize: '75%',
    }
  }),

  CardInner: style({
    display: 'flex'
  }),

  CardTools: style({
    // backgroundColor: 'silver',
    // backgroundColor: '#F0F0F0',
    // background: 'linear-gradient(to right, var(--card-bg-color), var(--border-color))',
    padding: '0.5em',
    // borderRight: '1px dotted var(--border-color)',
    // color: 'red',

    // '.isSelected & .pt-icon-large': {
    //   color: highlightColor,
    // },
    // '.isSelectedParent & .pt-icon-large': {
    //   color: fadedHighlightColor,
    // },

    // '.isSelected & .pt-button::before': {
    //   color: highlightColor,
    // },
    // '.isSelectedParent & .pt-button::before': {
    //   color: fadedHighlightColor,
    // },


    // '.pt-icon-large': {
    // color: 'silver',
    // color: 'red',
    // fill: 'red',
    // },
    '.pt-button::before': {
      color: 'silver',
      // color: 'red',
      // fill: 'red',
    }
  }),

  CardContent: style({
    flex: '1',
    // padding: '0.5em 1em',
    padding: '0.7em 1em',
    paddingLeft: 0,
    alignSelf: 'center',
    color: 'var(--card-fg-color)', //!important

    // '& *': {
    //   color: 'var(--card-fg-color)', //!important
    // }
  }),

  AddSiblingAction: style({
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
    bottom: -6,
    // left: 'calc(50% - 12px)',
    zIndex: 5,
    opacity: 0.1,
    // cursor: 'pointer',
    transition: 'opacity 200ms',
    paddingTop: 6,

    "&:hover": {
      opacity: "1 !important",
    },

    "span": {
      cursor: "pointer",
      "&:hover": {
        color: highlightColor
      }
    },
  }),

  AddChildAction: style({
    position: 'absolute',
    display: 'flex',
    right: -1,
    top: 0,
    bottom: 6,
    // left: 'calc(50% - 12px)',
    zIndex: 5,
    opacity: 0.1,
    // cursor: 'pointer',
    transition: 'opacity 200ms',
    // backgroundColor: 'red',
    paddingLeft: 6,
    // paddingTop: '1.5em',

    "&:hover": {
      opacity: 1,
    },

    "span": {
      cursor: "pointer",
      // marginTop: 'calc(50% - 12px)',
      alignSelf: 'center',

      "&:hover": {
        // backgroundColor: highlightColor,
        // color: 'white',
        // borderTopRightRadius: '100%',
        // borderBottomRightRadius: '100%',
        color: highlightColor,
      }
    },
  }),

  ToolsBtn: style({
    position: 'absolute',
    right: 20,
    top: 12,
    cursor: 'pointer',
    // color: 'slategrey',
    // color: 'gainsboro!important',

    "&:hover": {
      color: highlightColor
    }
  }),

  HasChildNotes: style({
    position: 'absolute',
    right: 0,
    zIndex: -1,
    // top: 'calc(50% - 8px)',
    top: '1.5em',
    // opacity: 0.5,

  }),

  CardFooter: style({
    display: 'flex',
    // marginBottom: '-24px',
    // marginLeft: '-12px',
    // marginRight: '-12px',
    opacity: 0,

    '.Card_Spacer': {
      flex: 1,
    }
  }),

  ClipboardMenu: style({
    width: 200
  }),


  MetadataGrid: style({
    width: '100%',
    margin: '.25em 0',
    border: '1px solid var(--card-bg-alt-color)',
    marginBottom: '0.7rem',

    "th": {
      fontFamily: 'IBM Plex Mono',
      textAlign: 'left',
      fontWeight: 200,
      background: 'var(--card-bg-alt-color)',
      fontSize: '90%',
      padding: '2px 3px'
    },
    "td": {
      padding: '2px 3px'
    },
    "td + td": {
      borderTop: '1px solid var(--card-bg-alt-color)'
    }
  })
}


export function findIndexOf(input: HTMLInputElement) {
  if (!input.form) return -1
  for (let i = 0; i < input.form.elements.length; i++) {
    if (input.form.elements[i] === input) {
      return i
    }
  }
  return -1
}

// const TaskParser = /\- \[[\W|x]\]/gi
const TaskParser = /^([^\n]*?)\- \[[\W|x]\]/gim

// searchText.match()
export function toggleTaskMarkdown(source: string, position: number, state: boolean): string {
  let count = -1

  return source.replace(TaskParser, (taskSource: string, padding: string, ...extra: any[]) => {
    // console.log("Replacing:", { taskSource, state, count, target: position, padding })
    return (++count === position)
      ? `${padding}- [${state ? 'x' : ' '}]`
      : taskSource
  })
}