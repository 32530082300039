import { Signal } from '../utils/Signal'
import { INoteSnapshot } from '../utils/Snapshot'
import Application from './Application'
import { Note } from './NoteManager'


export class EventManager {
  whenReady = Promise.resolve(this)
  app!: Application

  // Global Application Events:
  onStart = new Signal<Application>(this, { memorize: true })

  // Selection
  onSelectionChanged = new Signal<{ selectedNote: Note, previousNote?: Note }>(this)

  // Note CRUD
  onNoteCreate = new Signal<{ note: Note }>(this)
  onNoteUpdate = new Signal<{ note: Note, prevSnapshot: INoteSnapshot }>(this)
  onNoteDelete = new Signal<{ note: Note, snapshot: INoteSnapshot }>(this)
  onNotesLoaded = new Signal<{ root: Note, trash: Note }>(this, { memorize: true })

  // Sync
  onSyncStart = new Signal<{}>(this)
  onSyncComplete = new Signal<{}>(this)
  onSyncFailure = new Signal<{ error: string }>(this)
  onSyncEnd = new Signal<{}>(this)

  // Trash Bin
  onTrashEmptied = new Signal<{}>(this)
  onNoteRestored = new Signal<{ note: Note }>(this)


  constructor() {
    this.onStart.once((event) => {
      this.app = event.data
    })
  }

  // SINGLETON: 

  static get instance(): EventManager { return this.service }
  static get service() { return this._instance || (this._instance = new this()) }
  private static _instance: EventManager
}

export default EventManager