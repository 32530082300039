import Signal from './Signal'

export const onPaste = new Signal<{ text: string, srcEvent?: ClipboardEvent }>()

// document.body.addEventListener('paste', (srcEvent) => {
//   const text = srcEvent.clipboardData.getData('text')
//   // console.log('PASTED DATA!', text)
//   onPaste.dispatch({ text, srcEvent })
// })

window.addEventListener('paste', (event: Event) => {
  const srcEvent = event as ClipboardEvent
  const text = srcEvent.clipboardData.getData('text')
  console.log('PASTED DATA!', text, "EVENT:", event)
  onPaste.dispatch({ text, srcEvent })
})

const windowAsGlobal: any = window

export function copyText(text: string) {
  if (windowAsGlobal['clipboardData'] && windowAsGlobal['clipboardData'].setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return windowAsGlobal['clipboardData'].setData("Text", text)
  }
  else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
    var textarea = document.createElement("textarea")
    textarea.textContent = text
    textarea.style.position = "fixed"  // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea)
    textarea.select()

    try {
      return document.execCommand("copy")  // Security exception may be thrown by some browsers.
    }
    catch (ex) {
      console.warn("Copy to clipboard failed.", ex)
      return false
    }
    finally {
      document.body.removeChild(textarea)
    }
  }
}

export default { copyText, onPaste }