import { ObservableMap } from 'mobx';
import { createTransformer } from 'mobx-utils';
import { debounce } from '../utils/debounce';
import { Session } from '../utils/Session';

interface DraftMap {
  [id: string]: string
}

export class DraftManager {

  whenReady: Promise<DraftManager>//Promise.resolve(this)

  draftNotes = new ObservableMap<string>()

  private session = new Session('app')

  async initialize() {
    const draftMap: DraftMap | null = this.session.get('drafts', null)

    if (!!draftMap) {
      Object.keys(draftMap).forEach(id => {
        // console.log("> Loading draft:", id)
        const draft = draftMap[id]
        this.draftNotes.set(id, draft)
      })
    }

    this.draftNotes.observe(() => {
      // console.log("Draft changed... Save to session!")
      this.saveDrafts()
    })

    return this
  }

  has = createTransformer((id: string) => {
    return this.draftNotes.has(id)
  })

  get = createTransformer((id: string) => {
    return this.draftNotes.get(id)
  })

  set(id: string, value: string) {
    return this.draftNotes.set(id, value)
  }

  clear() {
    return this.draftNotes.clear()
  }

  remove(id: string) {
    return this.draftNotes.delete(id)
  }

  saveDrafts = debounce(() => {
    this.session.set('drafts', this.draftNotes.toJSON())
  }, { defer: 1000 })

  constructor() {
    this.whenReady = this.initialize()
  }
  // Singleton

  static get service() { return this._instance || (this._instance = new this()) }
  private static _instance: DraftManager
}

export default DraftManager