import { AnchorButton, Button, Dialog, IconClasses, Intent } from '@blueprintjs/core';
import { action, decorate, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import Application from '../services/Application';
import { style } from '../utils/Stylesheet';

class Field {
  // @observable 
  value = ''
  setValue = action((value: string) => {
    this.value = value
  })
  handleChangeEvent = (e: any) => {
    this.setValue(e.target.value)
  }
}

function field(defaultValue = '') {
  const field = new Field()
  field.setValue(defaultValue)
  return field
}

decorate(Field, {
  value: observable
})

class AuthDialogController {
  isVisible = false

  show() {
    this.isVisible = true
  }
  hide() {
    this.isVisible = false
  }
}

decorate(AuthDialogController, {
  isVisible: observable,
  show: action,
  hide: action
})

class AuthDialog extends React.Component<{}, {}>{
  // private get api() {
  //   return Application.service.api
  // }

  form = {
    username: field(''),
    password: field('')
  }

  isLoading = false
  errorMessage = ''

  render() {
    return (
      <Dialog enforceFocus autoFocus lazy
        className={Styles.AuthDialog}
        isOpen={AuthDialog.isVisible}
        iconName={IconClasses.USER}
        title="Profile Information"
        onClose={this.closeDialog}
        canOutsideClickClose={false}
      >
        <div className={Styles.AuthDialogContentContainer}>
          {!!this.errorMessage && this.renderError()}
          <div className={Styles.AuthDialogContent}>
            {/* {!this.api.isAuthenticated && this.renderLoginForm()}
            {this.api.isAuthenticated && this.renderProfile()} */}
          </div>
        </div>
      </Dialog>
    )
  }

  renderError() {
    return (
      <div className="pt-callout pt-intent-danger pt-icon-error">
        Error: {this.errorMessage}
      </div>
    )
  }

  renderLoginForm() {
    return (
      <form onSubmit={this.handleLogin}>
        <label className="pt-label">
          Email:
          <input required disabled={this.isLoading}
            className="pt-input"
            type="text"
            name="username"
            value={this.form.username.value}
            onChange={this.form.username.handleChangeEvent}
          />
        </label>

        <label className="pt-label">
          Password:
          <input required disabled={this.isLoading}
            className="pt-input"
            type="password"
            name="password"
            value={this.form.password.value}
            onChange={this.form.password.handleChangeEvent}
          />
        </label>

        <Button
          type="submit" disabled={this.isLoading}
          iconName={IconClasses.LOG_IN}
          intent={Intent.PRIMARY}
          text="Login"
          loading={this.isLoading}
        />
        &nbsp; &nbsp;
        <AnchorButton disabled={this.isLoading}
          className="pt-minimal"
          iconName={IconClasses.USER}
          intent={Intent.PRIMARY}
          text="Sign Up"
        />
      </form>
    )
  }

  renderProfile() {
    return (
      <div>
        {/* <p>Welcome back, {this.api.userInfo.name}.</p> */}
        <form onSubmit={this.handleLogout}>
          <Button
            type="submit" disabled={this.isLoading}
            iconName={IconClasses.LOG_OUT}
            intent={Intent.PRIMARY}
            text="Logout"
            loading={this.isLoading}
          />
        </form>
      </div>
    )
  }

  handleLogin = (e: any) => {
    e.preventDefault()
    runInAction(() => {
      this.isLoading = true
      this.errorMessage = ""
    })
    console.log("LOGIN!")
    // this.api
    //   .login(this.form.username.value, this.form.password.value)
    //   .then((user) => {
    //     console.log(">> LOGGED IN!", user)
    //     this.closeDialog()
    //     runInAction(() => this.isLoading = false)
    //   })
    //   .catch(err => {
    //     runInAction(() => {
    //       this.isLoading = false
    //       this.errorMessage = err.message || err
    //     })
    //   })
  }

  handleLogout = (e: any) => {
    e.preventDefault()

    runInAction(() => {
      this.isLoading = true
      this.errorMessage = ""
    })
    console.log("LOGOUT!")
    // this.api
    //   .logout()
    //   .then(() => {
    //     console.log(">> LOGGED OUT!")
    //     // this.closeDialog()
    //     runInAction(() => this.isLoading = false)
    //   })
    //   .catch(err => {
    //     runInAction(() => {
    //       this.isLoading = false
    //       this.errorMessage = err.message || err
    //     })
    //   })
  }

  closeDialog = () => {
    AuthDialog.hide()
  }

  // Controller...
  static controller = new AuthDialogController()

  static get isVisible() { return this.controller.isVisible }
  static show() { this.controller.show() }
  static hide() { this.controller.hide() }
}

decorate(AuthDialog, {
  isLoading: observable,
  errorMessage: observable,
})

export default observer(AuthDialog)

const Styles = {
  AuthDialog: style({
    // top: '5vh',
    width: '45em',
    padding: 0,
    '.pt-dialog-header': {
      marginBottom: 1
    }
  }),

  AuthDialogContentContainer: style({
    maxHeight: '80vh',
    overflowY: 'auto',
    paddingTop: 1,
  }),

  AuthDialogContent: style({
    // margin: '1.75em 2.2em',
    // backgroundColor: 'white',
    // boxShadow: '0px 2px 5px rgba(0,0,0, 0.5)',
    // // marginBottom: 0,
    padding: '1em',

    // ".CardContent": {
    //   pointerEvents: 'none'
    // }
  })
}