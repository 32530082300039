import Snapshot, { INoteSnapshot, getRootNote } from '../../utils/Snapshot'
import { Note } from '../../services/NoteManager'
import App from '../../services/Application'
import { generateUuid } from '../../utils/Uuid'

export function createNoteFromSnapshot(parent: Note, snapshot: INoteSnapshot, index: number = -1, updateIds = false): Note {
  const updateAllIds = (note: Note) => {
    note.id = generateUuid()
    note.children.forEach(updateAllIds)
  }
  return App.instance.undoMgr.executeCommand({
    context: {
      snapshot,
      parentNodeId: parent.id,
      // prevSelection: App.instance.selectionMgr.selectedNote, // Selection.service.selectedNote,
      isNewNote: true
    },

    execute(ctx) {
      // console.info("> createNote id:", newNoteId, 'content:', content, 'context:', ctx)
      const parent = App.instance.noteMgr.getById(ctx.parentNodeId)
      const note = Snapshot.deserializeNote(snapshot)

      note.parent = parent //?

      if (updateIds) {
        // Change all the IDs... Ensure no duplicates for pasting, for example.
        updateAllIds(note)
        ctx.snapshot = Snapshot.serializeNote(note)
      }

      parent.addChildNote(note, index)

      App.instance.events.onNoteCreate.dispatch({ note })

      return note
    },

    revert(ctx) {
      let rootNoteData = getRootNote(ctx.snapshot)
      let note = App.instance.noteMgr.getById(rootNoteData.id)
      App.instance.selectionMgr.selectNearest(note)

      note.parent.removeChildNote(note)
      App.instance.events.onNoteDelete.dispatch({ note, snapshot: ctx.snapshot })
    }
  })
}