import Application from "../services/Application";
import { Note } from "../services/NoteManager/Note";
import { cancelEvent } from "../utils/FunctionTools";
import * as commands from '../commands'
import { Intent, IconClasses } from "@blueprintjs/core";

export function createChild(e?: any, currNote?: Note) {
  if (Application.service.selectionMgr.isDisabled) return
  cancelEvent(e)
  currNote = currNote || Application.service.selectionMgr.selectedNote
  if (!currNote) return
  // const newNote = currNote.addChildNote(NEW_NOTE_CONTENT)
  const note = commands.createNote(currNote)
  Application.service.selectionMgr.select(note)
  Application.service.selectionMgr.enterEditMode()
  return note
}

export function createSibling(e?: any, currNote?: Note) {
  if (Application.service.selectionMgr.isDisabled) return
  cancelEvent(e)
  currNote = currNote || Application.service.selectionMgr.selectedNote
  if (!currNote) return
  const currIdx = currNote.parent.indexOf(currNote)
  // const newNote = currNote.parent.addChildNote(NEW_NOTE_CONTENT, currIdx + 1)
  const note = commands.createNote(currNote.parent, undefined, currIdx + 1)
  Application.service.selectionMgr.select(note)
  Application.service.selectionMgr.enterEditMode()
  return note
}

export function createLastSibling(e?: any, currNote?: Note) {
  if (Application.service.selectionMgr.isDisabled) return
  currNote = currNote || Application.service.selectionMgr.selectedNote
  if (!currNote) return
  // const newNote = currNote.parent.addChildNote(NEW_NOTE_CONTENT)
  const note = commands.createNote(currNote.parent)
  Application.service.selectionMgr.select(note)
  Application.service.selectionMgr.enterEditMode()
  return note
}

export function deleteNote(e?: any) {
  if (Application.service.selectionMgr.isDisabled) return
  const currNote = Application.service.selectionMgr.selectedNote
  if (!currNote) return

  const doDelete = (confirmed: boolean) => {
    if (confirmed) {
      Application.service.selectionMgr.selectNearest(currNote)
      commands.deleteNote(currNote)
    }
  }
  const showError = (error: Error) => {
    Application.service.notifications.show({
      intent: Intent.DANGER,
      iconName: IconClasses.WARNING_SIGN,
      message: error.message
    })
  }

  if (Application.service.UI.shouldConfirmDeletes) {
    Application.service.confirmation.show({}).then(doDelete).catch(showError)
  }
  else {
    try {
      doDelete(true)
    }
    catch (err) {
      showError(err)
    }
  }
}
