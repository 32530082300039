import * as React from 'react'
import { getFunctionName } from './FunctionTools'

export interface IAutoDisposer {
  (...disposables: IDisposable[]): IDisposable
  disposeAll(): void
}
export interface IDisposableObject {
  dispose: () => void
}
export interface IDisposableFunction {
  (): void
}
export type IDisposable = IDisposableObject | IDisposableFunction


export function autoDisposer(container: any = UnknownContainer) {
  let _unsubscribers: IDisposable[] = []

  const trackDisposable: any = (...disposables: IDisposable[]) => {
    _unsubscribers = _unsubscribers.concat(disposables)
    return disposables.length === 1
      ? disposables[0]
      : disposables
  }

  trackDisposable['disposeAll'] = function () {
    if (_unsubscribers) {
      _unsubscribers.forEach(disposable => {
        //log.debug("Unsubscribing in", getFunctionName(container))
        try {
          if (typeof disposable === 'function') {
            disposable()
          }
          else {
            disposable.dispose()
          }
        }
        catch (err) {
          console.warn("Error unsubscribing in", getFunctionName(container), ', Error follows:')
          console.error(err)
        }
      })
      _unsubscribers = []
    }
  }

  return trackDisposable as IAutoDisposer
}

function UnknownContainer() {
}

export function setDisposableTimeout(fn: any, timeout = 0, ...args: any[]) {
  let timer = setTimeout(fn, timeout, ...args)
  return () => {
    clearTimeout(timer)
  }
}

export function setDisposableInterval(fn: any, timeout = 0, ...args: any[]) {
  let timer = setInterval(fn, timeout, ...args)
  return () => {
    clearInterval(timer)
  }
}

export function setDisposableImmediate(fn: any, ...args: any[]) {
  let timer = setImmediate(fn, ...args)
  return () => {
    clearImmediate(timer)
  }
}

export interface IAutoDisposerProps {
  autoDispose: (disposable: IDisposable) => IDisposable
  [name: string]: any
}

//<P extends IAutoDisposerProps>
export function AutoDisposer(ChildComponent: any): any { //React.ClassicComponentClass<P>
  class AutoDisposerHOC extends React.Component<any, any> {
    autoDispose = autoDisposer()

    render() {
      return React.createElement(ChildComponent, {
        autoDispose: this.handleAutoDispose
      })
    }

    handleAutoDispose = (disposable: IDisposable) => {
      console.log("AutoDisposerHOC.handleAutoDispose", disposable)
      return this.autoDispose(disposable)
    }

    componentWillUnmount() {
      console.log("AutoDisposerHOC.componentDidUnmount -> disposeAll!")
      this.autoDispose.disposeAll()
    }
  }

  return AutoDisposerHOC as any
}

export default AutoDisposer