var COMMENT_PSEUDO_COMMENT_OR_LT_BANG = new RegExp(
  '<!--[\\s\\S]*?(?:-->)?'
  + '<!---+>?'  // A comment with no body
  + '|<!(?![dD][oO][cC][tT][yY][pP][eE]|\\[CDATA\\[)[^>]*>?'
  + '|<[?][^>]*>?',  // A pseudo-comment
  'g');

const WC_PATTERN = /[a-zA-Z0-9_\u0392-\u03c9\u0410-\u04F9]+|[\u4E00-\u9FFF\u3400-\u4dbf\uf900-\ufaff\u3040-\u309f\uac00-\ud7af]+/g

/* The right word count in respect for CJK. */
export function wordCount(data: string): number {
  const m = data.replace(COMMENT_PSEUDO_COMMENT_OR_LT_BANG, "").match(WC_PATTERN);
  let count = 0;

  if (m === null) return count;

  for (let i = 0; i < m.length; i++) {
    if (m[i].charCodeAt(0) >= 0x4E00) {
      count += m[i].length;
    }
    else {
      count += 1;
    }
  }

  return count;
}

export async function wordCountAsync(data: string) {
  const m = data.replace(COMMENT_PSEUDO_COMMENT_OR_LT_BANG, "").match(WC_PATTERN);
  let count = 0;

  if (m === null) return count;

  for (let i = 0; i < m.length; i++) {
    if (m[i].charCodeAt(0) >= 0x4E00) {
      count += m[i].length;
    }
    else {
      count += 1;
    }
  }

  return count;
}

export default wordCount