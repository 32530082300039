import { action, decorate, observable } from 'mobx';
import { sleep } from '../utils/Async';
import { IMergeOptions, Note } from './NoteManager';
import Selection from './SelectionManager';


export class PreviewManager {

  whenReady = Promise.resolve(this)

  isVisible = false
  previewContent = ""

  activeNote: Note | null = null

  previewNote(note: Note, renderOptions: Partial<IMergeOptions> = {}) {
    console.log("Generating PREVIEW!")
    renderOptions = { ...renderOptions, format: 'html' } // Force HTML

    this.activeNote = note
    this.previewContent = note.allContent(renderOptions)

    if (this.isVisible == false) {
      this.isVisible = true
      Selection.service.setEnabled(false)
    }
  }

  closePreview() {
    this.isVisible = false
    this.activeNote = null
    Selection.service.setEnabled(true)

    sleep(500).then(() => {
      this.clearContent()
    })
  }

  clearContent() {
    this.previewContent = ""
  }

  //

  static get service() { return this._instance || (this._instance = new this()) }
  private static _instance: PreviewManager
}

decorate(PreviewManager, {
  isVisible: observable,
  previewContent: observable,

  previewNote: action,
  closePreview: action,
  clearContent: action,
})

export default PreviewManager