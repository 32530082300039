import { generateUuid } from './Uuid'

const DUPLICATE_ERROR_MESSAGE = 'Signal already has matching listener function.'

export interface ISignalUnsubscriber {
  (): void
}

export interface ISignalListener<T, S> {
  // (e?: SignalEvent<T, S>): void
  (e: SignalEvent<T, S>): void
}

export interface ISignalOptions {
  id?: string
  cancelable?: boolean
  memorize?: boolean
}

export interface ISignalAPI<T, S> {
  add(listener: ISignalListener<T, S>, context?: any, priority?: number): ISignalUnsubscriber
  listen(listener: ISignalListener<T, S>, context?: any, priority?: number): ISignalUnsubscriber
  on(listener: ISignalListener<T, S>, context?: any, priority?: number): ISignalUnsubscriber
  once(listener: ISignalListener<T, S>, context?: any, priority?: number): ISignalUnsubscriber
  remove(listener: ISignalListener<T, S>, context?: any): void
}

export class Signal<T, S=any> {
  id: string
  cancelable: boolean
  memorize: boolean

  active: boolean = true
  lastParams: T | null = null
  listeners: SignalBinding<T, S>[] = []

  // TODO: Type the source/origin of a Signal/Event
  constructor(
    public source?: S,
    options: ISignalOptions = {}
  ) {
    this.id = options.id || generateUuid()
    this.cancelable = options.cancelable === true
    this.memorize = options.memorize === true
  }

  /**
   * Add function listener for signals. Returns an unsubscribing function.
   */
  add(listener: ISignalListener<T, S>, context: any = null, priority = 1, singleCall = false): ISignalUnsubscriber {
    if (this.has(listener, context)) {
      throw new Error(DUPLICATE_ERROR_MESSAGE)
    }

    const binding = new SignalBinding<T, S>(this, listener, singleCall, context, priority)

    this.listeners.push(binding)

    // Sort by priority, this is expensive so we're only doing it on 'add'
    this.listeners = this.listeners.sort((a, b) => b.priority - a.priority)

    if (this.memorize && this.lastParams != null) { // jshint ignore:line
      binding.execute(new SignalEvent(this, this.source, this.lastParams))
    }

    return () => {
      this.remove(listener, context)
    }
  }

  on(listener: ISignalListener<T, S>, context = null, priority = 1, singleCall = false): ISignalUnsubscriber {
    return this.add(listener, context, priority, singleCall)
  }

  once(listener: ISignalListener<T, S>, context?: any, priority?: number): ISignalUnsubscriber {
    return this.add(listener, context, priority, true)
  }

  remove(listener: ISignalListener<T, S>, context: any = null) {
    this.listeners = this.listeners.filter(binding => {
      if (binding.matches(listener, context)) {
        binding.dispose()
        return false
      }
      else {
        return true
      }
    })
    return this
  }

  has(listener: ISignalListener<T, S>, context?: any): boolean {
    return this.getBindingFor(listener, context) != null
  }

  /**
   * Removes all listeners.
   */
  clear() {
    // this.listeners.forEach( ( binding )=> { binding.dispose() } )
    for (var i = 0, len = this.listeners.length; i !== len; i++) {
      this.listeners[i].dispose()
    }
    this.listeners = []
  }

  forward(source: Signal<T, S>) {
    source.add(this.forwardSignal, this, -100)
  }

  stopForwarding(source: Signal<T, S>) {
    source.remove(this.forwardSignal, this)
  }

  /**
   * Forget the last dispatched params.
   */
  forget() {
    this.lastParams = null
  }

  forwardSignal(event: SignalEvent<T, S>) {
    event.source = this.source
    this.sendSignal(event.data, event)
  }

  trigger = (params: T) => {
    this.sendSignal(params)
  }

  send = (params: T) => {
    this.sendSignal(params)
  }

  dispatch = (params: T) => {
    this.sendSignal(params)
  }

  sendSignal(params: T = {} as T, withEvent?: SignalEvent<T, S>) {
    if (!this.active) {
      return
    }
    if (this.listeners.length === 0) {
      if (this.memorize) {
        this.lastParams = params
      }
      return
    }

    let index = 0
    let binding: SignalBinding<T, S> | null = this.listeners[index]
    let event = withEvent instanceof SignalEvent //!= null
      ? withEvent
      : new SignalEvent<T, S>(this, this.source, params) //this.lastParams

    while (binding) {
      binding.execute(event)
      index++
      binding = this.listeners[index]
      if (event.active === false && this.cancelable) {
        binding = null
      }
    }

    if (this.memorize) {
      this.lastParams = params
    }
  }

  getBindingFor(listener: ISignalListener<T, S>, context?: any): SignalBinding<T, S> | null {
    // return this.listeners.find((binding) => {
    //   return binding.matches(listener, context)
    // })
    const matches = this.listeners.filter(binding => {
      return binding.matches(listener, context)
    })

    return matches.length > 0
      ? matches[0]
      : null
  }

  api(): ISignalAPI<T, S> {
    return {
      add: this.add.bind(this),
      listen: this.add.bind(this),
      on: this.add.bind(this),
      once: this.once.bind(this),
      remove: this.remove.bind(this)
    }
  }
}


/**
 * SignalBinding. Signal -> Listener
 */
export class SignalBinding<T, S> {
  signal: Signal<T, S>
  listener: ISignalListener<T, S>
  isOnce: boolean
  context: any
  priority: number

  constructor(signal: Signal<T, S>, listener: ISignalListener<T, S>, isOnce = false, context = null, priority = 0) {
    this.signal = signal
    this.listener = listener
    this.isOnce = isOnce
    this.context = context
    this.priority = priority
  }

  matches(sourceListener: ISignalListener<T, S>, sourceContext?: any) {
    return sourceListener === this.listener && sourceContext === this.context
  }

  execute(event: SignalEvent<T, S>) {
    try {
      this.listener.call(this.context, event)
    }
    catch (error) {
      console.warn(`Error thrown in handler for Signal(${this.signal.id})`, this.signal, error)
      console.error(error)
    }
    if (this.isOnce) {
      this.detach()
    }
  }

  detach() {
    this.signal.remove(this.listener) //, this.context)
  }

  dispose() {
    delete this.signal
    delete this.listener
    delete this.context
    // this.signal = null
    // this.listener = null
    // this.context = null
  }
}

export class SignalEvent<T, S> {
  signal: Signal<T, S>
  source?: S
  data: T
  active: boolean
  origin: any

  constructor(signal: Signal<T, S>, source: S | undefined, data: T) {
    this.signal = signal
    this.source = source
    this.data = data
    this.active = true
    this.origin = source
  }

  stopPropagation() {
    this.active = false
  }
}

export default Signal
