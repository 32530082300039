import * as React from 'react';
import { Switch, NumericInput } from '@blueprintjs/core';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import Application from '../../services/Application';
import { UIManager } from '../../services/UIManager';

export const PreferencesTab = observer(class PreferencesTabUI extends React.Component<{}, {}> {
  render() {
    return (
      <div>
        <h5>General Options</h5>
        <Switch label="Use dark mode interface" checked={UIManager.service.useDarkMode} onChange={this.toggleDarkMode} />
        <Switch label="Use CodeMirror instead of textarea" checked={UIManager.service.useCodemirror} onChange={this.toggleCodemirror} />
        <Switch label="Confirm delete before taking any actions" checked={UIManager.service.shouldConfirmDeletes} onChange={this.toggleDeleteConfirmation} />

        <Switch label="Use W/A/S/D keys for navigation" checked={UIManager.service.useWASDNavKeys} onChange={this.toggleWASDKeys} />

        <Switch label="Use H/J/K/L keys for navigation" checked={UIManager.service.useVimNavKeys} onChange={this.toggleVimKeys} />


        <label className="pt-label pt-inline">
          Preview Theme:
          <div className="pt-select">
            <select>
              <option value="sans-serif">Sans-Serif</option>
              <option disabled value="serif">Serif</option>
              <option disabled value="monospace">Monospace</option>
            </select>
          </div>
        </label>

        <label className="pt-label pt-inline">
          Editor Font:
          <div className="pt-select">
            <select value={Application.service.UI.editorFont} onChange={this.font_onChange}>
              <option value="IBM Plex Mono">IBM Plex Mono</option>
              <option value="iA Writer Duospace">iA Duospace</option>
              <option value="Ubuntu Mono">Ubuntu Mono</option>
              <option value="Cousine">Cousine</option>
              <option value="Courier New">Courier New</option>
            </select>
          </div>
        </label>



        <label className="pt-label pt-inline">
          Editor Font Size:
          <NumericInput min={8} max={20} stepSize={1} value={Application.service.UI.editorFontSize} clampValueOnBlur={true} onValueChange={this.fontSize_onChange} />



        </label>
      </div>
    );
  }
  font_onChange = (e: any) => {
    Application.service.UI.setEditorFont(e.target.value);
  };
  fontSize_onChange = (fontSize: number) => {
    Application.service.UI.setEditorFontSize(fontSize);
  };
  // fontSize_onChange = (e: any) => {
  //   Application.service.UI.setEditorFontSize(e.target.value)
  // }
  toggleDarkMode = () => {
    UIManager.service.toggleDarkMode();
  };
  toggleCodemirror = () => {
    UIManager.service.toggleCodemirror();
  };
  toggleDeleteConfirmation = () => {
    UIManager.service.toggleDeleteConfirmation();
  };
  toggleWASDKeys = () => {
    runInAction(() => {
      UIManager.service.useWASDNavKeys = !UIManager.service.useWASDNavKeys;
    });
  };
  toggleVimKeys = () => {
    runInAction(() => {
      UIManager.service.useVimNavKeys = !UIManager.service.useVimNavKeys;
    });
  };
});
