import Snapshot, { INoteSnapshot } from '../../utils/Snapshot'
import { Note } from '../../services/NoteManager'
import App from '../../services/Application'
import { updateNoteParent, deleteNote } from './index';

export function dissolveNote(note: Note): Note | null {
  return App.instance.undoMgr.inTransaction(() => {
    let firstChild = note.hasChildren
      ? note.children[0]
      : null

    let position = note.position

      ; ([...note.children]).reverse().forEach((child) => {
        updateNoteParent(child, note.parent, position)
      })

    deleteNote(note)

    return firstChild
  })
}

