import { observable, computed, action, spy, decorate } from 'mobx'
import UUID from '../utils/Uuid'

/**
 * Transaction creates a sub-command list.
 * interface CommandStack = Command | Command[] 
 * undoStack CommandStack[]
 * 
 * When undo/redo if command is a command-stack, treat them as a single unit
 * 
 * Remove the coalese stuff
 */
interface AnyContext {
  [name: string]: any
}

export interface Command<T = AnyContext> {
  execute(ctx: T): any
  revert(ctX: T): void
  context?: T
  label?: string
  shouldCoalesce?: (ctx: T, lastCtx: T | any) => boolean
}

// export interface ITransaction {
//   executeCommand<T = AnyContext>(command: Command<T>): any
// }


export class UndoManager {
  whenReady = Promise.resolve(this)

  undoStack: Command[] = []
  undoSize = 0 // @observable
  get canUndo() {
    return this.undoSize > 0
  }

  redoStack: Command[] = []
  redoSize = 0 // @observable
  get canRedo() {
    return this.redoSize > 0
  }

  // autoTrackChanges(block: () => void) {
  //   // Maybe add pause/resume methods in the callback (to stop spying)?
  //   this.startTransaction()
  //   action(block)()
  //   const changeEvents = this.endTransaction()
  //   console.log("Change Events:", changeEvents)
  //   return changeEvents
  //   // const commandList: Command[] = []
  //   // const txn = {
  //   //   executeCommand<T = AnyContext>(command: Command<T>) {
  //   //     commandList.push(command)
  //   //   }
  //   // }
  //   // block(txn)
  //   // const manager = this
  //   // this.executeCommand({
  //   //   context: {
  //   //     commandList
  //   //   },
  //   //   execute(ctx) {
  //   //     ctx.commandList.forEach(cmd => {
  //   //       manager.executeCommand(cmd)
  //   //     })
  //   //   },
  //   //   revert(ctx) {
  //   //     ctx.commandList.forEach(cmd => {
  //   //       manager.undo()
  //   //     })
  //   //   }
  //   // })
  // }

  // private _eventLog: any[] = []
  // private stopSpying: any

  // protected startTransaction() {
  //   this._eventLog = []
  //   this.stopSpying = spy(this.onMobxChange)
  // }

  // protected endTransaction() {
  //   this.stopSpying()
  //   return this._eventLog
  // }

  // protected onMobxChange = (event: any) => {
  //   if (!('type' in event)) return
  //   if (event.type === 'compute') return
  //   if (event.type === 'action') return
  //   if (event.type === 'scheduled-reaction') return
  //   if (event.type === 'reaction') return
  //   // if (event.type === 'add' || event.type === 'update' || event.type === 'remove')
  //   this._eventLog.push(event)
  // }

  executeCommand<T = AnyContext>(command: Command<T>) {
    const {
      execute, revert,
      context = {},
      shouldCoalesce = (c: AnyContext, pc: any) => false
    } = command

    let returnValue = execute(context as any)
    let cmd = this.lastCommand

    if (cmd && shouldCoalesce(context as any, cmd.context as any)) {
      if (!cmd.context || cmd.context._isCoalesced) {
        throw new Error("Compound coelesced Commands are not currently supported!")
      }
      let mergedContext = {
        prevContext: cmd.context,
        lastContext: context,
        ...cmd.context,
        ...context,
        _isCoalesced: true,
        _lastCommand: cmd
      }
      this.undoStack.pop()
      this.undoStack.push({
        execute(ctx) {
          cmd.execute(ctx as any)
          execute(ctx as any)
        },
        revert(ctx) {
          revert(ctx as any)
          cmd.revert(ctx as any)
        },
        context: mergedContext,
        shouldCoalesce: () => false
      })
    }
    else {
      this.undoStack.push({
        execute,
        revert,
        context,
        //@ts-ignore
        shouldCoalesce
      })
    }

    if (this.canRedo) {
      this.redoStack.length = 0
    }
    this._calcSizes()

    return returnValue
  }

  // coalesceCommand(execute, revert, context = {}) {
  //   let cmd: Command = this.undoStack.pop() as Command
  //   execute()
  //   if (cmd !== undefined) {
  //     this.undoStack.push({
  //       execute() {
  //         cmd.execute()
  //         execute()
  //       },
  //       revert() {
  //         cmd.revert()
  //         revert()
  //       },
  //       context: {
  //         ...context,
  //         ...cmd.context
  //       }
  //     })
  //   }
  //   else {
  //     this.undoStack.push({ execute, revert, context })
  //   }
  //   this.calcSizes()
  // }

  get lastCommand() {
    return this.undoStack[this.undoStack.length - 1]
  }

  inTransaction<T = any>(transactionBlock: () => T): T {
    const startingIndex = this.undoStack.length
    const result = transactionBlock()
    const endingIndex = this.undoStack.length

    if (startingIndex == endingIndex) return result

    const commands = this.undoStack.splice(startingIndex, endingIndex - startingIndex)

    this.undoStack.push(groupCommands(commands) as any)
    this._calcSizes()
    // console.log("grouped commands in range", { startingIndex, endingIndex, commands, stack: this.undoStack })
    return result
  }

  undo() {
    if (this.canUndo) {
      let cmd: Command = this.undoStack.pop() as Command
      cmd.revert(cmd.context as any)
      this.redoStack.push(cmd)
      this._calcSizes()
      return true
    }
    else {
      return false
    }
  }

  redo() {
    if (this.canRedo) {
      let cmd: Command = this.redoStack.pop() as Command
      cmd.execute(cmd.context as any)
      this.undoStack.push(cmd)
      this._calcSizes()
      return true
    }
    else {
      return false
    }
  }

  /**
   * Calc sizes will set undoSize and redoSize, updating any observers.
   * This is because the actual undo/redo stacks are not observed.
   */
  _calcSizes() {
    this.undoSize = this.undoStack.length
    this.redoSize = this.redoStack.length
  }

  static get service() { return this._instance || (this._instance = new this()) }
  private static _instance: UndoManager
}

decorate(UndoManager, {
  undoSize: observable,
  redoSize: observable,

  canUndo: computed,
  canRedo: computed,

  undo: action,
  redo: action,
  _calcSizes: action,
})

export default UndoManager

function groupCommands(commands: Command[]): Command<{ commands: Command[] }> {
  return {
    context: {
      commands
    },
    execute(ctx) {
      return ctx.commands.map(cmd => cmd.execute(cmd.context!))
    },
    revert(ctx) {
      [...ctx.commands].reverse().forEach(cmd => cmd.revert(cmd.context!))
    }
  }
}
