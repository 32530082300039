import classNames_ from 'classnames'
// import { create, FreeStyle, Styles } from 'free-style'
import * as FreeStyle from 'free-style'

var globalSheet: FreeStyle.FreeStyle = FreeStyle.create()
var timer: number = 0

export const classNames = classNames_

export function css(styles: Partial<CssStyles>): Partial<CssStyles> {
  return styles
}

export function style(object: Partial<CssStyles>, displayName?: string): string {
  deferredInjection()
  // tslint:disable-next-line
  return globalSheet.registerStyle(object as any, displayName)
}

export function namedStyle(name: string, object: Partial<CssStyles>, displayName?: string): { [name: string]: string } {
  deferredInjection()
  // tslint:disable-next-line
  return {
    [name]: globalSheet.registerStyle(object as any, displayName)
  }
}

// namedStyle('Test', {})

export function keyframe(object: Partial<CssStyles>, displayName?: string): string {
  deferredInjection()
  // tslint:disable-next-line
  return globalSheet.registerKeyframes(object as any, displayName)
}

export function rule(query: string, object: Partial<CssStyles>): void {
  deferredInjection()
  // tslint:disable-next-line
  return globalSheet.registerRule(query, object as any)
}

function deferredInjection() {
  cancelAnimationFrame(timer)

  timer = requestAnimationFrame(() => {
    // Inject `<style>` into the `<head>`.
    var styleElement = document.createElement('style')
    styleElement.textContent = globalSheet.getStyles()
    document.head.appendChild(styleElement)
    globalSheet = FreeStyle.create()
  })
}

// tslint:disable
export interface CssStyles { //extends Partial<CSSStyleDeclaration>
  // [name: string]: string | number | CssStyles;//| any //| boolean

  alignContent: any;
  alignItems: any;
  alignSelf: any;
  alignmentBaseline: any;
  animation: any;
  animationDelay: any;
  animationDirection: any;
  animationDuration: any;
  animationFillMode: any;
  animationIterationCount: any;
  animationName: any;
  animationPlayState: any;
  animationTimingFunction: any;
  backfaceVisibility: any;
  background: any;
  backgroundAttachment: any;
  backgroundClip: any;
  backgroundColor: any;
  backgroundImage: any;
  backgroundOrigin: any;
  backgroundPosition: any;
  backgroundPositionX: any;
  backgroundPositionY: any;
  backgroundRepeat: any;
  backgroundSize: any;
  baselineShift: any;
  border: any;
  borderBottom: any;
  borderBottomColor: any;
  borderBottomLeftRadius: any;
  borderBottomRightRadius: any;
  borderBottomStyle: any;
  borderBottomWidth: any;
  borderCollapse: any;
  borderColor: any;
  borderImage: any;
  borderImageOutset: any;
  borderImageRepeat: any;
  borderImageSlice: any;
  borderImageSource: any;
  borderImageWidth: any;
  borderLeft: any;
  borderLeftColor: any;
  borderLeftStyle: any;
  borderLeftWidth: any;
  borderRadius: any;
  borderRight: any;
  borderRightColor: any;
  borderRightStyle: any;
  borderRightWidth: any;
  borderSpacing: any;
  borderStyle: any;
  borderTop: any;
  borderTopColor: any;
  borderTopLeftRadius: any;
  borderTopRightRadius: any;
  borderTopStyle: any;
  borderTopWidth: any;
  borderWidth: any;
  bottom: any;
  boxShadow: any;
  boxSizing: any;
  breakAfter: any;
  breakBefore: any;
  breakInside: any;
  captionSide: any;
  clear: any;
  clip: any;
  clipPath: any;
  clipRule: any;
  color: any;
  colorInterpolationFilters: any;
  columnCount: any;
  columnFill: any;
  columnGap: any;
  columnRule: any;
  columnRuleColor: any;
  columnRuleStyle: any;
  columnRuleWidth: any;
  columnSpan: any;
  columnWidth: any;
  columns: any;
  content: any;
  counterIncrement: any;
  counterReset: any;
  cssFloat: any;
  cssText: any;
  cursor: any;
  direction: any;
  display: any;
  dominantBaseline: any;
  emptyCells: any;
  enableBackground: any;
  fill: any;
  fillOpacity: any;
  fillRule: any;
  filter: any;
  flex: any;
  flexBasis: any;
  flexDirection: any;
  flexFlow: any;
  flexGrow: any;
  flexShrink: any;
  flexWrap: any;
  floodColor: any;
  floodOpacity: any;
  font: any;
  fontFamily: any;
  fontFeatureSettings: any;
  fontSize: any;
  fontSizeAdjust: any;
  fontStretch: any;
  fontStyle: any;
  fontVariant: any;
  fontWeight: any;
  glyphOrientationHorizontal: any;
  glyphOrientationVertical: any;
  height: any;
  imeMode: any;
  justifyContent: any;
  kerning: any;
  layoutGrid: any;
  layoutGridChar: any;
  layoutGridLine: any;
  layoutGridMode: any;
  layoutGridType: any;
  left: any;
  letterSpacing: any;
  lightingColor: any;
  lineBreak: any;
  lineHeight: any;
  listStyle: any;
  listStyleImage: any;
  listStylePosition: any;
  listStyleType: any;
  margin: any;
  marginBottom: any;
  marginLeft: any;
  marginRight: any;
  marginTop: any;
  marker: any;
  markerEnd: any;
  markerMid: any;
  markerStart: any;
  mask: any;
  maxHeight: any;
  maxWidth: any;
  minHeight: any;
  minWidth: any;
  msContentZoomChaining: any;
  msContentZoomLimit: any;
  msContentZoomLimitMax: any;
  msContentZoomLimitMin: any;
  msContentZoomSnap: any;
  msContentZoomSnapPoints: any;
  msContentZoomSnapType: any;
  msContentZooming: any;
  msFlowFrom: any;
  msFlowInto: any;
  msFontFeatureSettings: any;
  msGridColumn: any;
  msGridColumnAlign: any;
  msGridColumnSpan: any;
  msGridColumns: any;
  msGridRow: any;
  msGridRowAlign: any;
  msGridRowSpan: any;
  msGridRows: any;
  msHighContrastAdjust: any;
  msHyphenateLimitChars: any;
  msHyphenateLimitLines: any;
  msHyphenateLimitZone: any;
  msHyphens: any;
  msImeAlign: any;
  msOverflowStyle: any;
  msScrollChaining: any;
  msScrollLimit: any;
  msScrollLimitXMax: any;
  msScrollLimitXMin: any;
  msScrollLimitYMax: any;
  msScrollLimitYMin: any;
  msScrollRails: any;
  msScrollSnapPointsX: any;
  msScrollSnapPointsY: any;
  msScrollSnapType: any;
  msScrollSnapX: any;
  msScrollSnapY: any;
  msScrollTranslation: any;
  msTextCombineHorizontal: any;
  msTextSizeAdjust: any;
  msTouchAction: any;
  msTouchSelect: any;
  msUserSelect: any;
  msWrapFlow: any;
  msWrapMargin: any;
  msWrapThrough: any;
  opacity: any;
  order: any;
  orphans: any;
  outline: any;
  outlineColor: any;
  outlineOffset: any;
  outlineStyle: any;
  outlineWidth: any;
  overflow: any;
  overflowX: any;
  overflowY: any;
  padding: any;
  paddingBottom: any;
  paddingLeft: any;
  paddingRight: any;
  paddingTop: any;
  pageBreakAfter: any;
  pageBreakBefore: any;
  pageBreakInside: any;
  perspective: any;
  perspectiveOrigin: any;
  pointerEvents: any;
  position: any;
  quotes: any;
  right: any;
  rotate: any;
  rubyAlign: any;
  rubyOverhang: any;
  rubyPosition: any;
  scale: any;
  stopColor: any;
  stopOpacity: any;
  stroke: any;
  strokeDasharray: any;
  strokeDashoffset: any;
  strokeLinecap: any;
  strokeLinejoin: any;
  strokeMiterlimit: any;
  strokeOpacity: any;
  strokeWidth: any;
  tableLayout: any;
  textAlign: any;
  textAlignLast: any;
  textAnchor: any;
  textDecoration: any;
  textIndent: any;
  textJustify: any;
  textKashida: any;
  textKashidaSpace: any;
  textOverflow: any;
  textShadow: any;
  textTransform: any;
  textUnderlinePosition: any;
  top: any;
  touchAction: any;
  transform: any;
  transformOrigin: any;
  transformStyle: any;
  transition: any;
  transitionDelay: any;
  transitionDuration: any;
  transitionProperty: any;
  transitionTimingFunction: any;
  translate: any;
  unicodeBidi: any;
  verticalAlign: any;
  visibility: any;
  webkitAlignContent: any;
  webkitAlignItems: any;
  webkitAlignSelf: any;
  webkitAnimation: any;
  webkitAnimationDelay: any;
  webkitAnimationDirection: any;
  webkitAnimationDuration: any;
  webkitAnimationFillMode: any;
  webkitAnimationIterationCount: any;
  webkitAnimationName: any;
  webkitAnimationPlayState: any;
  webkitAnimationTimingFunction: any;
  webkitAppearance: any;
  webkitBackfaceVisibility: any;
  webkitBackgroundClip: any;
  webkitBackgroundOrigin: any;
  webkitBackgroundSize: any;
  webkitBorderBottomLeftRadius: any;
  webkitBorderBottomRightRadius: any;
  webkitBorderImage: any;
  webkitBorderRadius: any;
  webkitBorderTopLeftRadius: any;
  webkitBorderTopRightRadius: any;
  webkitBoxAlign: any;
  webkitBoxDirection: any;
  webkitBoxFlex: any;
  webkitBoxOrdinalGroup: any;
  webkitBoxOrient: any;
  webkitBoxPack: any;
  webkitBoxSizing: any;
  webkitColumnBreakAfter: any;
  webkitColumnBreakBefore: any;
  webkitColumnBreakInside: any;
  webkitColumnCount: any;
  webkitColumnGap: any;
  webkitColumnRule: any;
  webkitColumnRuleColor: any;
  webkitColumnRuleStyle: any;
  webkitColumnRuleWidth: any;
  webkitColumnSpan: any;
  webkitColumnWidth: any;
  webkitColumns: any;
  webkitFilter: any;
  webkitFlex: any;
  webkitFlexBasis: any;
  webkitFlexDirection: any;
  webkitFlexFlow: any;
  webkitFlexGrow: any;
  webkitFlexShrink: any;
  webkitFlexWrap: any;
  webkitJustifyContent: any;
  webkitOrder: any;
  webkitPerspective: any;
  webkitPerspectiveOrigin: any;
  webkitTapHighlightColor: any;
  webkitTextFillColor: any;
  webkitTextSizeAdjust: any;
  webkitTextStroke: any;
  webkitTextStrokeColor: any;
  webkitTextStrokeWidth: any;
  webkitTransform: any;
  webkitTransformOrigin: any;
  webkitTransformStyle: any;
  webkitTransition: any;
  webkitTransitionDelay: any;
  webkitTransitionDuration: any;
  webkitTransitionProperty: any;
  webkitTransitionTimingFunction: any;
  webkitUserModify: any;
  webkitUserSelect: any;
  webkitWritingMode: any;
  whiteSpace: any;
  widows: any;
  width: any;
  wordBreak: any;
  wordSpacing: any;
  wordWrap: any;
  writingMode: any;
  zIndex: any;
  zoom: any;
  resize: any;
  userSelect: any;

  [name: string]: Partial<CssStyles> | string | number;//| any //| boolean
}