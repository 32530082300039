import Application from "../services/Application";
import { Note } from "../services/NoteManager/Note";
import { cancelEvent } from "../utils/FunctionTools";
import * as commands from '../commands'
import { Intent, IconClasses } from "@blueprintjs/core";
import { IAggregationType } from "../services/NoteManager/ContentUtils";
import Snapshot, { INoteSnapshot } from "../utils/Snapshot";


export function moveUp(e?: any, currNote?: Note) {
  cancelEvent(e)
  // if (Application.service.selectionMgr.isDisabled) return
  currNote = currNote || Application.service.selectionMgr.selectedNote
  if (!currNote) return

  if (!commands.updateNotePosition(currNote, currNote.position - 1)) {
    Application.service.notifications.show({
      intent: Intent.PRIMARY,
      message: 'Failed to move note up.',
      timeout: 1500
    })
  }
}

export function moveDown(e?: any, currNote?: Note) {
  cancelEvent(e)
  // if (Application.service.selectionMgr.isDisabled) return
  currNote = currNote || Application.service.selectionMgr.selectedNote
  if (!currNote) return
  if (!commands.updateNotePosition(currNote, currNote.position + 1)) {
    Application.service.notifications.show({
      intent: Intent.PRIMARY,
      message: 'Failed to move note down.',
      timeout: 1500
    })
  }
}

export function showPreview(e?: any, note?: Note, aggregate: IAggregationType = 'note-children') {
  cancelEvent(e)
  // if (Application.service.selectionMgr.isDisabled) return
  note = note || Application.service.selectionMgr.selectedNote
  if (!note) return
  Application.service.previewMgr.previewNote(note, { contents: aggregate })
}

export function enterEditMode(e?: any, note?: Note) {
  if (Application.service.selectionMgr.isDisabled) return
  cancelEvent(e)
  if (note && note != Application.service.selectionMgr.selectedNote) {
    Application.service.selectionMgr.select(note)
  }
  Application.service.selectionMgr.enterEditMode()
}

export function exitEditMode(e?: any) {
  // if (Application.service.selectionMgr.isDisabled) return
  cancelEvent(e)
  Application.service.selectionMgr.exitEditMode()
}


export function hoist(e?: any, note?: Note) {
  if (Application.service.selectionMgr.isDisabled) return
  cancelEvent(e)

  if (note && note != Application.service.selectionMgr.selectedNote) {
    Application.service.selectionMgr.select(note)
  }

  if (!Application.service.selectionMgr.hoistSelected()) {
    Application.service.notifications.show({
      iconName: IconClasses.WARNING_SIGN,
      intent: Intent.DANGER,
      message: "Cannot hoist a note with no children!"
    })
  }
}

export function unhoist(e?: any) {
  if (Application.service.selectionMgr.isDisabled) return
  cancelEvent(e)

  if (!Application.service.selectionMgr.unhoist()) {
    Application.service.notifications.show({
      intent: Intent.PRIMARY,
      message: 'Nothing to unhoist!',
      timeout: 1500
    })
  }
}


export function undo(e?: any) {
  if (Application.service.selectionMgr.isDisabled) return
  cancelEvent(e)
  e && e.stopImmediatePropagation()

  Application.service.undoMgr.undo()
}

export function redo(e?: any) {
  if (Application.service.selectionMgr.isDisabled) return
  cancelEvent(e)
  e && e.stopImmediatePropagation()
  Application.service.undoMgr.redo()
}

export function cut() {
  if (Application.service.selectionMgr.isDisabled) return
  const snapshot = Snapshot.serializeNote(Application.service.selectionMgr.selectedNote)
  console.info("COPY!", snapshot)

    ; (window as any)['snapshot_clipboard'] = snapshot

  commands.deleteNote(Application.service.selectionMgr.selectedNote)
}

export function copy(e?: any) {
  if (Application.service.selectionMgr.isDisabled) return
  //e.preventDefault()
  //e.stopPropagation()
  //e.stopImmediatePropagation()
  const snapshot = Snapshot.serializeNote(Application.service.selectionMgr.selectedNote)
  console.info("COPY!", snapshot)

    ; (window as any)['snapshot_clipboard'] = snapshot
  setTimeout(() => {
    Application.service.clipboard.copyText(JSON.stringify(snapshot))
  })
}

export function paste(e?: KeyboardEvent) {
  if (Application.service.selectionMgr.isDisabled) return
  const snapshot = (window as any)['snapshot_clipboard'] as INoteSnapshot
  const asChild = e && e.shiftKey
  const parent = asChild
    ? Application.service.selectionMgr.selectedNote
    : Application.service.selectionMgr.selectedNote.parent
  const index = asChild
    ? 0
    : Application.service.selectionMgr.selectedNote.position + 1

  let newNote: Note = null as any

  if (!snapshot) {
    Application.instance.clipboard.onPaste.once(e => {
      try {
        const snapshot = JSON.parse(e.data.text)
        newNote = commands.createNoteFromSnapshot(parent, snapshot, index, true)
      }
      catch (ex) {
        if (typeof e.data.text == 'string' && e.data.text != "") {
          newNote = commands.createNote(parent, e.data.text, index)
        }
        else {
          Application.service.notifications.show({
            intent: Intent.DANGER,
            message: "Could not paste data from clipboard."
          })
        }
      }
    })
  }
  else {
    newNote = commands.createNoteFromSnapshot(parent, snapshot, index, true)
  }

  if (newNote) {
    Application.service.selectionMgr.select(newNote)
  }
}

export function showSettings(e?: any) {
  // if (Application.service.selectionMgr.isDisabled) return
  cancelEvent(e)
  Application.service.settings.show()
}

export function toggleTheme(e?: any) {
  cancelEvent(e)
  Application.service.UI.toggleDarkMode()
}

export function toggleTree(e?: any) {
  cancelEvent(e)
  if (Application.service.treeView.isVisible) {
    Application.service.treeView.hide()
  }
  else {
    Application.service.treeView.show()
  }
}

export function emptyTrash(e?: any) {
  cancelEvent(e)
  Application.service.confirmation
    .show({
      body: "This is a permanent action and cannot be undone. Continue?"
    })
    .then((confirmation) => {
      if (confirmation) {
        // alert("Killing the trash bin!")
        const notes = [...Application.service.noteMgr.trashBin.children]
        notes.forEach(n => n.parent.removeChildNote(n))
        Application.service.events.onTrashEmptied.dispatch({})
      }
    }).catch((error) => {
      Application.service.notifications.show({
        intent: Intent.DANGER,
        iconName: IconClasses.WARNING_SIGN,
        message: error.message
      })
    })
}

