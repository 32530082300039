import { Dialog, IconClasses, Tab2, Tabs2 } from '@blueprintjs/core';
import { action, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import Application from '../../services/Application';
import { style } from '../../utils/Stylesheet';
import { BackupTab } from './BackupTab';
import { DonateTab } from './DonateTab';
import { PreferencesTab } from './PreferencesTab';

export const logoImgPath = '/icons/NoteTreeIcon_Circle_512.png'

enum Tab {
  Preferences, Sync, Import, Export, Donate, Backup
}

class SettingsDialog extends React.Component<{}, {}>{
  get app() {
    return Application.service
  }

  activeTab: Tab = Tab.Preferences
  setActiveTab(index: Tab) { this.activeTab = index }

  tabAttrs(index: Tab) {
    return {
      active: this.activeTab === index,
      onClick: () => this.setActiveTab(index)
    }
  }

  render() {
    return (
      <Dialog enforceFocus autoFocus lazy
        className={Styles.SettingsDialog}
        isOpen={this.app.settings.isVisible}
        iconName={IconClasses.COG}
        title="Settings"
        onClose={this.handleClose}
        canOutsideClickClose={false}
      >
        <div>
          <DialogTabset />
        </div>

      </Dialog>
    )
  }

  handleClose = () => {
    this.app.settings.hide()
  }
}

decorate(SettingsDialog, {
  activeTab: observable,
  setActiveTab: action,
})

export default observer(SettingsDialog)

const Styles = {
  SettingsDialog: style({
    // top: '5vh',
    width: '45em',
    padding: 0,
    '.pt-dialog-header': {
      marginBottom: 1
    }
  }),

  CustomTabs: style({
    position: 'relative',
    padding: 12,
    textAlign: 'center',

    ".pt-button-group": {
      boxShadow: '0px 0px 1px rgba(0,0,0, 0.2)',
    }
  }),

  CustomTabDivider: style({
    content: " ",
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    boxShadow: '0px 2px 2px rgba(0,0,0, 0.2)',
    backgroundColor: 'rgba(255,255,255, 0.2)',
    // bottom: 0,
    bottom: '50%',
    // borderBottom: '1px solid gainsboro',
  }),

  CustomTabBody: style({
    padding: '1em',
    paddingTop: '0.5em',
  }),

  SettingsDialogTabs: style({
    padding: '1em',
    borderBottom: '1px dotted silver'
  }),

  SettingsDialogContent: style({
    paddingTop: '0.5em',

    ".pt-tab-list": {
      borderBottom: `1px dotted silver`,
      paddingLeft: '1em',
      paddingRight: '1em',
    },
    ".pt-tab-panel": {
      padding: '1em',
      paddingTop: 0,
    },
  })
}

const DialogTabset = observer(() => (
  <div>
    <div className={Styles.SettingsDialogContent}>
      <Tabs2 id="SettingsTab" defaultSelectedTabId={'p'} animate={false} renderActiveTabPanelOnly>
        <Tab2 id="p" title="Preferences" panel={<PreferencesTab />} />
        {/* <Tab2 id="s" title="Sync" panel={<SyncTab />} /> */}
        <Tab2 id="b" title="Backup" panel={<BackupTab />} />
        {/* <Tab2 id="i" title="Import" panel={<ImportTab />} /> */}
        {/* <Tab2 id="e" title="Export" panel={<ExportTab />} /> */}
        {/* <Tabs2.Expander /> */}
        <Tab2 id="d" title="Donate!" panel={<DonateTab />} />
      </Tabs2>
    </div>
  </div>
))



// class ImportTabUI extends React.Component<{}, {}> {
//   isImporting = false
//   importJSON = ""
//   mergeType = "add"
//   isDragOver = false

//   _json!: HTMLTextAreaElement

//   assign = {
//     _json: (c: any) => this._json = c,
//     importJSON: action((e: any) => this.importJSON = e.currentTarget.value),
//     mergeType: action((e: any) => this.mergeType = e.currentTarget.value),
//   }

//   render() {
//     let importIntent = Intent.PRIMARY
//     if (this.mergeType === 'merge') {
//       importIntent = Intent.WARNING
//     }
//     else if (this.mergeType === 'replace') {
//       importIntent = Intent.DANGER
//     }
//     return (
//       <div className={classNames(ImportClasses.Tab, { isDragOver: this.isDragOver })}>
//         <textarea
//           className="pt-input pt-fill pt-readonly"
//           ref={this.assign._json}
//           style={{ zoom: 0.7, fontFamily: 'Monaco, monospace' }}
//           rows={20}
//           placeholder="Paste JSON, or drag backup `.json` file here...."
//           disabled={this.isImporting}
//           value={this.importJSON}
//           onChange={this.assign.importJSON}
//           onDrop={this.handleDrop}
//           onDragEnter={this.handleDragEnter}
//           onDragExit={this.handleDragExit}
//         />
//         <br /><br />
//         <Row>
//           <Row>
//             <label>
//               Merge Options: &nbsp;
//           </label>
//             <RadioGroup onChange={this.assign.mergeType} selectedValue={this.mergeType}>
//               <Radio className="pt-inline" label="Add" value="add" />
//               <Radio className="pt-inline" label="Merge" value="merge" disabled />
//               <Radio className="pt-inline" label="Replace" value="replace" />
//             </RadioGroup>
//           </Row>

//           <Spacer />
//           <Button
//             loading={this.isImporting}
//             iconName={IconClasses.IMPORT}
//             text="Import"
//             onClick={this.handleImport}
//             disabled={this.importJSON == ""}
//             intent={importIntent}
//           />
//         </Row>
//       </div>
//     )
//   }

//   handleImport = action(() => {
//     this.isImporting = true

//     const starter = this.mergeType === 'replace'
//       ? Application.service.confirmation.show({
//         body: "This could/will result in data loss. Are you sure?",
//         yesLabel: "Yes, Continue Import",
//         noLabel: "Cancel"
//       })
//       : Promise.resolve(true)

//     starter
//       .then(confirmed => {
//         if (!confirmed) {
//           throw new Error("Cancel import.")
//         }
//       })
//       .then(() => this.doImport())
//       .then((noteTree: Note) => {
//         console.info("• Imported:", noteTree)
//         Application.service.notifications.show({
//           iconName: IconClasses.INFO_SIGN,
//           intent: Intent.PRIMARY,
//           message: `Import Complete`
//         })

//       })
//       .catch(err => {
//         Application.service.notifications.show({
//           iconName: IconClasses.ERROR,
//           intent: Intent.DANGER,
//           message: `Import Error: ${err.message}`
//         })
//       })
//       .then(() => sleep(500))
//       .then(action(() => {
//         this.isImporting = false
//       }))
//   })

//   handleImportNew = action(async () => {
//     this.isImporting = true

//     if (this.mergeType === 'replace') {
//       const confirmed = await Application.service.confirmation.show({
//         body: "This could/will result in data loss. Are you sure?",
//         yesLabel: "Yes, Continue Import",
//         noLabel: "Cancel"
//       })
//       if (!confirmed) {
//         throw new Error("Cancel import.")
//       }
//     }

//     try {
//       const noteTree = await this.doImport()
//       console.info("• Imported:", noteTree)
//       Application.service.notifications.show({
//         iconName: IconClasses.INFO_SIGN,
//         intent: Intent.PRIMARY,
//         message: `Import Complete`
//       })
//     }
//     catch (err) {
//       Application.service.notifications.show({
//         iconName: IconClasses.ERROR,
//         intent: Intent.DANGER,
//         message: `Import Error: ${err.message}`
//       })
//     }

//     await sleep(500)
//     this.isImporting = false
//   })

//   private doImport = action(async () => {
//     const importData: IBackupData = JSON.parse(this.importJSON)

//     const isValidData = (
//       importData &&
//       importData.version &&
//       typeof importData.version == 'string' &&
//       importData.version.startsWith('0.1') &&
//       'snapshot' in importData
//     )

//     if (!isValidData) {
//       throw new Error("Invalid JSON.")
//     }

//     const noteTree = Snapshot.deserializeNote(importData.snapshot, this.mergeType === 'add')

//     console.log("  Import Data:", importData)
//     console.log("    Regen IDs:", this.mergeType === 'add')
//     console.log("   Merge Type:", this.mergeType)
//     console.log("     New Root:", noteTree)

//     if (this.mergeType === 'add') {
//       console.info("ADD")
//       Application.service.noteMgr.rootNote.addChildNote(noteTree)
//       Application.service.selectionMgr.forceSelectionChain(
//         Application.service.selectionMgr.rootNote,
//         noteTree
//       )
//       Application.service.noteMgr.commands.updateNoteContent(
//         noteTree,
//         `# Import ${new Date(importData.exported).toLocaleDateString()}`
//       )
//     }
//     else if (this.mergeType === 'replace') {
//       console.info("REPLACE")
//       Application.service.noteMgr.rootNote = noteTree
//       Application.service.selectionMgr.forceSelectionChain(
//         noteTree,
//         noteTree.children[0]
//       )
//       Application.service.noteMgr.commands.updateNoteContent(
//         noteTree,
//         `# NoteTree`,
//         { force: true }
//       )
//     }
//     else {
//       throw new Error("Merge type not supported: " + this.mergeType)
//     }

//     return noteTree
//   })

//   handleDrop = (e: any) => {
//     e.preventDefault()
//     this.handleDragExit(null)
//     const files = e.target.files || e.dataTransfer.files
//     const file = files[0]
//     loadFileText(file)
//       .then(action((json: string) => {
//         this.importJSON = json
//       }))
//       .catch(err => {
//         Application.service.notifications.show({
//           iconName: IconClasses.ERROR,
//           intent: Intent.DANGER,
//           message: err.message
//         })
//       })
//   }

//   handleDragEnter = action((e: any) => {
//     e.preventDefault()
//     this.isDragOver = true
//   })

//   handleDragExit = action((e: any) => {
//     e && e.preventDefault()
//     this.isDragOver = false
//   })
// }

// decorate(ImportTabUI, {
//   isImporting: observable,
//   importJSON: observable,
//   mergeType: observable,
//   isDragOver: observable,
// })

// const ImportTab = observer(ImportTabUI)

// const ImportClasses = {
//   Tab: style({

//     '&.isDragOver': {

//       'textarea': {
//         backgroundColor: 'var(--highlight-color)', //'dodgerblue',
//         color: 'white',
//       }

//     }
//   }),
// }

// class ExportTabUI extends React.Component<{}, {}> {
//   exportJSON = ""
//   includeTrashbin = false
//   prettifyJSON = false
//   isBuilding = false
//   buildTime = 0

//   _json!: HTMLTextAreaElement
//   _anchor!: HTMLAnchorElement

//   assign = {
//     _json: (c: any) => this._json = c,
//     _anchor: (c: any) => this._anchor = c,
//     prettifyJSON: action((e: any) => this.prettifyJSON = e.currentTarget.checked),
//     includeTrashbin: action((e: any) => this.includeTrashbin = e.currentTarget.checked)
//   }

//   render() {
//     return (
//       <div>
//         <textarea readOnly
//           className="pt-input pt-fill pt-readonly"
//           ref={this.assign._json}
//           style={{ zoom: 0.7, fontFamily: 'Monaco, monospace' }}
//           rows={20}
//           value={this.exportJSON}
//           onClick={this.handleAutoSelect}
//         />
//         <br />{this.exportJSON != "" && <span style={{ float: 'right' }} className="pt-text-muted">JSON built in {this.buildTime}ms</span>}<br />
//         <Switch
//           label="Pretty JSON"
//           checked={this.prettifyJSON}
//           onChange={this.assign.prettifyJSON}
//           disabled={this.exportJSON != ""}
//         />
//         <Switch
//           label="Include Trash Bin"
//           checked={this.includeTrashbin}
//           onChange={this.assign.includeTrashbin}
//           disabled={this.exportJSON != ""}
//         />
//         <Row>
//           <Button
//             loading={this.isBuilding}
//             iconName={IconClasses.BUILD}
//             text="Generate JSON"
//             onClick={this.handleGenerateClick}
//             disabled={this.exportJSON != ""}
//           />
//           <Spacer />
//           <Button
//             loading={this.isBuilding}
//             iconName={IconClasses.DOWNLOAD}
//             text="Download"
//             onClick={this.handleDownload}
//             disabled={this.exportJSON == ""}
//             intent={Intent.PRIMARY}
//           />
//           <a ref={this.assign._anchor} />
//         </Row>

//       </div>
//     )
//   }

//   handleAutoSelect = () => {
//     this._json.focus()
//     this._json.select()
//   }

//   handleGenerateClick = action(() => {
//     this.isBuilding = true
//     setTimeout(() => {
//       this.buildJSON()
//     }, 10)
//   })

//   handleDownload = () => {
//     // DOWNLOAD!
//     const filename = `notetree-backup-${(new Date()).toISOString().split('T')[0]}.json`
//     const dataString = "data:text/json;charset=utf-8," + encodeURIComponent(this.exportJSON)

//     console.log("Downloading:", filename)

//     this._anchor.setAttribute('href', dataString)
//     this._anchor.setAttribute('download', filename)
//     this._anchor.click()
//   }

//   buildJSON = action(async () => {

//     this.isBuilding = true

//     const start = (performance && performance.now()) || Date.now()
//     const backup = await Application.service.backup.createBackup({
//       includeTrash: this.includeTrashbin
//     })

//     runInAction(() => {
//       this.exportJSON = this.prettifyJSON
//         ? JSON.stringify(backup, null, 2)
//         : JSON.stringify(backup)

//       const end = (performance && performance.now()) || Date.now()
//       this.buildTime = end - start
//       this.isBuilding = false
//       // setTimeout(this.handleAutoSelect, 100)
//     })

//   })
// }

// decorate(ExportTabUI, {
//   exportJSON: observable,
//   includeTrashbin: observable,
//   prettifyJSON: observable,
//   isBuilding: observable,
//   buildTime: observable,
// })

// const ExportTab = observer(ExportTabUI)





