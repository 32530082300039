/* https://gist.github.com/mattmccray/7012 */

export type FountainLineType
  = 'dialogue'
  | 'parenthetical'
  | 'character'
  | 'sceneheader'
  | 'transition'
  | 'action'
  | 'pagebreak'

export interface FountainLine {
  type: FountainLineType
  content: string
}

export function fountainParser(text: string) {
  var lines = text.split("\n"),
    prevLine: FountainLine | null = null,
    script: FountainLine[] = [];

  for (var i = 0; i < lines.length; i++) {
    var srcLine = lines[i],
      firstChar = srcLine[0],
      line: FountainLine | null = null;

    if (srcLine.trim() == '') {
      // Empty line...
    }
    else if (firstChar == '(') {
      line = { type: 'parenthetical', content: srcLine };
    }
    else if (prevLine && (prevLine.type == 'parenthetical' || prevLine.type == 'character')) {
      line = { type: 'dialogue', content: srcLine };
    }
    else if (srcLine.trim() == '---') {
      line = { type: 'pagebreak', content: srcLine };
    }
    else if (srcLine.startsWith('.')) {
      line = { type: 'sceneheader', content: srcLine };
    }
    else if (srcLine == srcLine.toUpperCase()) {
      if (srcLine.match(/(INT|EXT|EST|PANEL)/g)) {
        line = { type: 'sceneheader', content: srcLine };
      } else if (srcLine.match(/(CUT|FADE)/g)) {
        line = { type: 'transition', content: srcLine };
      } else {
        line = { type: 'character', content: srcLine };
      }
    }
    else {
      line = { type: 'action', content: srcLine };
    }

    if (line) {
      script.push(line);
      prevLine = line;
    }
  };
  return script;
}

var TEMPLATES: any = {
  container: '<div class="scrippet">#{ content }</div>',
  sceneheader: '<p class="sceneheader">#{ content }</p>',
  action: '<p class="action">#{ content }</p>',
  character: '<p class="character">#{ content }</p>',
  dialogue: '<p class="dialogue">#{ content }</p>',
  parenthetical: '<p class="parenthetical">#{ content }</p>',
  transition: '<p class="transition">#{ content }</p>',
  pagebreak: '<hr class="pagebreak"/>'
}

// Simple, non-evaling, templating support
function template(source: string, ctx: any) {
  if (typeof ctx != 'object') ctx = { content: ctx };
  return TEMPLATES[source].replace(/(#\{(.*?)\})/gim, function (cmdText: any) {
    var cmds = cmdText.match(/#\{[\s]*(.*?)[\s]*?\}/)[1].split('.'),
      obj = ctx;
    for (var i = 0; i < cmds.length; i++) { obj = obj[cmds[i]]; }
    return obj;
  });
}

function renderer(lines: FountainLine[]) {
  var html = '';
  for (var i = 0; i < lines.length; i++) {
    html += template(lines[i].type, lines[i]);
  };
  return template('container', html);
}

export function convertFountain(text: string | FountainLine[]) {
  const lines = (typeof text == 'string')
    ? fountainParser(text)
    : text
  return renderer(lines);
}



























export function convertFountainOld(text: string) {
  if (!text.startsWith('[scrippet')) {
    text = `[scrippet]${text}\n[/scrippet]`
  }

  const scrippet_pattern_string = '[\\[<]scrippet[\\]>]([^]*?)[\\[<]\\/scrippet[\\]>]';
  const scrippet_pattern = new RegExp(scrippet_pattern_string, "i");
  const scrippet_pattern_g = new RegExp(scrippet_pattern_string, "gi");
  let matches

  while (matches = scrippet_pattern_g.exec(text)) {
    const output = '' + "\r\n" +
      matches[1].replace(/<[^>]*>/g, '') /* remove any tags Wordpress or Markdown may dump into the scrippet */
        .replace(/\r/g, '') /* some basic character replacements to deal with bugs that Wordpress/Markdown introduce */
        .replace(/&amp;/g, '&')
        .replace(/\.{3}|É/g, '&#46;&#46;&#46;')
        .replace(/\-{2}|Ñ|Ð/g, '&#45;&#45;')
        .replace(/(PANEL|INT|EXT|EST)([\.\-\s]+?)(.+?)([A-Za-z0-9\)\s\.])\n/gi, '<div class="sceneheader">$1$2$3$4</div>' + "\r\n") /* Sceneheaders must start with INT or EXT */
        .replace(/\n([^<>\na-z]*?:|FADE TO BLACK\.|FADE OUT\.|CUT TO BLACK\.)[\s]??\n/g, '<div class="transition">$1</div>' + "\r\n") /* Catches transitions -- Looks for a colon, with some hard coded exceptions that don't use colons. */
        .replace(/\n([^<>\n]*?:[^\n]+?)\n/g, '<div class="action">$1</div>' + "\r\n")
        .replace(/\n{2}(([^a-z\n\:]+?[\.\?\,\s\!]*?)\n{2}){1,2}/g, "\n" + '<div class="action">$2</div>' + "\r\n") /* Catches multi-line action blocks -- looks for all caps without punctuation, then two Newlines -- This differentiates from character cues because Cues will only have a single break, then the dialogue/parenthetical. */
        .replace(/\n([^<>a-z\s][^a-z:\!\?]*?[^a-z\(\.\!\?:,][\s]??)\n/g, '<div class="character">$1</div>') /* Catches character cues -- Looks for all caps, parenthesis (for O.S./V.O.), then a single newline. */
        .replace(/(\([^<>]*?\)[\s]??)\n/g, '<div class="parenthetical">$1</div>') /* Catches parentheticals -- Just looks for text between parenthesis. */
        .replace(/(<div class="character">.*<\/div>|<div class="parenthetical">.*<\/div>)\n?([^<>\n]+?)\n/g, '$1<div class="dialogue">$2</div>' + "\r\n") /* Catches dialogue -- Must follow a character cue or parenthetical. */
        .replace(/([^<>]*?)\n/g, '<div class="action">$1</div>' + "\r\n") /* Defaults */
        .replace(/<div class="action">[\n\s]*?<\/div>/g, '') /* Hack - cleans up the mess the action regex is leaving behind.*/
        .replace(/\n/g, '') /* hide newlines from live posts */
      + '\r\n';
    text = text.replace(scrippet_pattern, output);
  }

  return text;
}

export function convertFountainCustomTags(text: string) {
  if (!text.startsWith('[scrippet')) {
    text = `[scrippet]${text}\n[/scrippet]`
  }

  const scrippet_pattern_string = '[\\[<]scrippet[\\]>]([^]*?)[\\[<]\\/scrippet[\\]>]';
  const scrippet_pattern = new RegExp(scrippet_pattern_string, "i");
  const scrippet_pattern_g = new RegExp(scrippet_pattern_string, "gi");
  let matches

  while (matches = scrippet_pattern_g.exec(text)) {
    const output = '' + "\r\n" +
      matches[1].replace(/<[^>]*>/g, '') /* remove any tags Wordpress or Markdown may dump into the scrippet */
        .replace(/\r/g, '') /* some basic character replacements to deal with bugs that Wordpress/Markdown introduce */
        .replace(/&amp;/g, '&')
        .replace(/\.{3}|É/g, '&#46;&#46;&#46;')
        .replace(/\-{2}|Ñ|Ð/g, '&#45;&#45;')
        .replace(/(\.|PANEL|INT|EXT|EST)([\.\-\s]+?)(.+?)([A-Za-z0-9\)\s\.])\n/gi, '<sceneheader>$1$2$3$4</sceneheader>' + "\r\n") /* Sceneheaders must start with INT or EXT */
        .replace(/\n([^<>\na-z]*?:|FADE TO BLACK\.|FADE OUT\.|CUT TO BLACK\.)[\s]??\n/g, '<transition>$1</transition>' + "\r\n") /* Catches transitions -- Looks for a colon, with some hard coded exceptions that don't use colons. */
        .replace(/\n([^<>\n]*?:[^\n]+?)\n/g, '<action>$1</action>' + "\r\n")
        .replace(/\n{2}(([^a-z\n\:]+?[\.\?\,\s\!]*?)\n{2}){1,2}/g, "\n" + '<action>$2</action>' + "\r\n") /* Catches multi-line action blocks -- looks for all caps without punctuation, then two Newlines -- This differentiates from character cues because Cues will only have a single break, then the dialogue/parenthetical. */
        .replace(/\n([^<>a-z\s][^a-z:\!\?]*?[^a-z\(\.\!\?:,][\s]??)\n/g, '<character>$1</character>') /* Catches character cues -- Looks for all caps, parenthesis (for O.S./V.O.), then a single newline. */
        .replace(/(\([^<>]*?\)[\s]??)\n/g, '<parenthetical>$1</parenthetical>') /* Catches parentheticals -- Just looks for text between parenthesis. */
        .replace(/(<character>.*<\/character>|<parenthetical>.*<\/parenthetical>)\n?([^<>\n]+?)\n/g, '$1<dialogue>$2</dialogue>' + "\r\n") /* Catches dialogue -- Must follow a character cue or parenthetical. */
        .replace(/([^<>]*?)\n/g, '<action>$1</action>' + "\r\n") /* Defaults */
        .replace(/<action>[\n\s]*?<\/action>/g, '') /* Hack - cleans up the mess the action regex is leaving behind.*/
        .replace(/\n/g, '') /* hide newlines from live posts */
      + '\r\n';
    text = text.replace(scrippet_pattern, output);
  }

  return text;
}

// export default convertFountain