import { action, computed, observable, toJS, decorate, runInAction } from 'mobx'
import UUID from '../../utils/Uuid'
import Snapshot from '../../utils/Snapshot'
import { extractTitle, extractMetaData, generateHTML, flattenNoteContent, IMergeOptions, extractMarkdown, calculateWordCount, createMarkdownWithFM } from './ContentUtils'
import { convertFountain } from '../../utils/Fountain'

export const NEW_NOTE_CONTENT = "Enter your note here..."

export interface INoteMetadata {
  $export?: boolean
  $title?: string
  $showMetadata?: boolean
  type?: 'fountain' | 'screenplay' | 'markdown'

  [name: string]: any
}

export class Note {
  id!: string //| number // Number if from server, string if it's never been saved
  parent!: Note // | undefined
  children: Note[] = []
  created!: number
  updated!: number

  _cache = {
    html: "",
    title: "",
    metadata: {} as INoteMetadata,
    markdown: "",
  }
  _content: string = ""


  get hasParent() { // @computed 
    return this.parent !== undefined
  }

  get hasChildren() { // @computed 
    return this.children.length > 0
  }

  get hasDescendants() { // @computed 
    return this.children.length != this.fullCount
  }

  get fullCount(): number { // @computed 
    return this.children.reduce((total, note) => {
      return total + note.fullCount
    }, this.children.length)
  }


  get allDescendants(): Note[] { // @computed 
    return this.children.reduce((list, note) => {
      return [...list, note, ...note.allDescendants]
    }, [] as Note[])
  }

  get title() {
    return this._cache.title
  }

  get contentHTML() {
    return this._cache.html
  }

  get contentMarkdown() {
    return this._cache.markdown
  }

  get content() {
    return this._content
  }
  set content(value: string) {
    this.updateContent(value)
  }

  get contentType() {
    return this.metadata.type || 'markdown'
  }

  get metadata() { // @computed 
    return this._cache.metadata || {}
  }

  get isEmpty() {
    return this.contentMarkdown.trim() == ""
  }

  get hasMetadata() { // @computed 
    return Object.keys(this.metadata).length > 0
  }

  get isDataset() { // @computed 
    return this.isEmpty && this.hasMetadata
  }

  get wordCount() {
    return calculateWordCount(this.contentMarkdown)
  }

  addChildNote(content: string | Note, index: number | 'top' | 'bottom' = -1, id = UUID.generate()) {
    index = (typeof index == 'string')
      ? (index == 'top') ? 0 : -1
      : index
    const newNote = typeof content === 'string'
      ? Note.fromContent(content, this, id)
      : (content.parent = this, content)

    if (index > -1) {
      this.children.splice(index, 0, newNote)
    }
    else {
      this.children.push(newNote)
    }

    return newNote
  }

  setMetadata(key: string | object, value?: any) {
    const newMetadata = (typeof key == 'string')
      ? { ...toJS(this.metadata), [key]: value }
      : { ...toJS(this.metadata), ...key }
    console.log(">> Updated metadata", newMetadata)
    this.updateContent(createMarkdownWithFM(this.contentMarkdown, newMetadata))
  }

  removeMetadata(key: string | string[]) {
    const newMetadata = toJS(this.metadata)
    const keys = typeof key == 'string' ? [key] : key

    keys.forEach(name => delete newMetadata[name])

    console.log(">> Updated metadata", newMetadata)
    this.updateContent(createMarkdownWithFM(this.contentMarkdown, newMetadata))
  }

  updateContent(content: string, updated = Date.now()) {
    if (content === this._content) {
      console.warn("Content unchanged.")
      return
    }
    this._content = content
    this.updated = updated

    this._cache.title = extractTitle(content)
    this._cache.metadata = extractMetaData(content)
    this._cache.markdown = extractMarkdown(content)
    this._cache.html = this._cache.metadata.type == 'fountain'
      ? convertFountain(this._cache.markdown)
      : generateHTML(this._cache.markdown)
  }

  removeChildNote(noteOrId: Note | string | undefined) {
    let note: Note | undefined = typeof noteOrId === 'string'
      ? this.children.find(note => note.id === noteOrId)
      : noteOrId

    if (note !== undefined) {
      let index = this.indexOf(note)
      this.children.splice(index, 1)
    }

    return note
  }

  get position() {
    return !!this.parent
      ? this.parent.indexOf(this)
      : -1
  }

  repositionTo(position: number) {
    if (
      position === this.position ||
      !this.hasParent ||
      position < 0 ||
      position > this.parent.children.length
    ) {
      return false
    }

    this.parent.removeChildNote(this)
    this.parent.addChildNote(this, position)

    return true
  }

  indexOf(childNote: Note): number {
    return this.children.indexOf(childNote)
  }

  findById(id: string | number): Note | null {
    if (this.id === id) {
      return this
    }
    else {
      if (this.hasChildren) {
        for (let i = 0; i < this.children.length; i++) {
          let found = this.children[i].findById(id)
          if (!!found) {
            return found
          }
        }
        return null
      }
      else {
        return null
      }
    }
  }

  allContent(options: Partial<IMergeOptions> = {}) {
    return flattenNoteContent(this, options)
  }

  toJson(recursive = true) {
    return Snapshot.serializeNote(this, recursive)
  }

  toValue() {
    const json = this.toJson(false)
    return json.notes[json.rootNoteId]
  }

  static fromContent(content: string, parent?: Note, id: string = UUID.generate()): Note { //| number
    const note = new Note()

    note.created = new Date().getTime()
    note.content = content
    note.id = id
    // note.parentId = parentId
    note.parent = parent as any

    return note
  }
}

decorate(Note, {
  _cache: observable,
  _content: observable,
  id: observable,
  parent: observable,
  children: observable,
  created: observable,
  updated: observable,

  hasParent: computed,
  hasChildren: computed,
  hasDescendants: computed,
  fullCount: computed,
  allDescendants: computed,
  metadata: computed,
  hasMetadata: computed,
  isDataset: computed,
  wordCount: computed,

  addChildNote: action,
  removeChildNote: action,
  repositionTo: action,
  updateContent: action,
})
