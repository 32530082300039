import Markdown from '../../utils/Markdown'
import { wordCount } from '../../utils/wordCount'
import { Note } from './Note'
import matter from 'gray-matter'

  ; (window as any)['matter'] = matter

export const TITLE_PARSER = /^[#]{1,}((.*))$/

export type IAggregationType = 'note' | 'note-peers' | 'note-children' | 'children' | 'leaves'

const defaultMergeOptions = {
  // insertTextBetweenNotes: '\n\n---\n\n',
  insertTextBetweenNotes: '\n\n<br>\n\n',
  contents: 'note-children' as IAggregationType,
  format: 'markdown'
}

export function extractTitle(content: string): string {
  const results = matter(content)

  if (results) {
    const data: any = results.data
    if (!!data.title) return data.title
  }

  const contentAsText = Markdown.removeFormatting(results.content || content)
  const lines = contentAsText.split('\n')
  let firstLine = lines.shift()!

  while (firstLine && firstLine.trim() == "") {
    firstLine = lines.shift()!
  }

  if (!firstLine || firstLine == "") {
    return "(No Content)"
  }

  if (firstLine.startsWith("#")) {
    let match = firstLine.match(TITLE_PARSER)

    if (match && match.length >= 2) {
      firstLine = String(match[1]).trim()
    }
  }
  else {
    firstLine = `${firstLine.split('.')[0]}` // TODO: Make less naive... Won't for other puncuation.
  }
  return firstLine
}

export function createMarkdownWithFM(content: string, metadata: any): string {
  return matter.stringify(content, metadata)
}

export function calculateWordCount(content: string): number {
  if (!content || content == "") return 0

  return wordCount(extractMarkdown(content))
}

export function extractMetaData(content: string): any {
  const results = matter(content)
  return results.data || {}
}

export function extractMarkdown(content: string): any {
  const results = matter(content)
  return results.content
}

export function generateHTML(markdown: string) {
  const results = matter(markdown)
  return Markdown.convertToHTML(results.content || markdown)
}

export type IMergeOptions = typeof defaultMergeOptions

function getFormattedContent(note: Note, options: Partial<IMergeOptions> = {}) {
  if (note.metadata.$export == false) return ''
  return options.format == 'markdown'
    ? note.contentMarkdown
    : note.contentHTML
}

export function flattenNoteContent(note: Note, options: Partial<IMergeOptions> = {}) {
  const fullOptions: (IMergeOptions) = Object.assign({}, defaultMergeOptions, options)

  if (options.contents === 'note') {
    return getFormattedContent(note, options)//note.content
  }
  else if (options.contents === 'note-peers') {
    return _formatContentArray(
      note.parent.children.map(n => getFormattedContent(n, options)),
      fullOptions
    )
  }
  else if (options.contents === 'leaves') {
    const leaves = flatten(_recursivelyGetLeafContent(fullOptions, note))
    return _formatContentArray(leaves, fullOptions)
  }
  else {
    const contents = flatten(_recursivelyGetContent(fullOptions, note, true))
    // Pull out the first content (the root note) if applicable
    if (!fullOptions.contents.startsWith('note')) {
      contents.shift()
    }
    return _formatContentArray(contents, fullOptions)
  }
}

function _formatContentArray(contents: string[], options: IMergeOptions): string {
  return contents.join(options.insertTextBetweenNotes)
}

function _recursivelyGetLeafContent(options: IMergeOptions, note: Note): string[] {
  if (note.hasChildren) {
    const children = note.children.map(child => _recursivelyGetLeafContent(options, child))
    return flatten(children)
  }
  else {
    return [getFormattedContent(note, options)]
    // return [note.content]
  }
}

function _recursivelyGetContent(options: IMergeOptions, note: Note, isRoot = false): string[] {
  if (note.hasChildren) {
    const children = note.children.map(child => _recursivelyGetContent(options, child))
    return flatten([
      getFormattedContent(note, options),
      children
    ])
    // return [note.content, note.children.map(child => _recursivelyGetContent(options, child))]
  }
  else {
    return [getFormattedContent(note, options)]
    // return [note.content]
  }
}

function flatten(arr: any[]): any[] {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
  }, []);
}