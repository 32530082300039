
/**
 * A Promise version of setTimeout
 */
export function sleep<T>(time: number, resolveWith?: T) {
  return new Promise<T>(resolve => {
    setTimeout(() => resolve(resolveWith), time)
  })
}

export default {
  sleep
}

async function test() {
  var x = await sleep(100, 100)
}