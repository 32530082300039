import { IconClasses, ITreeNode, Tree } from '@blueprintjs/core'; //, Classes: PtClasses, TreeNode
import { computed, decorate } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import Application from '../services/Application';
import { Note } from '../services/NoteManager';
import { autoDisposer } from '../utils/AutoDisposer';
import { classNames, style } from '../utils/Stylesheet';


function noteToTreeNode(note: Note): ITreeNode {
  return {
    iconName: IconClasses.DOCUMENT,
    label: note.title,
    id: note.id,
    hasCaret: note.hasChildren, //false,  
    isExpanded: true,
    // isSelected: false,
    // isExpanded: (
    //   // Application.service.selectionMgr.isNoteSelected(note) ||
    //   Application.service.selectionMgr.isNoteParentOfSelected(note)
    // ),
    isSelected: Application.service.selectionMgr.isNoteSelected(note),
    childNodes: note.children.map(noteToTreeNode)
  }
}


class NoteTree extends React.Component<{}, {}> {
  autoDispose = autoDisposer(this)
  get app() {
    return Application.service
  }

  get treeContent(): ITreeNode[] {
    console.log("Computing tree...")
    // return this.app.noteMgr.rootNote.children.map(noteToTreeNode)
    if (this.app.selectionMgr.rootNote === this.app.noteMgr.rootNote) {
      return this.app.selectionMgr.rootNote.children.map(noteToTreeNode)
    }
    else {
      return [noteToTreeNode(this.app.selectionMgr.rootNote)]
    }

  }

  render() {
    return (
      <div className={Classes.Container} ref={this.app.UI.assignNoteTreeElement}>
        {this.app.selectionMgr.isHoisted &&
          <div className={classNames(Classes.Header, { isHoisted: this.app.selectionMgr.isHoisted })} onClick={this.handleUnhoist}>
            <div className={Classes.HeaderIcon}><span className="pt-icon-standard pt-icon-arrow-left" />{' '}</div>
            <div className={Classes.HeaderLabel}>Unhoist</div>
          </div>
        }
        {!this.app.selectionMgr.isHoisted &&
          <div className={Classes.Header}>
            &nbsp;
          </div>
        }
        <div className={Classes.TreeContainer}>
          <Tree
            className="pt-small pt-darkX"
            contents={this.treeContent}
            onNodeClick={this.handleNodeClick}
            onNodeExpand={this.handleNodeExpand}
            onNodeCollapse={this.handleNodeCollapse}
          >
          </Tree>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.autoDispose(
      () => {
        this.app.UI.assignNoteTreeElement(null)
      },
      this.app.events.onSelectionChanged.on((e) => {
        setTimeout(() => {
          const li = document.querySelector('li.pt-tree-node-selected')
          if (li) {
            const item = li.firstElementChild
            if (item) {
              scrollIntoView(item, false, {
                centerIfNeeded: false,
                easing: 'easeOut',
                duration: 200
              })
            }
          }
        })
      })
    )
  }

  componentWillUnmount() {
    this.autoDispose.disposeAll()
  }

  handleNodeClick = (treeNode: ITreeNode) => {
    console.info(">> CLICK", treeNode.id)
    const note = this.app.noteMgr.getById(treeNode.id as string)
    const root = this.app.selectionMgr.rootNote
    if (note !== root && root.findById(note.id)) {
      this.app.selectionMgr.select(note)
    }
  }

  handleNodeExpand = (treeNode: ITreeNode) => {
    console.info(">> EXPAND", treeNode.id)
  }

  handleNodeCollapse = (treeNode: ITreeNode) => {
    console.info(">> COLLAPSE", treeNode.id)
  }

  handleUnhoist = () => {
    this.app.selectionMgr.unhoist()
  }
}

decorate(NoteTree, {
  treeContent: computed
})

export default observer(NoteTree)

const Classes = {
  Container: style({
    width: '20vw',
    // backgroundColor: '#094b8c',
    // backgroundColor: 'gainsboro',
    // backgroundColor: 'rgba(0,0,0, 0.05)',

    // borderRight: '1px solid #022c56',
    // borderRight: '1px solid silver',
    display: 'flex',
    flexDirection: 'column',
  }),

  Header: style({
    // color: '#022c56',
    // color: 'white',
    height: 30,
    display: 'flex',
    alignItems: 'center',

    '&.isHoisted': {
      cursor: 'pointer',
      '&:hover': {
        background: 'rgba(255,255,255, 0.2)',
      },
    },
  }),

  HeaderIcon: style({
    paddingLeft: 12,
  }),

  HeaderLabel: style({
    flex: 1,
    paddingLeft: 12,
  }),

  TreeContainer: style({
    flex: 1,
    zoom: 0.8,
    overflowY: 'auto',
    // borderRight: '1px dotted var(--border-color)',
    boxShadow: 'inset -2px -2px 2px var(--shadow-color)',

    ".pt-tree-node-label": {
      color: "var(--card-fg-color)", //!important
      opacity: 0.5,
    }
  }),
}