import { Note } from '../services/NoteManager'
import { generateUuid } from './Uuid'

export interface INoteData {
  id: string
  parentId?: string | undefined
  // position: number
  content: string
  created: number
  updated: number
  // isTrashed: boolean
  children: (string)[]
}

interface INoteMap {
  [id: string]: INoteData
}

export interface INoteSnapshot {
  rootNoteId: string,
  notes: INoteMap
}


export function serializeNote(note: Note, recursively = true): INoteSnapshot {
  const noteMap = {}

  return {
    rootNoteId: createNoteData(noteMap, note, recursively),
    notes: noteMap
  }
}

function createNoteData(noteMap: INoteMap, note: Note, recurse = true): string {
  let noteId: string = String(note.id)
  noteMap[noteId] = {
    id: note.id,
    parentId: note.parent && note.parent.id,
    created: note.created,
    children: recurse
      ? note.children.map(child => createNoteData(noteMap, child, recurse))
      : note.children.map(child => child.id), // [],
    // position: note.position,
    // isTrashed: note.isTrashed,
    updated: note.updated,
    content: note.content,
  }

  return note.id
}

export function deserializeNote(snapshot: INoteSnapshot, regenerateIds = false): Note {
  const createNoteFromId = (id: string) => convertDataToNote(snapshot.notes[id])
  const convertDataToNote = (note: INoteData): Note => (
    Object.assign(new Note(), {
      id: note.id,
      content: note.content,
      created: note.created,
      updated: note.updated,
      // isTrashed: note.isTrashed,
      children: note.children.map(createNoteFromId)
    })
  )
  const fixChildren = (note: Note): Note => {
    if (regenerateIds) {
      note.id = generateUuid()
    }
    note.children.forEach(child => {
      child.parent = note
      fixChildren(child)
    })
    return note
  }
  return fixChildren(createNoteFromId(snapshot.rootNoteId))
}

export function getRootNote(snapshot: INoteSnapshot) {
  return snapshot.notes[snapshot.rootNoteId];
}

export default {
  deserializeNote, serializeNote, getRootNote
}

  ; (window as any)['noteTreeFromSnapshot'] = deserializeNote