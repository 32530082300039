export function insertHeaderText(textarea: HTMLTextAreaElement, text: string) {
  const { selectionStart, selectionEnd, value } = textarea

  const newLineStart = getStartOfLineFromPosition(value, selectionStart)
  let offsetLength = 0
  let peekChar = value.substr(newLineStart, 1)

  while (peekChar == '#' || peekChar == ' ') {
    offsetLength++
    peekChar = value.substr(newLineStart + offsetLength, 1)
  }

  replaceText(textarea, text, newLineStart, newLineStart + offsetLength)

  const replacementOffset = text.length - offsetLength
  textarea.setSelectionRange(selectionStart + replacementOffset, selectionEnd + replacementOffset)
}

export function toggleTextMarks(textarea: HTMLTextAreaElement, mark: string) {
  const { selectionStart, selectionEnd, value } = textarea
  const markLength = mark.length
  const selectedText = value.substring(selectionStart, selectionEnd)
  const peekBefore = value.substring(selectionStart - markLength, selectionStart)
  const peekAfter = value.substring(selectionEnd, selectionEnd + markLength)

  if (peekBefore === peekAfter && peekBefore === mark) {
    // Remove marks
    textarea.setSelectionRange(selectionStart - markLength, selectionEnd + markLength)
    insertText(selectedText)
    textarea.setSelectionRange(selectionStart - markLength, selectionEnd - markLength)
    return false
  }
  else {
    // Insert marks
    insertText(`${mark}${selectedText}${mark}`)
    textarea.setSelectionRange(selectionStart + markLength, selectionEnd + markLength)
    return true
  }
}

export function getStartOfLineFromPosition(text: string, position: number): number {
  let newLineStart = text.substring(0, position).lastIndexOf('\n')
  return newLineStart + 1
}

export function prependText(textarea: HTMLTextAreaElement, text: string, atStartOfLine = false) {
  const { selectionStart, selectionEnd, value } = textarea
  let insertPos = selectionStart

  if (atStartOfLine) {
    insertPos = getStartOfLineFromPosition(value, selectionStart)
  }

  replaceText(textarea, text, insertPos)
  textarea.setSelectionRange(selectionStart + text.length, selectionEnd + text.length)
}

export function replaceText(textarea: HTMLTextAreaElement, text: string, start: number, end: number = start, showUI = false) {
  textarea.setSelectionRange(start, end)
  return document.execCommand("insertText", showUI, text);
}

export function insertText(text: string, showUI = false) {
  return document.execCommand("insertText", showUI, text);
}

export default {
  insertHeaderText, toggleTextMarks, getStartOfLineFromPosition, replaceText, insertText
}