export function cancelEvent(e?: any) {
  e && e.preventDefault && e.preventDefault()
  e && e.stopPropagation && e.stopPropagation()
}

/**
 * Returns the name, or displayName of the passed Function or Object
 */
export function getFunctionName(source: any): string {
  const fn = source && typeof source === 'object'
    ? source.constructor
    : typeof source === 'symbol'
      ? { name: String(source) }
      : source || {}

  return fn.name || fn.displayName || 'anonymous'
}

export default {
  getFunctionName, cancelEvent
}

