import { Button, Dialog, IconClasses, Intent, Radio, RadioGroup } from '@blueprintjs/core';
import { action, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import Application from '../services/Application';
import { IAggregationType } from '../services/NoteManager/index';
import { style } from '../utils/Stylesheet';

function setExportOption(options: any, key: string, callback?: (value: any) => void) {
  return action((e: any) => {
    options[key] = e.currentTarget.value
    callback && callback(options[key])
  })
}

class PreviewDialog extends React.Component<{}, {}>{

  exportOptions = {
    source: "note-children"
  }

  private get manager() {
    return Application.service.previewMgr
  }

  onSourceChange = (contents: IAggregationType) => {
    this.manager.previewNote(this.manager.activeNote!, { contents })
    const container = document.querySelector('.PreviewScrollArea')
    if (container) {
      container.scrollTo(0, 0)
    }
  }

  form = {
    sourceOnChange: setExportOption(this.exportOptions, 'source', this.onSourceChange)
  }

  render() {
    return (
      <Dialog enforceFocus autoFocus lazy
        className={Styles.PreviewDialog}
        isOpen={this.manager.isVisible}
        iconName={IconClasses.EYE_OPEN}
        title="Note Preview"
        onClose={this.hidePreview}
        canOutsideClickClose={false}
      >
        <div>
          <div className={Styles.DialogHeader}>
            <RadioGroup
              onChange={this.form.sourceOnChange}
              selectedValue={this.exportOptions.source}
              inline
            >
              <Radio label="Note" value="note" />
              <Radio label="Note and siblings" value="note-peers" />
              <Radio label="Note and all children" value="note-children" />
              <Radio label="All child notes" value="children" />
              <Radio label="Leaf notes only" value="leaves" />
            </RadioGroup>
          </div>
          <div className={"PreviewScrollArea " + Styles.PreviewDialogContentContainer}>
            <div className={Styles.PreviewDialogContent}>
              <div
                className="CardContent"
                dangerouslySetInnerHTML={{ __html: this.manager.previewContent }}
              />
            </div>
          </div>
          <div className={Styles.DialogActions}>
            <Button text="Download HTML" intent={Intent.PRIMARY} disabled />
          </div>
        </div>
      </Dialog>
    )
  }

  hidePreview = () => {
    this.manager.closePreview()
  }

}

decorate(PreviewDialog, {
  exportOptions: observable,
})

export default observer(PreviewDialog)

const Styles = {
  PreviewDialog: style({
    // top: '5vh',
    width: '45em',
    padding: 0,
    '.pt-dialog-header': {
      marginBottom: 1
    }
  }),

  PreviewDialogContentContainer: style({
    maxHeight: '70vh',
    // minHeight: '70vh',
    overflowY: 'auto',
    paddingTop: 1,
  }),

  PreviewDialogContent: style({
    margin: '0.2em 2.2em', //'1.75em 2.2em',
    backgroundColor: 'white',
    color: 'black',
    boxShadow: '0px 2px 5px rgba(0,0,0, 0.5)',
    // marginBottom: 0,
    padding: '1em',

    ".CardContent": {
      pointerEvents: 'none'
    }
  }),

  DialogHeader: style({
    padding: 12,
    paddingBottom: 0,
    textAlign: 'center'
  }),

  DialogActions: style({
    padding: 12,
    textAlign: 'right'
  })
}