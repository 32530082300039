import Snapshot, { INoteSnapshot } from '../../utils/Snapshot'
import NoteMgr, { Note } from '../../services/NoteManager'
import App from '../../services/Application'

export function updateNoteParent(note: Note, newParent: Note, position: number | 'top' | 'bottom'): boolean {
  if (
    note == newParent ||
    note.parent == newParent ||
    note.allDescendants.includes(newParent)
  ) {
    console.warn("Invalid parent target.")
    return false
  }

  const getNoteById = App.instance.noteMgr.getByIdOrThrow


  return App.instance.undoMgr.executeCommand({
    context: {
      noteId: note.id,
      parentId: newParent.id,
      prevParentId: note.parent.id,
      position: typeof position == 'string'
        ? (position == 'top' ? 0 : newParent.children.length)
        : position,
      prevPosition: note.position,
      snapshot: {} as INoteSnapshot,
      parentSnapshot: {} as INoteSnapshot,
      newParentSnapshot: {} as INoteSnapshot
    },

    execute(ctx) {
      // console.info("> updateNote id:", ctx.noteId, 'content:', content, 'context:', ctx)
      let note = getNoteById(ctx.noteId)
      let parent = getNoteById(ctx.parentId)
      const prevParent = note.parent
      const prevSnapshot = ctx.snapshot = Snapshot.serializeNote(note)
      const prevParentSnapshot = ctx.parentSnapshot = Snapshot.serializeNote(note.parent)
      const newParentSnapshot = ctx.newParentSnapshot = Snapshot.serializeNote(parent)

      note.parent.removeChildNote(note)
      parent.addChildNote(note, ctx.position)

      App.instance.events.onNoteUpdate.dispatch({ note, prevSnapshot })
      App.instance.events.onNoteUpdate.dispatch({ note: prevParent, prevSnapshot: prevParentSnapshot })
      App.instance.events.onNoteUpdate.dispatch({ note: note.parent, prevSnapshot: newParentSnapshot })

      return note
    },

    revert(ctx) {
      let note = getNoteById(ctx.noteId)
      const parent = note.parent
      const newParentSnapshot = Snapshot.serializeNote(note.parent)
      const prevSnapshot = Snapshot.serializeNote(note)
      const prevParent = getNoteById(ctx.prevParentId)
      const prevParentSnapshot = Snapshot.serializeNote(prevParent)

      note.parent.removeChildNote(note)
      prevParent.addChildNote(note, ctx.prevPosition)

      App.instance.events.onNoteUpdate.dispatch({ note, prevSnapshot })
      App.instance.events.onNoteUpdate.dispatch({ note: parent, prevSnapshot: newParentSnapshot })
      App.instance.events.onNoteUpdate.dispatch({ note: prevParent, prevSnapshot: prevParentSnapshot })
    },

    // shouldCoalesce(ctx, prevCtx) {
    //   return prevCtx.isNewNote && prevCtx.noteId === ctx.noteId && !prevCtx._isCoalesced
    // }
  })
}