import * as CryptoJS from 'crypto-js';
import { observer } from 'mobx-react';
import * as React from 'react';
import Application from '../services/Application';
import Undo from '../services/UndoManager';
import { classNames, css, style } from '../utils/Stylesheet';
import AuthDialog from './AuthDialog';

const logoImgPath = '/icons/NoteTreeIcon_Clear_128.png'

interface AppHeaderState {
}

interface AppHeaderProps {
  layout: 'horizontal' | 'vertical'
}

const isLocal = window.location.host.startsWith('localhost')

export default observer(class AppHeader extends React.Component<AppHeaderProps, AppHeaderState> {
  state = {}

  get app() { return Application.service }
  // get api() { return Application.service.api }
  get selection() { return Application.service.selectionMgr }
  get notes() { return Application.service.noteMgr }

  render() {
    const disableUndo = !Undo.service.canUndo
    const disableRedo = !Undo.service.canRedo

    if (this.props.layout == 'horizontal') {
      return this.renderHorizontalLayout(disableUndo, disableRedo)
    }
    else {
      return this.renderVerticalLayout(disableUndo, disableRedo)
    }
  }

  renderVerticalLayout(disableUndo: boolean, disableRedo: boolean) {
    const treeViewClasses = classNames("pt-icon-standard", {
      'pt-icon-double-chevron-right': !Application.service.treeView.isVisible,
      'pt-icon-double-chevron-left': Application.service.treeView.isVisible
    })
    return (
      <nav className={Styles.AppHeaderVert}>
        <div className="NavLogo" data-tooltip={`NoteTree v${Application.service.meta.version}`} data-tooltip-pos="right">
          <img className={Styles.AppLogo} src={logoImgPath} />
        </div>

        {/* <div className={Styles.StretchSpacer} /> */}

        <div className={Styles.StaticSpacer} />

        <div className="NavBtn" data-disabled={disableUndo} onClick={this.handleUndo} data-tooltip={`Undo (${Undo.service.undoSize})`} data-tooltip-pos="right">
          <span className="pt-icon-standard pt-icon-undo" />
        </div>
        <div className="NavBtn" data-disabled={disableRedo} onClick={this.handleRedo} data-tooltip={`Redo (${Undo.service.redoSize})`} data-tooltip-pos="right">
          <span className="pt-icon-standard pt-icon-redo" />
        </div>

        {/* <div className={Styles.StaticSpacer} /> */}
        <div className={Styles.StretchSpacer} />

        {this.selection.isHoisted &&
          <div title="Unhoist" className="NavBtn" onClick={this.handleUnhoist} data-tooltip="Unhoist" data-tooltip-pos="right">
            <span className="pt-icon-standard pt-icon-rotate-documentX pt-icon-import" />
          </div>
        }
        {!this.selection.isHoisted &&
          <div data-disabled={!this.selection.canHoist} className="NavBtn" onClick={this.handleHoist} data-tooltip="Hoist" data-tooltip-pos="right">
            <span className="pt-icon-standard pt-icon-shareX pt-icon-export" />
          </div>
        }

        <div className="NavBtn" onClick={this.handleExport} data-tooltip="Export..." data-tooltip-pos="right">
          <span className="pt-icon-standard pt-icon-git-repo" />
        </div>

        <div className={Styles.StretchSpacer} />

        <div className="NavBtn" onClick={this.handleTreeViewToggle} data-tooltip="Toggle TreeView" data-tooltip-pos="right">
          <span className={treeViewClasses} />
        </div>


        {/* {this.renderUserChip()}

        {this.api.isAuthenticated &&
          <div data-disabled={this.api.lastServerUpdate >= this.notes.latestUpdate} className="NavBtn" onClick={this.handleSync}>
            <span className="pt-icon-standard pt-icon-social-media" />
          </div>
        } */}


        <div className="NavBtn TrashBin" onClick={this.handleTrashBin} data-tooltip="Trash Bin..." data-tooltip-pos="right">
          <span className="pt-icon-standard pt-icon-trash" />
          {Application.service.noteMgr.trashBin.children.length > 0 &&
            <span className="badge">{Application.service.noteMgr.trashBin.children.length}</span>
          }
        </div>

        {/* <div data-disabled="true" className="NavBtn" onClick={this.handleTreeViewToggle} data-tooltip="Toggle Layout" data-tooltip-pos="right">
          <span className="pt-icon-standard pt-icon-column-layout pt-icon-page-layoutX" />
        </div> */}

        <div className="NavBtn" onClick={this.handleSettings} data-tooltip="Settings..." data-tooltip-pos="right">
          <span className="pt-icon-standard pt-icon-cog" />
        </div>

      </nav>
    )
  }

  renderAppTooltip() {
    return (
      <span>
        Note<span className="thin-text">Tree</span>{' '}
        <span className="thin-text">v{Application.service.meta.version}</span>
      </span>
    )
  }

  // renderUserChip() {
  //   const tooltip = this.api.isAuthenticated
  //     ? `Logged in as ${this.api.userInfo.name}`
  //     : 'Not logged in'
  //   const avatarId = this.api.isAuthenticated
  //     ? CryptoJS.MD5(this.api.userInfo.email).toString()
  //     : 'd41d8cd98f00b204e9800998ecf8427e'

  //   return (
  //     <div className={Styles.UserChip} title={tooltip} onClick={this.handleUserChipClick}>
  //       <img src={`https://www.gravatar.com/avatar/${avatarId}?s=100&d=mm`} width="100" height="100" />
  //     </div>
  //   )
  // }

  renderHorizontalLayout(disableUndo: boolean, disableRedo: boolean) {
    return (
      <nav className={"pt-navbar pt-dark " + Styles.AppHeader}>
        <div>
          <div className="pt-navbar-group pt-align-left">
            <div className="pt-navbar-heading">
              <span className="pt-icon-large pt-icon-predictive-analysis" />{' '}
              Note<span className="thin-text">Tree</span>{' '}
              {this.selection.isHoisted && <span>
                <span className="pt-icon-standard pt-icon-chevron-right" />{' '}
                <span className="pt-icon-standard pt-icon-document" />{' '}
                <span className="thin-text">{this.selection.rootNote.title}</span>{' '}
              </span>
              }
            </div>
          </div>
          <div className="pt-navbar-group pt-align-right">
            {/*<button className="pt-button pt-minimal pt-icon-home">Home</button>
              <button className="pt-button pt-minimal pt-icon-document">Files</button>
              <button className="pt-button pt-minimal pt-icon-user" />*/}

            <button title="Undo" className="pt-button pt-minimal pt-icon-undo" disabled={disableUndo} onClick={this.handleUndo} />
            <button title="Redo" className="pt-button pt-minimal pt-icon-redo" disabled={disableRedo} onClick={this.handleRedo} />

            <span className="pt-navbar-divider" />

            {this.selection.isHoisted &&
              <button title="Unhoist" className="pt-button pt-minimal pt-icon-rotate-document" onClick={this.handleUnhoist} />
            }

            {/*<button title="Preview" className="pt-button pt-minimal pt-icon-eye-open" onClick={this.handlePreview} />*/}
            <button title="Export..." className="pt-button pt-minimal pt-icon-export" onClick={this.handleExport} />

            {/*{this.renderHoistAction()}*/}

            {/*<span className="pt-navbar-divider" />

            <button disabled className="pt-button pt-minimal pt-icon-notifications" />*/}
            {/* <button disabled className="pt-button pt-minimal pt-icon-changesX pt-icon-refreshX pt-icon-social-media" onClick={this.handleSync} /> */}
            <button className="pt-button pt-minimal pt-icon-cog" onClick={this.handleSettings} />
          </div>
        </div>
      </nav>
    )
  }

  renderHoistAction() {
    if (this.selection.isHoisted) {
      return (
        <button title="Hoist" className="pt-button pt-minimal pt-icon-rotate-document" onClick={this.handleUnhoist} />
      )
    }
    if (!this.selection.canHoist) {
      return (
        <button title="Hoist" className="pt-button pt-minimal pt-icon-document-open" disabled onClick={this.handleHoist} />
      )
    }
    return (
      <button title="Unhoist" className="pt-button pt-minimal pt-icon-document-open" onClick={this.handleHoist} />
    )
  }

  handleUndo = () => {
    Undo.service.undo()
  }

  handleRedo = () => {
    Undo.service.redo()
  }

  handleExport = () => {
    Application.service.exportMgr.show()
  }

  // handleSync = () => {
  //   this.api.syncChanges();
  // }

  handleUserChipClick = () => {
    AuthDialog.show()
  }

  handleTreeViewToggle = () => {
    if (Application.service.treeView.isVisible) {
      Application.service.treeView.hide()
    }
    else {
      Application.service.treeView.show()
    }
  }

  handlePreview = () => {
    const app = Application.instance

    app.previewMgr.previewNote(app.noteMgr.rootNote, { contents: 'note-children' })
  }

  handleTrashBin = () => {
    Application.instance.trashbin.show()
  }

  handleSettings = () => {
    Application.service.settings.show()
  }

  handleHoist = () => {
    this.selection.hoistSelected()
  }

  handleUnhoist = () => {
    this.selection.unhoist()
  }

})

//

const Styles = {

  AppLogo: style({
    display: 'inline-block',
    maxWidth: 40,
    margin: '0 auto',
  }),

  AppHeaderVert: style({
    outline: 'none',
    width: 50,
    backgroundColor: 'var(--header-color)',// isLocal ? 'crimson' : '#022c56', //'#094b8c', //
    color: 'var(--header-icon-color)',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '1em',
    textAlign: 'center',
    zIndex: 2,
    boxShadow: '2px 0px 2px var(--shadow-color)',

    'button': {
      height: 50,
    },

    'button *': {
      color: 'white !important',
    },

    '.NavBtn': {
      width: 50,
      height: 50,
      cursor: 'pointer',
      display: 'flex',
      textAlign: 'center',
      position: 'relative',

      '&[disabled], &[data-disabled=true]': {
        opacity: 0.5,
        pointerEvents: 'none',
      },

      'span': {
        flex: 1,
        alignSelf: 'center',
      },

      '.badge': {
        position: 'absolute',
        fontSize: 10,
        bottom: 7,
        right: 7,
        background: 'var(--header-color)',
        border: '1px solid var(--header-icon-color)',
        boxShadow: '0px 0px 0px 2px var(--header-color)',
        borderRadius: 12,
        padding: '2px 4px',
        lineHeight: 1,
        textAlign: 'center',
        // fontFamily: 'monospace',
      },

      '&:hover': {
        background: 'rgba(255,255,255, 0.15)',
        color: 'white',
      },
    },
  }),

  AppIcon: style({
    fontSize: '25px !important',
    textAlign: 'center',
    // marginBottom: 50,
    display: 'block',
    color: 'white',
  }),

  UserChip: style({
    cursor: 'pointer',
    paddingTop: 5,
    '&:hover': {
      background: 'rgba(255,255,255, 0.15)',
    },
    'img': css({
      maxWidth: 35,
      maxHeight: 35,
      borderRadius: '100%',
    })
  }),

  StaticSpacer: style({
    // height: 25,
    height: 50,
  }),

  StretchSpacer: style({
    flex: 1
  }),

  AppHeader: style({
    backgroundColor: 'var(--header-color)!important'
  }),
}
