import Snapshot, { INoteSnapshot } from '../../utils/Snapshot'
import { Note } from '../../services/NoteManager'
import App from '../../services/Application'
import * as commands from '../index'

// TODO: Move selection stuff to event handlers


export function deleteNote(note: Note, options: { updateSelection?: boolean } = {}): Note {
  return App.instance.undoMgr.executeCommand({
    context: {
      originalParentNoteId: note.parent.id,
      originalParentNoteTitle: note.parent.title,
      // note,
      snapshot: {} as INoteSnapshot,
      parentSnapshot: {} as INoteSnapshot,
      noteId: note.id,
      prevIndex: note.parent.indexOf(note),
      prevContent: note.content,
      isDeleteCommand: true
    },

    execute(ctx) {
      const note = App.instance.noteMgr.getByIdOrThrow(ctx.noteId)
      const snapshot = ctx.snapshot = Snapshot.serializeNote(note)

      const parent = note.parent
      const parentSnapshot = ctx.parentSnapshot = Snapshot.serializeNote(parent)

      if (options.updateSelection != false || App.service.selectionMgr.selectedNote == note)
        App.instance.selectionMgr.selectNearest(note)

      note.parent.removeChildNote(note)
      note.setMetadata({
        trash: {
          date: Date.now(),
          parentId: ctx.originalParentNoteId,
          parentTitle: ctx.originalParentNoteTitle,
          position: ctx.prevIndex
        }
      })

      App.instance.noteMgr.trashBin.addChildNote(note, 'top')
      App.instance.events.onNoteDelete.dispatch({ note, snapshot })
      App.instance.events.onNoteUpdate.dispatch({ note: parent, prevSnapshot: parentSnapshot })

      return note
    },

    revert(ctx) {
      const parent = App.instance.noteMgr.getByIdOrThrow(ctx.originalParentNoteId)
      const parentSnapshot = Snapshot.serializeNote(parent)
      const note = Snapshot.deserializeNote(ctx.snapshot)

      App.instance.noteMgr.trashBin.removeChildNote(note.id)
      note.removeMetadata('trash')
      parent.addChildNote(note, ctx.prevIndex)

      App.instance.events.onNoteCreate.dispatch({ note })
      App.events.onNoteUpdate.dispatch({ note: parent, prevSnapshot: parentSnapshot })
      App.instance.selectionMgr.select(note)
    }
  })
}


export function deleteNotePermanently(note: Note, options: { updateSelection?: boolean } = {}): Note {
  return App.instance.undoMgr.executeCommand({
    context: {
      parentNoteId: note.parent.id,
      // note,
      snapshot: {} as INoteSnapshot,
      noteId: note.id,
      prevIndex: note.parent.indexOf(note),
      prevContent: note.content,
      isDeleteCommand: true
    },

    execute(ctx) {
      // console.info("> deleteNote id:", newNoteId, 'content:', content, 'context:', ctx)
      const note = App.instance.noteMgr.getByIdOrThrow(ctx.noteId)
      const snapshot = ctx.snapshot = Snapshot.serializeNote(note)

      if (options.updateSelection != false || App.service.selectionMgr.selectedNote == note)
        App.instance.selectionMgr.selectNearest(note)

      note.parent.removeChildNote(note)



      App.instance.events.onNoteDelete.dispatch({ note, snapshot })


      return note
    },

    revert(ctx) {
      const parent = App.instance.noteMgr.getByIdOrThrow(ctx.parentNoteId)
      const note = Snapshot.deserializeNote(ctx.snapshot)
      parent.addChildNote(note, ctx.prevIndex)
      // const note = parent.addChildNote(ctx.prevContent, ctx.prevIndex, ctx.noteId)

      App.instance.events.onNoteCreate.dispatch({ note })

      App.instance.selectionMgr.select(note)
    }
  })
}

