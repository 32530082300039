import * as React from 'react'
import { Button, Intent } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { Row, Spacer } from '../Grid';
import { logoImgPath } from './SettingsDialog';

export const DonateTab = observer(() => {
  const size = 256;
  return (
    <div>
      <p style={{ textAlign: 'center' }}><img style={{ width: size, height: size }} src={logoImgPath} /></p>
      <p>
        Soon I'll have a patreon set up, but until then you can always send me a few
        bucks via PayPal!
        </p>
      <p>&nbsp;</p>
      <Row>
        <Spacer />
        <Button intent={Intent.PRIMARY} text="Add To The Tip Jar!" onClick={handleTipClick} />
      </Row>
    </div>
  );
});

const handleTipClick = () => {
  window.open('https://www.paypal.me/mattmccray', '_newWindow');
};
