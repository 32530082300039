import { Button } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import * as React from 'react';
import scrollIntoView from 'scroll-into-view';
import { Application } from '../services/Application';
import { Note } from '../services/NoteManager';
import { cancelEvent } from '../utils/FunctionTools';
import { classNames, style } from '../utils/Stylesheet';
import Card from './Card';

//

interface CardListProps {
  note: Note
  viewport: ClientRect
}

class CardList extends React.Component<CardListProps, {}> {
  assign = {
    _root: (c: HTMLElement | any) => this._root = c
  }
  _root!: HTMLElement

  render() {
    const notes = this.props.note.children

    return (
      <div className={classNames('Column', Styles.Column, { hasNoChildren: notes.length === 0 })} ref={this.assign._root}>
        <div className="cardList">
          {this.renderCards(notes)}
          {/*<div className="cardListAction">
            <AnchorButton
              className="pt-fill pt-minimal"
              iconName={IconClasses.INSERT}
              onClick={this.handleCreateLastSibling}
              title="New Note"
            />
          </div>*/}
          <div className={Styles.ColumnSpacer} />
        </div>
        {/* <div className="cardListFooter">
          {notes.length} Notes.
        </div> */}
      </div>
    )
  }

  renderCards(notes: Note[]) {
    if (notes.length === 0) {
      // return null
      const isRootEmpty = (
        this.props.note == Application.service.noteMgr.rootNote ||
        this.props.note == Application.service.selectionMgr.rootNote
      )
      return (
        <div className={Styles.NothingToSeeHere}>
          {isRootEmpty &&
            <Button text="Add Note" iconName="plus" className="pt-minimal pt-fill pt-small" onClick={this.handleCreateNote} />
          }
          {/* <NonIdealState
            title="No Child Notes"
            visual={IconClasses.DOCUMENT}
          /> */}
        </div>
      )
    }
    else {
      return notes.map(this.renderCard)
    }
  }

  renderCard = (note: Note, idx: number) => {
    return (
      <Card
        key={note.id}
        viewport={this.props.viewport}
        note={note}
        requestScrollIntoView={this.handleScrollIntoView}
      />
    )
  }

  handleCreateNote = (e: any) => {
    cancelEvent(e)
    Application.actions.createChild(e, this.props.note)
  }

  handleScrollIntoView = (node: HTMLElement, alignTop: boolean, alignLeft?: boolean) => {
    const align = {
      // top: alignTop ? 1 : 0,
      // left: alignLeft ? 1 : 0
      top: alignTop ? 0 : 1,
      left: 0, //alignLeft ? 0 : 1
      leftOffset: 50
    }
    scrollIntoView(node, {
      time: 200,
      align
    })

    // if (!node || !this._root) {
    //   console.warn("handleScrollIntoView() node/root was null", node)
    // }
    // else {
    //   this._root.scrollIntoView(alignTop)
    //   node.scrollIntoView(alignTop)
    //   console.info("Scrolling view!", align)
    // }
  }

  // handleCreateChild = () => {
  //   const currNote = this.props.note
  //   const note = commands.createNote(currNote)// currNote.addChildNote(NEW_NOTE_CONTENT)
  //   Selection.service.select(note)
  //   Selection.service.enterEditMode()
  // }
  // // handleCreateSibling = () => {
  // //   const currNote = Selection.service.selectedNote
  // //   const currIdx = currNote.parent.indexOf(currNote)
  // //   const newNote = currNote.parent.addChildNote(NEW_NOTE_CONTENT, currIdx + 1)
  // //   Selection.service.select(newNote)
  // // }
  // handleCreateLastSibling = () => {
  //   const currNote = this.props.note
  //   // const newNote = currNote.parent.addChildNote(NEW_NOTE_CONTENT)
  //   const note = commands.createNote(currNote) // currNote.addChildNote(NEW_NOTE_CONTENT)
  //   Selection.service.select(note)
  //   Selection.service.enterEditMode()
  // }

}

export default observer(CardList)

// export const NARROW_COLUMN_WIDTH = '30em'
export const COLUMN_WIDTH = '40em'
// export const WIDE_COLUMN_WIDTH = '50em'

const Styles = {

  Column: style({
    width: COLUMN_WIDTH,
    minWidth: COLUMN_WIDTH,
    // borderRight: '1px solid gainsboro',
    // borderRight: '1px dotted var(--border-color)',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingTop: '0.5em',

    '&:last-child': {
      borderRight: 0
    },

    '.cardListAction': {
      padding: '0.5em 1em',
    },

    '&.hasNoChildren': {
      // width: '65px !important',
      // minWidth: '65px !important',
      // maxWidth: '65px !important'
    },

    ".cardListFooter": {
      position: 'fixed',
      bottom: 0
    }
  }),

  ColumnSpacer: style({
    // height: '45vh'
    height: '25px'
  }),

  NothingToSeeHere: style({
    padding: '2em',
    opacity: '0.5',
  }),
}
