import { Note, NEW_NOTE_CONTENT } from "../../services/NoteManager/Note";
import { Application } from "../../services/Application";
import * as commands from "./index";
import { createMarkdownWithFM } from "../../services/NoteManager";

export type SplitType = 'bullet' | 'selection' | 'hrule'

export interface SplitSelectionContext {
  contentBefore: string
  contentSelected: string
  contentAfter: string
}

export function splitNote(note: Note, type: SplitType = 'bullet', selectionContext?: SplitSelectionContext) {
  return Application.service.undoMgr.inTransaction(() => {
    if (type == 'bullet') {
      return splitOnBullets(note);
    }
    else if (type == 'hrule') {
      return splitOnHRule(note)
    }
    else {
      if (!!selectionContext) {
        return splitOnSelection(note, selectionContext)
      }
      else {
        console.warn("No split context provided!")
        return note
      }
    }
  })
}


type ListSegment = { content: string, depth: number }

const bulletRE = /^([\s]*)?[\-|\*]{1}[\s]+/i //m

function splitOnBullets(note: Note) {
  const source = note.contentMarkdown;
  const notes: ListSegment[] = [];

  let inBullet = false;
  let content: string[] = [];
  let lastBulletDepth = 0;

  const updateNotes = () => {
    if (content.length > 0) {
      // console.log("+ depth:", lastBulletDepth, 'note:', content.join('\n'))
      const source = content.join('\n').trim().replace(bulletRE, '')
      if (source != "") {
        notes.push({
          depth: lastBulletDepth,
          content: source
        })
      }
    }
  }

  source.split('\n').forEach(line => {
    const [bulletFrag, indentFrag = ''] = line.match(bulletRE) || ['', ''];

    if (bulletFrag != '') {
      updateNotes()
      content = [];
      inBullet = true;
      lastBulletDepth = indentFrag.length
    }
    else if (inBullet) {
      inBullet = false;
      updateNotes()
      content = [];
    }

    content.push(line);
  });

  if (!inBullet) lastBulletDepth = 0 //?

  updateNotes()

  if (notes.length > 1) {
    console.log("SPLIT NOTES:", { notes: [...notes] });

    let newSource = '';
    while (newSource.trim() == '') {
      newSource = notes.shift()!.content
    }

    commands.updateNoteContent(note, newSource, { mergeFrontMatter: true });

    let newNote: Note = note

    while (notes.length > 0) {
      newNote = createNestedNotesFromList({
        sourceNote: newNote,
        parent: note.parent,
        forDepth: 0,
        position: newNote.position + 1,
        list: notes
      })
    }
  }
  else {
    console.log("No split performed.");
  }

  return note
}


interface Opts {
  parent: Note
  sourceNote: Note
  forDepth: number
  position: number
  list: ListSegment[]
}


function createNestedNotesFromList({ parent, forDepth, position, sourceNote, list }: Opts): Note {
  if (list.length == 0) return sourceNote
  const targetItemDepth = list[0].depth

  if (targetItemDepth == forDepth) {
    // console.log({ parent, forDepth, position, sourceNote, list })

    const { depth, content } = list.shift()!

    let newNote = commands.createNote(parent, createMarkdownWithFM(content, sourceNote.metadata), position);

    newNote = createNestedNotesFromList({
      position: position + 1,
      sourceNote: newNote,
      parent, forDepth, list
    })

    return newNote
  }
  else if (targetItemDepth > forDepth) {
    createNestedNotesFromList({
      parent: sourceNote,
      position: 0,
      forDepth: targetItemDepth,
      list, sourceNote
    })

    // Continue on?
    return createNestedNotesFromList({
      parent, position, forDepth, list, sourceNote
    })
  }

  return sourceNote
}


const hRuleRE = /^[\n\n][---][-|\s]+/im

function splitOnHRule(note: Note) {
  const source = note.contentMarkdown;
  const notes: string[] = source.split(hRuleRE).filter(note => note.trim() != "");

  if (notes.length > 1) {
    console.log("SPLIT NOTE:", { notes });

    let newNote = note;
    const firstNoteContent = notes.shift()!

    commands.updateNoteContent(note, firstNoteContent, { mergeFrontMatter: true })

    notes.forEach(content => {
      newNote = commands.createNote(note.parent, createMarkdownWithFM(content, note.metadata), newNote.position + 1);
    });
  }
  else {
    console.log("No split performed.");
  }

  return note
}


function splitOnSelection(note: Note, context: SplitSelectionContext) {
  return Application.service.undoMgr.inTransaction(() => {
    let { contentBefore, contentAfter, contentSelected } = context

    let returnNote = note

    if (contentBefore == "") contentBefore = NEW_NOTE_CONTENT
    if (contentAfter == "") contentAfter = NEW_NOTE_CONTENT

    commands.updateNoteContent(note, contentBefore, { mergeFrontMatter: true })

    if (contentSelected != "") {
      const newNote = commands.createNote(note.parent, createMarkdownWithFM(contentSelected, note.metadata), note.position + 1)
      const lastNote = commands.createNote(note.parent, createMarkdownWithFM(contentAfter, note.metadata), newNote.position + 1)

      if (contentBefore != NEW_NOTE_CONTENT) {
        returnNote = newNote
      }
    }
    else {
      const newNote = commands.createNote(note.parent, createMarkdownWithFM(contentAfter, note.metadata), note.position + 1)

      if (contentBefore != NEW_NOTE_CONTENT) {
        returnNote = newNote
      }
    }

    return returnNote
  })
}
