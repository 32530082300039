import { action, decorate, observable } from 'mobx';
import { Session } from '../utils/Session';


export class TreeViewManager {
  private session = new Session('ui')

  whenReady = Promise.resolve(this)

  isVisible = false

  show() {
    this.isVisible = true
    this.session.set('showTreeView', true)
  }
  hide() {
    this.isVisible = false
    this.session.set('showTreeView', false)
  }

  constructor() {
    this.isVisible = this.session.get('showTreeView', false)
  }

  //

  static get service() { return this._instance || (this._instance = new this()) }
  private static _instance: TreeViewManager
}

decorate(TreeViewManager, {
  isVisible: observable,

  show: action,
  hide: action,
})

export default TreeViewManager