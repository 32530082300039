import { computed, decorate } from 'mobx';
import * as actions from '../actions';
import Application from './Application';

const createNavigationKeys = (up: string, right: string, down: string, left: string) => {
  return [
    { combo: `${up}`, onKeyDown: actions.selectPrev, label: "Select Previous Card" },
    { combo: `mod+${up}`, onKeyDown: actions.selectTop, label: "Select Top Card" },
    { combo: `alt+${up}`, onKeyDown: actions.selectPrev, label: "Create and Select Previous Card" },
    { combo: `shift+${up}`, onKeyDown: actions.moveUp, label: "Move Note Up" },
    { combo: `alt+shift+${up}`, onKeyDown: actions.mergeUp, label: "Merge Note Up" },

    { combo: `${down}`, onKeyDown: actions.selectNext, label: "Select Next Card" },
    { combo: `mod+${down}`, onKeyDown: actions.selectLast, label: "Select Last Card" },
    { combo: `alt+${down}`, onKeyDown: actions.selectNext, label: "Create and Select Next Card" },
    { combo: `shift+${down}`, onKeyDown: actions.moveDown, label: "Move Note Down" },
    { combo: `alt+shift+${down}`, onKeyDown: actions.mergeDown, label: "Merge Note Down" },

    { combo: `${right}`, onKeyDown: actions.selectChild, label: "Select Child Card" },
    { combo: `mod+${right}`, onKeyDown: actions.selectBottomLeaf, label: "Select Leaf Card" },
    { combo: `alt+${right}`, onKeyDown: actions.selectChild, label: "Create and Select Child Card" },

    { combo: `${left}`, onKeyDown: actions.selectParent, label: "Select Parent Card" },
    { combo: `mod+${left}`, onKeyDown: actions.selectTopmost, label: "Select Topmost Parent Card" },
  ]
}

export class Hotkeys {
  whenReady = Promise.resolve(this)

  navigationHotKeyProps = createNavigationKeys('up', 'right', 'down', 'left')
  vimNavigationHotKeyProps = createNavigationKeys('j', 'l', 'k', 'h')
  wasdNavigationHotKeyProps = createNavigationKeys('w', 'd', 's', 'a')

  get globalHotKeyProps() {
    const vimKeys = Application.service.UI.useVimNavKeys ? this.vimNavigationHotKeyProps : []
    const wasdKeys = Application.service.UI.useWASDNavKeys ? this.wasdNavigationHotKeyProps : []
    return [
      ...this.navigationHotKeyProps,
      ...wasdKeys,
      ...vimKeys,

      { combo: "mod+enter", onKeyDown: actions.enterEditMode, label: "Enter Edit Mode" },
      { combo: "alt+enter", onKeyDown: actions.enterEditMode, label: "Enter Edit Mode" },
      { combo: "enter", onKeyDown: actions.enterEditMode, label: "Enter Edit Mode" },

      { combo: "mod+shift+p", onKeyDown: actions.showPreview, label: "Preview Note" },

      { combo: "mod+backspace", onKeyDown: actions.deleteNote, label: "Delete Card" },

      { combo: "shift+mod+backspace", onKeyDown: actions.dissolveNote, label: "Dissolve Card" },

      { combo: "=", onKeyDown: actions.hoist, label: "Hoist Current Note" },
      { combo: "-", onKeyDown: actions.unhoist, label: "Unhoist view" },
      { combo: "mod+z", onKeyDown: actions.undo, label: "Undo" },
      { combo: "mod+shift+z", onKeyDown: actions.redo, label: "Redo" },
      { combo: "mod+c", onKeyDown: actions.copy, label: "Copy Note" },
      { combo: "mod+v", onKeyDown: actions.paste, label: "Paste Note" },
      { combo: "mod+shift+v", onKeyDown: actions.paste, label: "Paste Note as Child" },
      { combo: "mod+x", onKeyDown: actions.cut, label: "Cut Note" },
      { combo: "mod+,", onKeyDown: actions.showSettings, label: "Show Settings" },

      { combo: "mod+left", onKeyDown: actions.doNothing, label: "(Prevents accidental page navigation)" },
      { combo: "tabbing", onKeyDown: actions.doNothing, label: "(Prevents tabbing)" },

      { combo: "mod+d", onKeyDown: actions.toggleTheme, label: "Toggle Dark Mode UI" },
      { combo: "alt+t", onKeyDown: actions.toggleTree, label: "Toggle Tree View" },

      // { combo: "mod+t", onKeyDown: this.handleToggleTree, label: "Toggle Tree View" },
    ]
  }

  // SINGLETON: 

  static get instance(): Hotkeys {
    return this.service
  }
  static get service() { return this._instance || (this._instance = new this()) }
  private static _instance: Hotkeys

}

decorate(Hotkeys, {
  globalHotKeyProps: computed
})

export default Hotkeys