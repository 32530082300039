export type SessionType = 'local' | 'session'

export class Session {
  prefix: string
  type: SessionType

  private storage: Storage

  constructor(prefix: string = 'app', type: SessionType = 'local') {
    this.prefix = prefix
    this.type = type
    this.storage = this.type === 'local'
      ? window.localStorage
      : window.sessionStorage
  }

  get<T>(key: string, defaultValue?: T): any {
    let item = this.storage.getItem(
      this.getFullKey(key)
    )

    if (item === null) {
      return defaultValue
    }
    else {
      return JSON.parse(item)
    }
  }

  set(key: string, value: any): void {
    this.storage.setItem(
      this.getFullKey(key),
      JSON.stringify(value)
    )
  }

  remove(key: string): void {
    this.storage.removeItem(
      this.getFullKey(key)
    )
  }

  has(key: string): boolean {
    let fullKey = key.startsWith(`${this.prefix}.`)
      ? key
      : this.getFullKey(key)
    return this.storage.hasOwnProperty(fullKey)
    // return this.storage.hasOwnProperty(key)
  }

  clear() {
    this.keys.forEach(key => this.remove(key))
  }

  get values(): any[] {
    return this.keys.map(key => this.get(key))
  }

  get keys(): string[] {
    let keys: string[] = []

    for (let i = 0; i < this.storage.length; i++) {
      let keyName = this.storage.key(i)

      if (keyName && keyName.startsWith(`${this.prefix}.`)) {
        keys.push(
          this.getLocalKey(keyName)
        )
      }
    }

    return keys
  }

  asObject(): any {
    return this.keys.reduce((data: any, key: string) => {
      data[key] = this.get(key)
      return data
    }, {})
  }

  private getFullKey(key: string): string {
    return `${this.prefix}.${key}`
  }

  private getLocalKey(key: string): string {
    let parts: string[] = key.split('.')
    parts.shift()
    return parts.join('.')
  }
}

export default Session

  ; (window as any)['Session'] = Session