import { action, computed, decorate, observable } from 'mobx';
import { IAggregationType, Note } from './NoteManager';
import Selection from './SelectionManager';

interface IExportOptions {
  source: 'root' | 'selected'
  output: IAggregationType
  insertText: string
  format: 'html' | 'markdown' | 'epub'
}

export class ExportManager {
  whenReady = Promise.resolve(this)
  isVisible = false
  target!: Note
  options: IExportOptions = {
    source: 'selected',
    output: 'note-children',
    insertText: '\n<br>',
    format: 'html'
  }

  get exportPreviewContent() {
    console.log("Generating PREVIEW!")
    const source = this.options.source === 'root'
      ? Selection.service.rootNote
      : Selection.service.selectedNote
    const content = source.allContent({
      contents: this.options.output,
      insertTextBetweenNotes: `\n\n${this.options.insertText}\n\n`,
      format: this.options.format // 'html' | 'markdown'
    })
    return content
  }


  show(note = Selection.service.rootNote) {
    this.target = note
    Selection.service.setEnabled(false)
    this.isVisible = true
  }

  hide() {
    Selection.service.setEnabled(true)
    this.isVisible = false
  }


  // Singleton Support

  static get service() { return this._instance || (this._instance = new this()) }
  private static _instance: ExportManager
}

decorate(ExportManager, {
  isVisible: observable,
  target: observable,
  options: observable,

  exportPreviewContent: computed,

  show: action,
  hide: action
})

export default ExportManager