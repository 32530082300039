import * as React from 'react'
import { Button, IconClasses, Intent, Radio, RadioGroup, Switch, FileUpload } from '@blueprintjs/core';
import { action, decorate, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import Application from '../../services/Application';
import { IBackupData } from '../../services/BackupManager';
import { Note } from '../../services/NoteManager';
import { sleep } from '../../utils/Async';
import Snapshot from '../../utils/Snapshot';
import { Row, Spacer } from '../Grid';

export const BackupTab = observer(class BackupTab extends React.Component {
  isImporting = false;
  importJSON = "";
  importData: IBackupData | null = null
  mergeType = "add";
  isDragOver = false;
  isBuilding = false;
  includeTrash = false;
  filenameToImport: string = "Choose NoteTree file...";
  fileToImport: File | null = null;
  fileToImportIsValid: 'no-file' | 'validating' | 'invalid' | 'valid' = 'no-file';
  _json!: HTMLTextAreaElement;

  assign = {
    _json: (c: any) => this._json = c,
    importJSON: action((e: any) => this.importJSON = e.currentTarget.value),
    mergeType: action((e: any) => this.mergeType = e.currentTarget.value),
    includeTrash: action((e: any) => this.includeTrash = e.currentTarget.checked),
    fileToImport: action((e: any) => {
      if (e.currentTarget.files.length > 0) {
        this.fileToImportIsValid = 'validating';
        // console.log("> File input change:", e.currentTarget.files[0])
        this.fileToImport = e.currentTarget.files[0];
        this.filenameToImport = this.fileToImport!.name;
        this.validateFile(this.fileToImport!).catch(err => {
          console.warn("Validation error:", err);
          runInAction(() => this.fileToImportIsValid = 'invalid');
        });
      }
      else {
        // console.log(">! No file selected.")
        this.filenameToImport = "Choose NoteTree file...";
        this.fileToImport = null;
      }
    }),
  };

  async validateFile(file: File) {
    const content = await loadFileText(file);
    const importData: IBackupData = JSON.parse(content);
    const isValidData = (
      importData &&
      importData.version &&
      typeof importData.version == 'string' &&
      importData.version.startsWith('0.1') &&
      'snapshot' in importData
    );

    if (!isValidData) {
      throw new Error("Invalid JSON.");
    }

    runInAction(() => {
      this.fileToImportIsValid = 'valid';
      this.importData = importData
    });
  }

  render() {
    let importIntent = Intent.PRIMARY;

    if (this.mergeType === 'merge') {
      importIntent = Intent.WARNING;
    }
    else if (this.mergeType === 'replace') {
      importIntent = Intent.DANGER;
    }

    return (
      <div>
        <h5>Backup</h5>
        <Row>
          <Switch label="Include trash bin in backup data." checked={this.includeTrash} onChange={this.assign.includeTrash} disabled={this.isBuilding} />
          <Spacer />
          <Button loading={this.isBuilding} iconName={IconClasses.DOWNLOAD} text="Download" onClick={this.handleDownload} intent={Intent.PRIMARY} />
        </Row>

        <hr />

        <h5>Restore</h5>

        <label className="pt-file-uploadX">
          <FileUpload inputProps={{
            accept: "application/json,.json,.notetree",
          }} text={this.filenameToImport} onInputChange={this.assign.fileToImport} fill />
        </label>
        <br /><br />
        {this.fileToImportIsValid == 'invalid' &&
          <React.Fragment>
            <div className="pt-callout pt-intent-danger">
              <h5 className="pt-callout-title">Invalid Backup File</h5>
              Please select a valid NoteTree backup file.
              </div>
            <br /><br />
          </React.Fragment>}
        <Row>
          <Row>
            <label>
              Merge Options: &nbsp;
          </label>
            <RadioGroup disabled={this.fileToImportIsValid != 'valid'} onChange={this.assign.mergeType} selectedValue={this.mergeType}>
              <Radio className="pt-inline" label="Add" value="add" />
              <Radio className="pt-inline" label="Merge" value="merge" disabled />
              <Radio className="pt-inline" label="Replace" value="replace" />
            </RadioGroup>
          </Row>

          <Spacer />

          <Button loading={this.isImporting} iconName={IconClasses.IMPORT} text="Import" onClick={this.handleImport} disabled={this.fileToImportIsValid != 'valid'} intent={importIntent} />

        </Row>

      </div>
    );
  }
  handleDownload = async () => {
    runInAction(() => this.isBuilding = true);
    await Application.service.backup.downloadAsFile({
      includeTrash: this.includeTrash
    });
    runInAction(() => this.isBuilding = false);
  };

  handleImport = action(() => {
    this.isImporting = true;

    const starter = this.mergeType === 'replace'
      ? Application.service.confirmation.show({
        body: "This could/will result in data loss. Are you sure?",
        yesLabel: "Yes, Continue Import",
        noLabel: "Cancel"
      })
      : Promise.resolve(true);

    starter
      .then(confirmed => {
        if (!confirmed) {
          throw new Error("Cancel import.");
        }
      })
      .then(() => this.doImport())
      .then((noteTree: Note) => {
        console.info("• Imported:", noteTree);
        Application.service.notifications.show({
          iconName: IconClasses.INFO_SIGN,
          intent: Intent.PRIMARY,
          message: `Import Complete`
        });
      })
      .catch(err => {
        Application.service.notifications.show({
          iconName: IconClasses.ERROR,
          intent: Intent.DANGER,
          message: `Import Error: ${err.message}`
        });
      })
      .then(() => sleep(500))
      .then(action(() => {
        this.isImporting = false;
      }));
  });

  // handleImportNew = action(async () => {
  //   this.isImporting = true;

  //   if (this.mergeType === 'replace') {
  //     const confirmed = await Application.service.confirmation.show({
  //       body: "This could/will result in data loss. Are you sure?",
  //       yesLabel: "Yes, Continue Import",
  //       noLabel: "Cancel"
  //     });
  //     if (!confirmed) {
  //       throw new Error("Cancel import.");
  //     }
  //   }

  //   try {
  //     const noteTree = await this.doImport();
  //     console.info("• Imported:", noteTree);
  //     Application.service.notifications.show({
  //       iconName: IconClasses.INFO_SIGN,
  //       intent: Intent.PRIMARY,
  //       message: `Import Complete`
  //     });
  //   }
  //   catch (err) {
  //     Application.service.notifications.show({
  //       iconName: IconClasses.ERROR,
  //       intent: Intent.DANGER,
  //       message: `Import Error: ${err.message}`
  //     });
  //   }

  //   await sleep(500);
  //   this.isImporting = false;
  // });

  private doImport = action(async () => {
    const importData = this.importData //: IBackupData = JSON.parse(this.importJSON);
    if (importData == null) throw new Error("New data to import.")

    const isValidData = (importData &&
      importData.version &&
      typeof importData.version == 'string' &&
      importData.version.startsWith('0.1') &&
      'snapshot' in importData);

    if (!isValidData) {
      throw new Error("Invalid JSON.");
    }

    const noteTree = Snapshot.deserializeNote(importData.snapshot, this.mergeType === 'add');

    console.log("  Import Data:", importData);
    console.log("    Regen IDs:", this.mergeType === 'add');
    console.log("   Merge Type:", this.mergeType);
    console.log("     New Root:", noteTree);

    if (this.mergeType === 'add') {
      console.info("ADD");
      Application.service.noteMgr.rootNote.addChildNote(noteTree);
      Application.service.selectionMgr.forceSelectionChain(Application.service.selectionMgr.rootNote, noteTree);
      Application.service.noteMgr.commands.updateNoteContent(noteTree, `# Import ${new Date(importData.exported).toLocaleDateString()}`);
    }
    else if (this.mergeType === 'replace') {
      console.info("REPLACE");
      Application.service.noteMgr.rootNote = noteTree;
      Application.service.selectionMgr.forceSelectionChain(noteTree, noteTree.children[0]);
      Application.service.noteMgr.commands.updateNoteContent(noteTree, `# NoteTree`, { force: true });
    }
    else {
      throw new Error("Merge type not supported: " + this.mergeType);
    }

    return noteTree;
  });
});

decorate(BackupTab, {
  isImporting: observable,
  importJSON: observable,
  mergeType: observable,
  isDragOver: observable,
  includeTrash: observable,
  isBuilding: observable,
  fileToImport: observable,
  filenameToImport: observable,
  fileToImportIsValid: observable,
});

export function loadFileText(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function (e: any) {
      resolve(e.target.result as any)
    }
    reader.onerror = function (err) {
      reject(err)
    }
    reader.readAsText(file)
  })
}

