import { action, computed, decorate, observable } from 'mobx'; //, toJS
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { COLUMN_WIDTH } from '../components/CardList';
import { sleep } from '../utils/Async';
import { Session } from '../utils/Session';
import Events from './EventManager';
import { Note } from './NoteManager';
import Selection from './SelectionManager';
import debounce from '../utils/debounce';

createColumnMeasure()

export class UIManager {

  private session = new Session('ui')

  whenReady: Promise<UIManager>

  private _scrollTarget!: HTMLDivElement
  private noteTreeWidth = 0
  private appHeaderSize = 50

  useDarkMode = false
  useCodemirror = true
  useWASDNavKeys = true
  useVimNavKeys = false
  showNoteTree = true
  shouldConfirmDeletes = false
  editorFont = 'IBM Plex Mono'

  editorFontSize = 14
  noteTheme = 'note-theme-sans-serif'


  viewportWidth = document.body.getBoundingClientRect().width
  columnWidth = columnMeasure.getBoundingClientRect().width

  get spacerColumnWidth() {
    const width = this.columnWidth < this.viewportWidth
      ? this.viewportWidth - this.columnWidth //- 50
      : 0
    return Math.max(width, this.columnWidth)
    // return this.columnWidth
  }

  get hasScrollTarget() {
    return !!this._scrollTarget
  }

  constructor() {
    this.whenReady = this.initialize()
    this.useDarkMode = this.session.get('darkMode', false)
    this.useCodemirror = this.session.get('useCodemirror', true)
    this.shouldConfirmDeletes = this.session.get('shouldConfirmDeletes', false)

    this.editorFont = this.session.get('editorFont', 'IBM Plex Mono')
    this.editorFontSize = this.session.get('editorFontSize', 13)

    this.applyDarkMode()
  }


  setEditorFont(font: string) {
    this.editorFont = font
    this.session.set('editorFont', font)

  }
  setEditorFontSize(fontSize: string | number) {
    this.editorFontSize = typeof fontSize == 'string'
      ? parseInt(fontSize)
      : fontSize
    this.session.set('editorFontSize', fontSize)
  }

  _calcAllDimensions() {
    this._assignViewportWidth()
    this._assignColumnWidth()
    this.logValues()
  }

  _assignViewportWidth() {
    this.viewportWidth = document.body.getBoundingClientRect().width
  }

  _assignColumnWidth() {
    this.columnWidth = columnMeasure.getBoundingClientRect().width
  }

  setDarkMode(darkMode: boolean) {
    this.useDarkMode = darkMode
    this.session.set('darkMode', darkMode)
    this.applyDarkMode()
  }

  toggleDarkMode() {
    this.setDarkMode(!this.useDarkMode)
  }

  enableCodemirror(codemirror: boolean) {
    this.useCodemirror = codemirror
    this.session.set('useCodemirror', codemirror)
  }

  toggleCodemirror() {
    this.enableCodemirror(!this.useCodemirror)
  }

  enableDeleteConfirmation(shouldConfirmDeletes: boolean) {
    this.shouldConfirmDeletes = shouldConfirmDeletes
    this.session.set('shouldConfirmDeletes', shouldConfirmDeletes)
  }

  toggleDeleteConfirmation() {
    this.enableDeleteConfirmation(!this.shouldConfirmDeletes)
  }

  private logValues = () => {
    // console.debug('UI =', toJS(this))
  }

  private async initialize() {
    this.logValues()

    window.addEventListener('resize', () => {
      this._calcAllDimensions()
    })

    Events.instance.onSelectionChanged.on(e => {
      this.handleScrollSelectedIntoView()
    })

    await sleep(100)
    this._calcAllDimensions()

    // ; (window as any)['uiManager'] = this

    return this
  }

  private applyDarkMode() {
    if (this.useDarkMode) {
      document.body.classList.remove('theme-light')
      document.body.classList.add('theme-dark', 'pt-dark')
    }
    else {
      document.body.classList.add('theme-light')
      document.body.classList.remove('theme-dark', 'pt-dark')
    }
  }

  handleScrollSelectedIntoView = debounce(() => {
    const note = Selection.service.selectedNote

    if (!note) {
      return console.warn("No selection to scroll to!")
    }

    const elem = document.querySelector(`.Card[data-note-id=n${note.id}]`)

    if (elem) {
      this.alignScrollTarget()
      scrollIntoViewIfNeeded(elem, false, {
        // scrollIntoViewIfNeeded(elem, {
        // scrollMode: 'if-needed',
        // behavior: 'smooth',
        centerIfNeeded: false,
        duration: 200
      })
      scrollIntoViewIfNeeded(this._scrollTarget, false, {
        // scrollMode: 'if-needed',
        // behavior: 'smooth',
        centerIfNeeded: false,
        duration: 200
      })
      // console.info("Selection was changed, scrolling if needed:", note.title)
    }
  }, { defer: 0 })

  assignNoteTreeElement = (source: HTMLDivElement | null) => {
    if (source) {
      this.noteTreeWidth = source.getBoundingClientRect().width
    }
    else {
      this.noteTreeWidth = 0
    }
  }

  assignScrollTarget(scrollTarget: HTMLDivElement) {
    if (this._scrollTarget === scrollTarget) {
      return
    }


    this._scrollTarget = scrollTarget

    Object.assign(this._scrollTarget.style, {
      'position': 'absolute',
      'min-height': '25px',
      'min-width': '25px',
      // 'background-color': 'rgba(255,0,0, 0.3)',
      'background-color': 'transparent',
      'z-index': '-1',
      'pointer-events': 'none'
    })

    this.alignScrollTarget()
  }

  private alignScrollTarget(note: Note = Selection.service.selectedNote) {
    if (!note) {
      return console.warn("No selection to scroll to!")
    }

    const elem = document.querySelector(`.Card[data-note-id=n${note.id}]`)

    if (elem) {
      const padding = 100
      const rect = elem.getBoundingClientRect()
      const leftScrollOffset = this.getScrollLeft(elem as any)
      Object.assign(this._scrollTarget.style, {
        // top: inPx(rect.top),
        left: inPx((((rect.left + leftScrollOffset) - this.appHeaderSize) - this.noteTreeWidth) - 35),
        width: inPx(rect.width * 2 + padding),
        // height: inPx(rect.height),
      })
    }
  }

  private getScrollLeft(elem: HTMLElement | null): number {
    if (!elem) {
      return 0
    }
    return elem.scrollLeft + this.getScrollLeft(elem.parentElement)
  }

  //

  static get service() { return this._instance || (this._instance = new this()) }
  private static _instance: UIManager
}

decorate(UIManager, {
  useDarkMode: observable,
  useCodemirror: observable,
  useWASDNavKeys: observable,
  useVimNavKeys: observable,
  showNoteTree: observable,
  shouldConfirmDeletes: observable,
  editorFont: observable,
  editorFontSize: observable,
  noteTheme: observable,
  viewportWidth: observable,
  columnWidth: observable,

  spacerColumnWidth: computed,

  setEditorFont: action,
  setEditorFontSize: action,
  _calcAllDimensions: action,
  _assignViewportWidth: action,
  _assignColumnWidth: action,
  setDarkMode: action,
  enableCodemirror: action,
  enableDeleteConfirmation: action,
})

export default UIManager


var columnMeasure: HTMLDivElement

function createColumnMeasure() {
  columnMeasure = document.createElement('div')
  columnMeasure.style.display = 'block'
  columnMeasure.style.position = 'absolute'
  columnMeasure.style.width = COLUMN_WIDTH
  columnMeasure.style.visibility = 'hidden'

  document.body.appendChild(columnMeasure)
}

function inPx(value: number | string): string {
  if (typeof value == 'string') {
    return value
  }
  else {
    return `${value}px`
  }
}

// const inPx2 = (value: number | string): string => (
//   typeof value == 'string' ? value : `${value}px`
// )

// function inPx3(value: number | string): string {
//   return typeof value == 'string' ? value : `${value}px`
// }
