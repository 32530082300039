import Snapshot, { INoteSnapshot, getRootNote } from '../../utils/Snapshot'
import NoteMgr, { Note, extractMetaData, createMarkdownWithFM } from '../../services/NoteManager'
import App from '../../services/Application'
import { toJS } from 'mobx'

interface UpdateNoteOptions {
  force?: boolean
  mergeFrontMatter?: boolean
}

export function updateNoteContent(note: Note, content: string, options: UpdateNoteOptions = {}): Note {
  if (note.content === content && !options.force) {
    return note
  }

  const getNoteById = App.instance.noteMgr.getByIdOrThrow

  return App.instance.undoMgr.executeCommand({
    context: {
      noteId: note.id,
      snapshot: {} as INoteSnapshot
    },

    execute(ctx) {
      // console.info("> updateNote id:", ctx.noteId, 'content:', content, 'context:', ctx)
      let note = getNoteById(ctx.noteId)
      const prevSnapshot = ctx.snapshot = Snapshot.serializeNote(note)

      if (options.mergeFrontMatter) {
        const mergedMetadata = Object.assign(toJS(note.metadata), extractMetaData(content))
        note.updateContent(createMarkdownWithFM(content, mergedMetadata))
      }
      else {
        note.updateContent(content)
      }

      App.instance.events.onNoteUpdate.dispatch({ note, prevSnapshot })
      return note
    },

    revert(ctx) {
      let note = NoteMgr.service.getById(ctx.noteId)
      const prevSnapshot = Snapshot.serializeNote(note)
      const noteData = getRootNote(ctx.snapshot)
      // note.updateContent(ctx.snapshot.content, ctx.snapshot.updated)
      note.updateContent(noteData.content, noteData.updated)

      App.instance.events.onNoteUpdate.dispatch({ note, prevSnapshot })
    },

    shouldCoalesce(ctx, prevCtx) {
      return prevCtx.isNewNote && prevCtx.noteId === ctx.noteId && !prevCtx._isCoalesced
    }
  })
}