import * as React from 'react';
import { classNames, style } from '../utils/Stylesheet';

export const Row: React.SFC<{ className?: string }> = ({ className, children }) => (
  <div className={classNames(Classes.Row, className)}>{children}</div>
)

export const Col: React.SFC<{ className?: string }> = ({ className, children }) => (
  <div className={classNames(Classes.Col, className)}>{children}</div>
)

export const Spacer: React.SFC<{ className?: string }> = ({ className }) => (
  <div className={classNames(Classes.Spacer, className)}></div>
)

export default {
  Row, Col, Spacer
}

const Classes = {
  Row: style({
    display: 'flex'
  }),

  Col: style({
    display: 'flex',
    flexDirection: 'column'
  }),

  Spacer: style({
    flex: 1
  }),
}