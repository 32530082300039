import * as serviceWorker from './serviceWorker';
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as mobx from 'mobx'
import { configure } from 'mobx'
import { Provider } from 'mobx-react'
import './utils/Errors'
import Application from './services/Application'
import App from './components/App'
import { Intent } from '@blueprintjs/core';

import './index.css'
import './tooltip.css'

configure({
  enforceActions: 'observed',
})

Application.instance.start()
  .then(app => {
    ReactDOM.render(
      <Provider app={app}>
        <App />
      </Provider>,
      document.getElementById('root') //as HTMLElement
    )
  })
  .catch(err => {
    console.warn(err)
    ReactDOM.render(
      <div>Start Up Error: {err.message}</div>,
      document.getElementById('root')  //as HTMLElement
    )
  })

if (!serviceWorker.isLocalhost) {
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.register({
    onUpdate() {
      console.log("There's a new version!")
      Application.service.notifications.show({
        message: "Update ready, refresh to use latest version.",
        intent: Intent.PRIMARY,
      })
    },
    onSuccess() {
      console.log("Application has been 'installed' and will load from cache, even when offline!")
    }
  })
}
else {
  serviceWorker.unregister();
}

; (window as any)['App'] = Application;
; (window as any)['mobx'] = mobx;
