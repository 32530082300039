import { action, decorate, observable } from 'mobx';
import Snapshot, { INoteSnapshot } from '../utils/Snapshot';
import Application from './Application';
import Selection from './SelectionManager';

export interface IBackupData {
  type?: string
  version: string
  exported: string // ISO Date 
  snapshot: INoteSnapshot
  trashbin?: INoteSnapshot
}

interface BackupOptions {
  filename: string
  includeTrash: boolean
}

export class BackupManager {
  private _anchor: HTMLAnchorElement = document.createElement('a')

  whenReady = Promise.resolve(this)

  isVisible = false

  async createBackup(options: Partial<BackupOptions> = {}): Promise<IBackupData> {
    const { rootNote, trashBin: trashbinNote } = Application.service.noteMgr
    const snapshot = Snapshot.serializeNote(rootNote)
    const trashbin = options.includeTrash
      ? Snapshot.serializeNote(trashbinNote)
      : void 0
    return {
      type: "NoteTree Backup",
      version: Application.service.meta.version,
      exported: (new Date()).toISOString(),
      snapshot, trashbin
    }
  }

  async downloadAsFile(options: Partial<BackupOptions> = {}) {
    const data = await this.createBackup(options)
    const json = JSON.stringify(data)
    const dataString = "data:text/json;charset=utf-8," + encodeURIComponent(json)
    const {
      filename = `backup-${(new Date()).toISOString().split('T')[0]}.notetree`
    } = options

    console.log("Downloading:", filename)

    this._anchor.setAttribute('href', dataString)
    this._anchor.setAttribute('download', filename)
    this._anchor.click()
  }

  show() {
    Selection.service.setEnabled(false)
    this.isVisible = true

  }
  hide() {
    Selection.service.setEnabled(true)
    this.isVisible = false
  }

  // Singleton

  static get service() { return this._instance || (this._instance = new this()) }
  private static _instance: BackupManager
}

decorate(BackupManager, {
  isVisible: observable,

  show: action,
  hide: action,
})

export default BackupManager