//tslint:disable
/*
Language: Fountain
Requires: xml.js
Author: Matt McCray <matt@elucidata.net>, John Crepezzi <john.crepezzi@gmail.com>
Website: http://seejohncode.com/
Category: common, markup
*/

export default function (hljs: any) {
  return {
    case_insensitive: false,
    aliases: ['fd', 'scrippet', 'ft', 'fountain'],
    contains: [

      // highlight headers
      {
        className: 'section',
        variants: [
          { begin: '^#{1,6}', end: '$' },
          { begin: '^.+?\\n[=-]{2,}$' },
          // { begin: '^[\.|eE|xX|nN|tT]{1}\\s*', end: '$' }
        ]
      },
      {
        className: 'comment',
        begin: '^%', end: '$'
      },
      {
        className: 'synopsis',
        begin: '^=', end: '$'
      },
      // {
      //   className: 'dialog',
      //   begin: '[\\w|\.]*[\\n]{1}[^\\n]', end: '[\\n]{1}'
      // },

      {
        className: 'parenthetical',
        begin: '^\\(', end: '\\)$'
      },
      // scene
      {
        className: 'scene',
        variants: [
          { begin: '^[\.|eE|iI|xX|nN|sS|tT]{3}\\s*', end: '$' },
          { begin: '^[\.]{1}\\s*', end: '$' },
        ]
      },
      // inline html
      {
        begin: '<', end: '>',
        subLanguage: 'xml',
        relevance: 0
      },
      // lists (indicators only)
      {
        className: 'bullet',
        begin: '^([*+-]|(\\d+\\.))\\s+'
      },
      // strong segments
      {
        className: 'strong',
        begin: '[*_]{2}.+?[*_]{2}'
      },
      // emphasis segments
      {
        className: 'emphasis',
        variants: [
          { begin: '\\*.+?\\*' },
          {
            begin: '_.+?_'
            , relevance: 0
          }
        ]
      },
      // blockquotes
      {
        className: 'quote',
        begin: '^>\\s+', end: '$'
      },
      // code snippets
      {
        className: 'code',
        variants: [
          {
            begin: '^```\w*\s*$', end: '^```\s*$'
          },
          {
            begin: '`.+?`'
          },
          {
            begin: '^( {4}|\t)', end: '$',
            relevance: 0
          }
        ]
      },
      // horizontal rules
      {
        begin: '^[-\\*]{3,}', end: '$'
      },
      // using links - title and link
      {
        begin: '\\[.+?\\][\\(\\[].*?[\\)\\]]',
        returnBegin: true,
        contains: [
          {
            className: 'string',
            begin: '\\[', end: '\\]',
            excludeBegin: true,
            returnEnd: true,
            relevance: 0
          },
          {
            className: 'link',
            begin: '\\]\\(', end: '\\)',
            excludeBegin: true, excludeEnd: true
          },
          {
            className: 'symbol',
            begin: '\\]\\[', end: '\\]',
            excludeBegin: true, excludeEnd: true
          }
        ],
        relevance: 10
      },
      {
        begin: /^\[[^\n]+\]:/,
        returnBegin: true,
        contains: [
          {
            className: 'symbol',
            begin: /\[/, end: /\]/,
            excludeBegin: true, excludeEnd: true
          },
          {
            className: 'link',
            begin: /:\s*/, end: /$/,
            excludeBegin: true
          }
        ]
      },
      // character
      {
        className: 'character',
        // illegal: '[a-z]',
        begin: '^[A-Z]{1,}$', //end: '$'
        excludeEnd: true
      },

      // action
      // {
      //   className: 'action',
      //   // begin: '^.*', end: '$'
      // },

    ]
  };
}