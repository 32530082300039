import { action, decorate, observable } from 'mobx';
import Selection from './SelectionManager';

export class SettingsManager {

  whenReady = Promise.resolve(this)

  isVisible = false

  show() {
    Selection.service.setEnabled(false)
    this.isVisible = true

  }
  hide() {
    Selection.service.setEnabled(true)
    this.isVisible = false
  }

  // Singleton

  static get service() { return this._instance || (this._instance = new this()) }
  private static _instance: SettingsManager
}

decorate(SettingsManager, {
  isVisible: observable,

  show: action,
  hide: action,
})

export default SettingsManager