import * as React from 'react'
import { observer } from 'mobx-react'
import { Dialog, IconClasses, Button, Intent, Icon } from '@blueprintjs/core'
import { style } from '../utils/Stylesheet'
import Application from '../services/Application'
import { Note } from '../services/NoteManager/Note';
import { cancelEvent } from '../utils/FunctionTools';
import * as actions from '../actions'

class TrashBinDialog extends React.Component<{}, {}>{

  private get manager() {
    return Application.service.trashbin
  }

  render() {
    const bin = Application.service.noteMgr.trashBin

    return (
      <Dialog enforceFocus autoFocus lazy
        className={Styles.Dialog}
        isOpen={this.manager.isVisible}
        iconName={IconClasses.TRASH}
        title="Trash Bin"
        onClose={this.hidePreview}
        canOutsideClickClose={false}
      >
        <div>
          <div className={Styles.ContentContainer}>

            {bin.children.length == 0 &&
              <div>
                <br /><br />
                <div className="pt-non-ideal-state">
                  <div className="pt-non-ideal-state-visual pt-non-ideal-state-icon">
                    <span className="pt-icon pt-icon-trash"></span>
                  </div>
                  <h4 className="pt-non-ideal-state-title">Trash bin is empty</h4>
                  <div className="pt-non-ideal-state-description">
                    You have no notes in the trash bin... How tidy!
                  </div>
                </div>
                <br /><br />
              </div>
            }

            {bin.children.length > 0 &&
              <div className={Styles.List}>
                {bin.children.map(note => (
                  <ListItem note={note} key={note.id} />
                ))}
              </div>
            }

          </div>
          {bin.children.length > 0 &&
            <div className={Styles.DialogActions}>
              <div className={Styles.DialogActionsStatus}>
                <small className="pt-text-muted">Notes: {bin.children.length}</small>
              </div>
              <Button text="Empty Trash" intent={Intent.DANGER} onClick={this.empty_onClick} />
            </div>
          }
        </div>
      </Dialog>
    )
  }

  hidePreview = () => {
    this.manager.hide()
  }

  empty_onClick = (e: any) => {
    cancelEvent(e)
    actions.emptyTrash()
  }
}

export default observer(TrashBinDialog)


class ListItem extends React.Component<{
  note: Note;
}, {
  showMore: boolean
}> {

  state = {
    showMore: false
  }

  render() {
    const { note } = this.props
    return (
      <div className={Styles.ListItem}>
        <div className={Styles.ListItemCell_Icon} onClick={this.toggleShowMore}>
          <Icon iconName="document" />
          <Icon iconName={this.state.showMore ? "caret-down" : "caret-right"} className="disclosure" />
        </div>
        <div className={Styles.ListItemCell_Title}>
          {note.title}
          {this.state.showMore &&
            <div>
              <small className="pt-text-muted">Parent: {note.metadata.trash.parentTitle || 'Unknown'}</small>
              <br />
              <small className="pt-text-muted">Trashed: {new Date(note.metadata.trash.date).toLocaleString()}</small>
              {/* <pre>{JSON.stringify(note.metadata.trash, null, 2)}</pre> */}
            </div>
          }
        </div>
        <div className={Styles.ListItemCell_Actions}>
          <Button text="Restore" intent={Intent.PRIMARY} className="pt-minimal" onClick={this.restore_onClick} />
          {/* &nbsp; &nbsp;
          <Button text="Delete" intent={Intent.DANGER} className="pt-minimal" onClick={this.delete_onClick} /> */}
        </div>
      </div>
    )
  }

  private toggleShowMore = (e: any) => {
    cancelEvent(e)
    this.setState({
      showMore: !this.state.showMore
    })
  }

  private restore_onClick = (e: any) => {
    cancelEvent(e)
    Application.service.trashbin.hide()
    actions.restoreNote(e, this.props.note)
  }

  // private delete_onClick = (e) => {
  //   cancelEvent(e)
  // }
}


const Styles = {

  Dialog: style({
    // top: '5vh',
    width: '45em',
    padding: 0,
    '.pt-dialog-header': {
      marginBottom: 1
    }
  }),

  ContentContainer: style({
    maxHeight: '60vh',
    overflowY: 'auto',
    padding: 12,
  }),

  List: style({

  }),

  ListItem: style({
    display: 'flex',
    background: 'var(--card-bg-color)',
    marginBottom: 12,
    boxShadow: '0px 2px 2px var(--shadow-color) !important',

    "&:last-child": {
      marginBottom: 0
    },
    ".disclosure": {
      paddingLeft: 0,
      marginLeft: -3,
      opacity: 0.5,
    }
  }),

  ListItemCell_Icon: style({
    padding: '1em',
    paddingRight: 0,
    opacity: 0.5,
  }),

  ListItemCell_Title: style({
    flex: 1,
    padding: '1em',
    paddingLeft: 6,
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'nowrap'
  }),

  ListItemCell_Actions: style({
    // display: 'flex'
    alignSelf: 'center',
    paddingRight: 12,
  }),

  DialogActions: style({
    padding: 12,
    display: 'flex',
    alignItems: 'center'
  }),

  DialogActionsStatus: style({
    flex: 1
  })
}