import Snapshot, { INoteSnapshot } from './utils/Snapshot'
import { Note } from './services/NoteManager';

export default function initializeContent(rootNote: Note) {
  const virtRoot = Snapshot.deserializeNote(DEFAULT_NOTES)
  virtRoot.children.forEach(note => {
    rootNote.addChildNote(note)
  })
}

var DEFAULT_NOTES: INoteSnapshot = require('./welcome-notes.json')