import { Button, Dialog, IconClasses, Intent, Radio, RadioGroup } from '@blueprintjs/core';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import Application from '../services/Application';
import { classNames, style } from '../utils/Stylesheet';

class ExportDialog extends React.Component<{}, {}>{
  get app() {
    return Application.service
  }

  render() {
    return (
      <Dialog enforceFocus autoFocus lazy
        className={Styles.ExportDialog}
        isOpen={this.app.exportMgr.isVisible}
        iconName={IconClasses.GIT_REPO}
        title="Export"
        onClose={this.handleClose}
        canOutsideClickClose={false}
      >
        {this.app.exportMgr.isVisible && <ExportPanel />}
      </Dialog>
    )
  }

  handleClose = () => {
    this.app.exportMgr.hide()
  }
}

export default observer(ExportDialog)

function setExportOption(options: any, key: string) {
  return action((e: any) => {
    options[key] = e.currentTarget.value
  })
}

const ExportPanel = observer(class ExportPanelUI extends React.Component<{}, any> {
  get app() {
    return Application.service
  }
  get exportOptions() {
    return this.app.exportMgr.options
  }
  _anchor!: HTMLAnchorElement
  assign = {
    _anchor: (c: any) => this._anchor = c
  }
  form = {
    sourceChange: setExportOption(this.exportOptions, 'source'), // (e) => this.setState({ source: e.currentTarget.value }),
    contentsChange: setExportOption(this.exportOptions, 'output'),//(e) => this.setState({ contents: e.currentTarget.value }),
    formatChange: setExportOption(this.exportOptions, 'format'), //(e) => this.setState({ format: e.currentTarget.value }),
    insertTextChange: setExportOption(this.exportOptions, 'insertText'), //(e) => this.setState({ insertText: e.currentTarget.value }),
  }

  render() {
    return (
      <div>
        <div className={Styles.ExportPanelContainer}>
          <div className={Styles.ExportPanelOptions + ' pt-small'}>
            <RadioGroup
              label="Export Source:"
              onChange={this.form.sourceChange}
              selectedValue={this.exportOptions.source}
            >
              <Radio label={`${this.app.selectionMgr.isHoisted ? 'Hoisted ' : ''}Root`} value="root" />
              <Radio label="Selection" value="selected" />
            </RadioGroup>
            {/*<div>From the top</div>
            <div>Selected Note ({this.app.selectionMgr.selectedNote.title})</div>*/}

            <RadioGroup
              label="Note Contents:"
              onChange={this.form.contentsChange}
              selectedValue={this.exportOptions.output}
            >
              <Radio label="Note" value="note" disabled={this.exportOptions.source == 'root' && this.app.selectionMgr.rootNote == this.app.noteMgr.rootNote} />
              <Radio label="Note and all children" value="note-children" />
              <Radio label="All child notes" value="children" />
              <Radio label="Leaf notes only" value="leaves" />
            </RadioGroup>


            {/*<strong>Contents:</strong>
            <div>Note</div>
            <div>Note and all children</div>
            <div>Child notes</div>
            <div>Leaf notes only</div>*/}
            <label className={classNames("pt-label", { 'pt-disabled': this.exportOptions.output === 'note' })}>
              Insert Between Notes:
              <textarea
                className="pt-input"
                disabled={this.exportOptions.output === 'note'}
                value={this.exportOptions.insertText}
                onChange={this.form.insertTextChange}
              />
            </label>
            {/*<strong>Options:</strong>
            <div>Insert text between notes: []</div>*/}

            <RadioGroup
              label="Format:"
              onChange={this.form.formatChange}
              selectedValue={this.exportOptions.format}
            >
              <Radio disabled label="ePub (soon)" value="epub" />
              <Radio label="HTML" value="html" />
              <Radio label="Markdown" value="markdown" />
            </RadioGroup>
            {/*<strong>Format:</strong>
            <div>Markdown</div>
            <div>HTML</div>
            <div>ePub <span className="pt-text-muted">(Coming soon)</span></div>*/}

          </div>
          <div className={Styles.ExportPanelPreviewContainer}>
            <div className={Styles.ExportPanelPreview}>
              <div
                className={classNames("CardContent", `format-${this.exportOptions.format}`)}
                dangerouslySetInnerHTML={{ __html: this.app.exportMgr.exportPreviewContent }}
              />
            </div>
          </div>
        </div>
        <div className={Styles.DialogActions}>
          <Button
            intent={Intent.PRIMARY}
            iconName={IconClasses.DOWNLOAD}
            text={`Download ${this.exportOptions.format}`}
            onClick={this.handleDownload}
          />
          <a ref={this.assign._anchor} />
        </div>
      </div>
    )
  }

  handleDownload = () => {
    // TODO: Need better filename generation!
    const title = "Exported NoteTree"
    const filename = `${title}.${this.exportOptions.format}`
    const content = this.exportOptions.format === 'html'
      ? wrapHTML(this.app.exportMgr.exportPreviewContent, title)
      : this.app.exportMgr.exportPreviewContent
    // const dataString = "data:text/" + this.exportOptions.format + ";charset=utf-8," + encodeURIComponent(content)
    const dataString = `data:text/${this.exportOptions.format};charset=utf-8,${encodeURIComponent(content)}`

    console.log("Downloading:", filename)

    this._anchor.setAttribute('href', dataString)
    this._anchor.setAttribute('download', filename)
    this._anchor.click()

  }
})

function wrapHTML(content: string, title: string): string {
  const styles: string[] = []
  for (let index = 0; index < document.styleSheets.length; index++) {
    styles.push(document.styleSheets[index].ownerNode.textContent || '')
  }

  document.styleSheets
  return `<!doctype html><html><head><meta charset="utf-8"/><title>${title}</title><style>
  ${styles.join('\n')}
  .CardContent { max-width: 45em; margin: 2em auto; }
  .CardContent input { pointer-events: none; }
  body {overflow: auto !important;}
  </style></head><body>
<div class="CardContent">${content}</div>
</body></html>`
}

const Styles = {
  ExportDialog: style({
    // top: '5vh',
    width: '45em',
    padding: 0,
    '.pt-dialog-header': {
      marginBottom: 1
    }
  }),

  ExportPanelContainer: style({
    display: 'flex',
    maxHeight: '80vh',
    overflowY: 'auto',
    paddingTop: 1,
  }),

  ExportPanelOptions: style({
    padding: '1em',
  }),

  ExportPanelPreviewContainer: style({
    flex: 1,
    overflowY: 'auto',

    '.format-markdown': {
      whiteSpace: 'pre-wrap',
    }
  }),

  ExportPanelPreview: style({
    margin: '1.75em 2.2em',
    backgroundColor: 'white',
    boxShadow: '0px 2px 5px rgba(0,0,0, 0.5)',
    // marginBottom: 0,
    padding: '1em',
    zoom: 0.5,
    'input': {
      pointerEvents: 'none'
    },
  }),

  DialogActions: style({
    padding: '1em',
    textAlign: 'right',
  }),
}